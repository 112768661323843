import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, ApiServiceContext } from './api.service';
import { ApplicationProperty } from '../models/properties/applicationProperty.model';
import { ApplicationProperties } from '../models/properties/applicationProperties.enum';

@Injectable({ providedIn: 'root' })
export class ApplicationPropertyService extends ApiService {
  private readonly cache: Map<ApplicationProperties, ApplicationProperty> = new Map();
  private readonly url = this.environment.apiUrl + 'application-property';

  constructor(ctx: ApiServiceContext) {
    super(ctx);
  }

  public getPublicApplicationProperty(appProperty: ApplicationProperties): Observable<ApplicationProperty> {
    if (!this.cache.has(appProperty)) {
      return this.http.get<ApplicationProperty[]>(`${this.url}/public`).pipe(
        map((properties) => {
          properties.forEach((p) => {
            this.cache.set(p.code as ApplicationProperties, p);
          });
          return this.cache.get(appProperty);
        })
      );
    }
    return of(this.cache.get(appProperty));
  }
}
