import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toolbar-divider',
  templateUrl: './toolbar-divider.component.html',
  styleUrls: ['./toolbar-divider.component.scss'],
})
export class ToolbarDividerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
