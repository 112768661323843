import { FilterUtils } from 'primeng/utils';
import { Lov } from '../../models/lov.model';

export function defineCustomFilters() {
  FilterUtils['dateMatch'] = (value: Date, filter: Date) => {
    const valueDate = new Date(value);
    const filterDate = new Date(filter);
    return valueDate.getTime() === filterDate.getTime();
  };

  FilterUtils['lovTextMatch'] = (value: Lov, filter: string) => {
    return value.labelFr.includes(filter);
  };
}
