import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { NgxSecurityService } from 'ngx-security';
import { combineLatest, Observable, of } from 'rxjs';
import { exhaustMap, filter, map, startWith, tap } from 'rxjs/operators';
import { AppConfig, ApplicationProperties, SecRoles } from '../models';
import { UIService, TopBarService, ApplicationPropertyService, SecurityService, ConfigService } from '../services';
import { AuthenticationState, LogoutAction, AuthenticationStateModel, TouchAction, RefreshToken } from '../states';
import { defineCustomFilters } from '../core';
import { Environment } from '../environment';

@UntilDestroy()
@Component({
  selector: 'nsi-root',
  template: `
    <router-outlet *ngIf="blank$ | async; else layout"></router-outlet>
    <ng-template #layout>
      <app-layout *ngIf="!config.security"></app-layout>
      <app-layout *ngIf="config.security && authenticated$ | async; else loggedOut"></app-layout>

      <ng-template #loggedOut>
        <app-login-layout
          *ngIf="!config.securityExternal && config.security && config.security && config.securityForm"
        ></app-login-layout>
        <app-login-multiple
          *ngIf="!config.securityExternal && config.security && !config.securityForm"
        ></app-login-multiple>
      </ng-template>

      <!-- App Custom Components -->
      <p-blockUI [blocked]="ui.blocked$ | async"></p-blockUI>
      <ng-progress [spinner]="false"></ng-progress>
      <app-valdemort-default></app-valdemort-default>
    </ng-template>
  `,
})
export class AppComponent implements OnInit {
  public blank$: Observable<boolean>;
  public authenticated$: Observable<boolean>;
  public config: AppConfig;
  private intervalId: any;

  constructor(
    public ui: UIService,
    private router: Router,
    private topBar: TopBarService,
    private security: NgxSecurityService,
    private store: Store,
    private http: HttpClient,
    private applicationPropertyService: ApplicationPropertyService,
    private securityService: SecurityService,
    private environment: Environment,
    private configService: ConfigService
  ) {
    this.blank$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map((e: NavigationEnd) => e.urlAfterRedirects.indexOf('/viewer') === 0)
    );

    /* we want to observe authenticated only after navigation,
     * else the previous component will be displayed until the navigation end and change the component
     */
    this.authenticated$ = combineLatest([
      // observe both navigation events and state, project the last one received
      this.router.events,
      this.store.select(AuthenticationState.authenticated),
    ]).pipe(
      // whenever events or state arrive, we ignore as long as navigation isn't ended
      filter(([event, authenticated]) => event && event instanceof NavigationEnd),
      // we only return state
      map(([event, authenticated]) => authenticated),
      // ensure that initial state is returned when application is initializing (required for touch action)
      startWith(this.store.selectSnapshot(AuthenticationState.authenticated))
    );
  }

  ngOnInit() {
    this.config = this.configService.app as AppConfig;

    // Progress bar sur la navigation + blockUI
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => this.updateProgress(event));

    // Authentication - Update Security
    this.store
      .select(AuthenticationState)
      .pipe(untilDestroyed(this))
      .subscribe((authentication) => this.updateSecurity(authentication));

    // Authentication - Logout
    this.topBar
      .select('logout')
      .pipe(
        untilDestroyed(this),
        exhaustMap(() =>
          this.config.securityForm
            ? this.applicationPropertyService.getPublicApplicationProperty(
                ApplicationProperties.APPLICATION_SSO_LOGOUT_URL
              )
            : of({ value: window.location.origin + this.environment.context })
        ),
        exhaustMap((property) => this.store.dispatch(new LogoutAction()).pipe(map(() => property))),
        tap((property) => {
          // Reset the security state to it's initial value and permission cache
          this.securityService.reset();

          if (property.value === '/') this.router.navigate(['/']);
          else document.location.href = property.value;
        })
      )
      .subscribe();

    // keepSessionAlive
    if (this.config.keepSessionAlive) {
      combineLatest([this.authenticated$, this.store.select(AuthenticationState.token)])
        .pipe(
          untilDestroyed(this),
          tap(([authenticated, token]) => {
            if (authenticated && token) {
              // Calculate interval : token expiration date - 2.5 minutes
              const interval =
                moment
                  .utc(
                    moment(new Date(token.accessToken.expiresAt), 'DD/MM/YYYY HH:mm:ss').diff(
                      moment(new Date(), 'DD/MM/YYYY HH:mm:ss')
                    )
                  )
                  .valueOf() - 150000; // 150000 = 2.5 minutes

              if (!this.intervalId) {
                this.intervalId = setInterval(() => this.keepSessionAlive(), interval);
              }
            } else {
              clearInterval(this.intervalId);
              this.intervalId = null;
            }
          })
        )
        .subscribe();
    }

    // Define custom filters for PrimeNG Table
    defineCustomFilters();
  }

  private updateProgress(event: Event) {
    if (event instanceof NavigationStart) {
      this.ui.progressStart(true);
    }

    if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
      this.ui.progressComplete();
    }
  }

  private updateSecurity(authentication: AuthenticationStateModel) {
    if (this.config.security) {
      this.security.setAuthenticated(AuthenticationState.authenticated(authentication));

      if (authentication.roles instanceof Array) {
        this.security.setRoles(authentication.roles);
      }
      if (authentication.groups instanceof Array) {
        this.security.setGroups(authentication.groups);
      }

      // Permission Checker
      this.security.setPermissionChecker((perm: string) => {
        return this.securityService.hasPermission(perm);
        // return of(true);
      });
    } else {
      this.security.setAuthenticated(true);
      this.security.setRoles([SecRoles.ROLE_USER]);
    }
  }

  private keepSessionAlive() {
    if (this.config.security) {
      if (this.config.securityForm) {
        this.store.dispatch(new TouchAction());
      } else {
        this.store.dispatch(new RefreshToken());
      }
    }
  }
}
