import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxSecurityGuard } from 'ngx-security';
import {
  CoreModule,
  LayoutsModule,
  securityAuthenticatedRoutePermsData,
  ShowcaseAppModule,
  ShowcaseSharedModule,
} from '@nsi-showcase/components';
import { environment } from '../../environments/environment';
import { SecPermissions } from '@spw-amie/components';

export const FEATURES_ROUTES: Routes = [
  { path: '', redirectTo: '/type', pathMatch: 'full' },
  { path: 'dashboard', redirectTo: '/type', pathMatch: 'full' },
  {
    path: '',
    canActivate: [NgxSecurityGuard],
    runGuardsAndResolvers: 'always',
    data: {
      security: {
        authenticated: true,
        redirectTo: '/access-denied',
      },
    },
    children: [
      {
        path: 'type',
        canActivate: [NgxSecurityGuard],
        data: securityAuthenticatedRoutePermsData([SecPermissions.FORM_SEARCH], 'collection'),
        loadChildren: () => import('@spw-amie-backoffice/features/type/type.module').then((m) => m.TypeModule),
      },
      {
        path: 'collection',
        canActivate: [NgxSecurityGuard],
        data: securityAuthenticatedRoutePermsData([SecPermissions.COLLECTION_SEARCH]),
        loadChildren: () =>
          import('@spw-amie-backoffice/features/collection/collection.module').then((m) => m.CollectionModule),
      },
      {
        path: 'user-administration',
        canActivate: [NgxSecurityGuard],
        runGuardsAndResolvers: 'always',
        data: securityAuthenticatedRoutePermsData([SecPermissions.ADMIN_USER]),
        loadChildren: () =>
          import('@spw-amie-backoffice/features/user-administration/user-administration.module').then(
            (m) => m.UserAdministrationModule
          ),
      },
      {
        path: 'map',
        loadChildren: () => import('@spw-amie-backoffice/features/map/map.module').then((m) => m.MapModule),
      },
      {
        path: 'showcase',
        loadChildren: () =>
          import('@nsi-showcase-demo/features/@showcase/showcase.module').then((m) => m.ShowcaseModule),
      },
      {
        path: 'viewer',
        loadChildren: () => import('@nsi-showcase/components').then((m) => m.ShowcaseViewerModule),
        data: { apiUrl: environment.spwGeoviewerApiUrl },
      },
    ],
  },
];

const MODULES: any[] = [];

@NgModule({
  imports: [...MODULES, RouterModule.forChild(FEATURES_ROUTES)],
  exports: MODULES,
})
export class FeaturesModule {}
