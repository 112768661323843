<a
    [href]="url"
    target="_blank"
    (click)="disabled ? $event.preventDefault() : ''"
    [pTooltip]="key ? (key | translate) : title"
    [class]="class"
    [tabIndex]="tabIndex"
    [tooltipPosition]="tooltipPosition || 'bottom'"
    [class.disabled]="disabled"
    *ngIf="url"
>
    <i class="{{ iconClass }} {{ iconColorClass }}" [style.font-size]="iconSize" [class.active]="active">{{ icon }}</i>
</a>

<a
    [routerLink]="!disabled ? routerLink : null"
    [pTooltip]="key ? (key | translate) : title"
    [class]="class"
    [tabIndex]="tabIndex"
    [tooltipPosition]="tooltipPosition || 'bottom'"
    [class.disabled]="disabled"
    *ngIf="routerLink"
>
    <i class="{{ iconClass }} {{ iconColorClass }}" [style.font-size]="iconSize" [class.active]="active">{{ icon }}</i>
</a>

<a
    href="#"
    (click)="$event.preventDefault(); onClickAction($event)"
    [pTooltip]="key ? (key | translate) : title"
    [class]="class"
    [tabIndex]="tabIndex"
    [tooltipPosition]="tooltipPosition || 'bottom'"
    [class.disabled]="disabled"
    *ngIf="command || isAction"
>
    <i class="{{ iconClass }} {{ iconColorClass }}" [style.font-size]="iconSize" [class.active]="active">{{ icon }}</i>
</a>

<a
    href="#"
    (click)="disabled ? $event.preventDefault() : $event.preventDefault(); menu.toggle($event)"
    [class.disabled]="disabled"
    [class]="class"
    [tabIndex]="tabIndex"
    *ngIf="menuItems"
>
    <i class="{{ iconClass }} {{ iconColorClass }}" [style.font-size]="iconSize" [class.active]="active">{{ icon }}</i>
</a>
<p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
