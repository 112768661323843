import { AfterViewInit, Component } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormCanDeactivateComponent } from './form-can-deactivate.component';

@UntilDestroy()
@Component({ template: '' })
export abstract class DungeonKeeperFormComponent extends FormCanDeactivateComponent implements AfterViewInit {
  constructor(protected route: ActivatedRoute) {
    super();
  }

  ngAfterViewInit(): void {
    const readOnly =
      !!this.route && this.route.snapshot && this.route.snapshot.data && this.route.snapshot.data['readOnly']
        ? this.route.snapshot.data['readOnly']
        : false;
    if (readOnly) {
      setTimeout(() => this.disableForms());
    }
  }

  protected disableForms(): void {
    this.forms.forEach((form) => form.disable());
  }

  protected resetForms(): void {
    this.forms.forEach((form) => this.resetForm(form));
  }

  protected resetForm(formGroup: FormGroup): void {
    formGroup.reset();
  }

  protected validateForms(): void {
    this.forms.forEach((form) => this.validateForm(form));
  }

  protected validateForm(formGroup: FormGroup) {
    formGroup.markAsPristine();
  }

  protected invalidateForms() {
    this.forms.forEach((form) => this.invalidateForm(form));
  }

  protected invalidateForm(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      control.markAsDirty();

      if (control.controls) {
        this.invalidateForm(control);
      }
    });
  }

  /** @deprecated */
  protected setReadOnly(control: AbstractControl, disabled: boolean): void {
    if (!disabled) control.enable();
    else control.disable();
  }
}
