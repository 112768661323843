import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ViewerIframeComponent } from './viewer-iframe.component';

const MODULES: any[] = [TranslateModule];

const DECLARATIONS: any[] = [ViewerIframeComponent];

@NgModule({
  imports: MODULES,
  declarations: DECLARATIONS,
  exports: [...DECLARATIONS, ...MODULES],
})
export class ViewerIframeModule {}
