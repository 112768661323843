<form *ngIf="types$ | async" #form="ngForm" [formGroup]="formGroup" class="width-500px">
    <div class="ui-g">
        <!--TYPE-->
        <div class="ui-g-12 form-group-field">
            <div>
                <label>{{ 'collection.field-type' | translate }} *</label>
                <br />
                <p-dropdown
                    [disabled]="lightEdit"
                    [options]="fieldTypes"
                    formControlName="fieldTypeCode"
                    [filter]="true"
                    class="form-field"
                    appendTo="body"
                ></p-dropdown>
            </div>
        </div>

        <!--LABEL-->
        <div class="ui-g-12 form-group-field">
            <div>
                <label>{{ 'collection.description' | translate }} *</label>
                <input class="form-field" type="text" pInputText formControlName="label" />
            </div>
        </div>

        <!--MIN AND MAX-->
        <div
            *ngIf="
                formGroup.controls['fieldTypeCode'].value === 'INT' ||
                formGroup.controls['fieldTypeCode'].value === 'FLOAT'
            "
        >
            <div *ngIf="formGroup.controls['fieldTypeCode'].value === 'INT'">
                <div class="ui-g-6 form-group-field">
                    <label>{{ 'collection.field-min' | translate }}</label>
                    <input pInputText class="form-field" type="text" pKeyFilter="int" formControlName="minVal" />
                </div>
                <div class="ui-g-6 form-group-field">
                    <label>{{ 'collection.field-max' | translate }}</label>
                    <input pInputText class="form-field" type="text" pKeyFilter="int" formControlName="maxVal" />
                </div>
            </div>
            <div *ngIf="formGroup.controls['fieldTypeCode'].value === 'FLOAT'">
                <div class="ui-g-6 form-group-field">
                    <label>{{ 'collection.field-min' | translate }}</label>
                    <input pInputText class="form-field" type="text" pKeyFilter="num" formControlName="minVal" />
                </div>
                <div class="ui-g-6 form-group-field">
                    <label>{{ 'collection.field-max' | translate }}</label>
                    <input pInputText class="form-field" type="text" pKeyFilter="num" formControlName="maxVal" />
                </div>
            </div>
            <div *ngIf="formGroup.errors?.range && (formGroup.touched || formGroup.dirty)" class="msg-error">
                {{ 'collection.errors.min-lower-max' | translate }}
            </div>
        </div>

        <!--LIST OF VALUES-->
        <div *ngIf="formGroup.controls['fieldTypeCode'].value === 'TXT'" class="ui-g-12 form-group-field">
            <label>{{ 'collection.list-of-values' | translate }}</label>
            <p-chips formControlName="values" class="form-field"></p-chips>
        </div>

        <!--MANDATORY-->
        <div class="ui-g-12 form-group-field">
            <span>
                <p-checkbox
                    [disabled]="lightEdit"
                    class="light-checkbox"
                    [label]="'collection.mandatory' | translate"
                    (onChange)="onMandatoryChecked($event)"
                    binary="true"
                    formControlName="isMandatory"
                ></p-checkbox>
            </span>
        </div>

        <!--MOBILE-->
        <div class="ui-g-12 form-group-field">
            <div>
                <p-checkbox
                    [disabled]="lightEdit"
                    class="light-checkbox"
                    [label]="'collection.visible-from-mobile' | translate"
                    binary="true"
                    formControlName="isMobile"
                ></p-checkbox>
            </div>
        </div>

        <!--BUTTON-->
        <div class="ui-g-12 text-align-r">
            <button
                type="button"
                pButton
                [label]="'common.validate' | translate"
                class="ui-button-info"
                [disabled]="form.invalid"
                (click)="addType()"
                [icon]="'ui-icon-save'"
            ></button>
        </div>
    </div>
</form>
