import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'translateRn', pure: false })
export class TranslateRnPipe implements PipeTransform {
  constructor() {}

  transform(nationalNumber: string | any): string | any {
    if (nationalNumber === null || nationalNumber === undefined || nationalNumber === '') {
      return '';
    }

    if (typeof nationalNumber !== 'string') {
      return 'Only string are supported';
    }

    if (nationalNumber.length !== 11) return 'Error : Less than 11 numbers';

    return `${nationalNumber.substring(0, 2)}.${nationalNumber.substring(2, 4)}.${nationalNumber.substring(
      4,
      6
    )}-${nationalNumber.substring(6, 9)}.${nationalNumber.substring(9, 11)}`;
  }
}
