export enum MapServiceTypes {
  CLUSTER = 'CLUSTER',
  COMPOSED_LAYER = 'COMPOSED_LAYER',
  CSV_ADDRESS_LAYER = 'CSV_ADDRESS_LAYER',
  CSV_LAYER = 'CSV_LAYER',
  DATA_LAYER = 'DATA_LAYER',
  DRAW_LAYER = 'DRAW_LAYER',
  AGS_DYNAMIC = 'AGS_DYNAMIC',
  FEATURE_LAYER = 'FEATURE_LAYER',
  GEOJSON_LAYER = 'GEOJSON_LAYER',
  GMAP_LAYER = 'GMAP_LAYER',
  GPX_LAYER = 'GPX_LAYER',
  HEAT = 'HEAT',
  JSON_LAYER = 'JSON_LAYER',
  KML_LAYER = 'KML_LAYER',
  OSM = 'OSM',
  SHP_LAYER = 'SHP_LAYER',
  STATIC_IMAGE = 'STATIC_IMAGE',
  STAT_LAYER = 'STAT_LAYER',
  AGS_TILED = 'AGS_TILED',
  TIMER = 'TIMER',
  WEB_TILED = 'WEB_TILED',
  WMS = 'WMS',
  WMTS = 'WMTS',
  XML_LAYER = 'XML_LAYER',
}

export interface MapServiceInfo {
  serviceId: string;
  label: string;
  type: MapServiceTypes;
  url?: string;
  visible?: boolean;
  toLoad?: boolean;
  inTOC?: boolean;
  identifiable?: boolean;
  order?: number;
}
