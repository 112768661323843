import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-action-icon',
  templateUrl: './action-icon.component.html',
  styleUrls: ['./action-icon.component.scss'],
})
export class ActionIconComponent implements OnInit, AfterViewChecked {
  @Input() key: string;
  @Input() title: string;
  @Input() icon: string;
  @Input() iconColorClass: string;
  @Input() iconSize: string;
  @Input() url: string;
  @Input() routerLink: string;
  @Input() active: boolean;
  @Input() disabled: boolean;
  @Input() tooltipPosition: string;
  @Input() command: (_: any) => any;
  @Input() menuItems: MenuItem[];
  @Input() class: string;
  @Input() readonly: boolean;
  @Input() tabIndex: number;
  @Input() context: any;

  @Output() action = new EventEmitter();

  public iconClass: string;

  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (
      this.icon.startsWith('fas ') ||
      this.icon.startsWith('far ') ||
      this.icon.startsWith('fal ') ||
      this.icon.startsWith('fab ')
    ) {
      this.iconClass = this.icon;
      this.icon = '';
    } else {
      this.iconClass = 'material-icons';
    }
  }

  ngAfterViewChecked(): void {
    const readOnly =
      this.readonly === undefined
        ? !!this.route && this.route.snapshot && this.route.snapshot.data && this.route.snapshot.data['readOnly']
          ? this.route.snapshot.data['readOnly']
          : false
        : this.readonly;
    if (readOnly && !this.disabled) {
      // Specific conditions
      const disabled = !(
        this.icon === 'description' ||
        this.icon === 'edit' ||
        this.icon === 'create' ||
        this.icon === 'close' ||
        this.icon === 'vertical_align_bottom' ||
        this.icon === 'visibility'
      );
      this.disabled = disabled;
      this.cdr.detectChanges();
    }
  }

  onClickAction(evt: Event) {
    if (evt) evt.stopPropagation();

    if (!this.disabled) {
      if (this.action.observers.length > 0) this.action.emit(this.context ?? evt);
      else if (this.command) this.command(this.context ?? evt);
    }
  }

  get isAction(): boolean {
    return this.action.observers.length > 0;
  }
}
