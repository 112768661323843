// Angular Modules
import { NgModule } from '@angular/core';
// App Modules
import { CoreModule, LayoutsModule, ShowcaseAppModule, ShowcaseSharedModule } from '@nsi-showcase/components';
import { SharedModule, StatesModule } from '@spw-amie/components';
import { FeaturesModule } from './features/features.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const MODULES: any[] = [
  ShowcaseAppModule,
  StatesModule,
  CoreModule,
  SharedModule,
  ShowcaseSharedModule,
  FeaturesModule,
  LayoutsModule,
  AppRoutingModule,
];

const DECLARATIONS: any[] = [AppComponent];
@NgModule({
  imports: MODULES,
  declarations: DECLARATIONS,
  bootstrap: [AppComponent],
})
export class AppModule {}
