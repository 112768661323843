<p-calendar
    #calendar
    [ngModel]="innerValue"
    (ngModelChange)="updateModel($event)"
    [disabled]="disabled"
    [required]="required"
    [appendTo]="appendTo"
    [placeholder]="placeholder"
    [ariaLabelledBy]="ariaLabelledBy"
    [showButtonBar]="showButtonBar"
    [showIcon]="showIcon"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [dateFormat]="dateFormat"
    [yearNavigator]="yearNavigator"
    [monthNavigator]="monthNavigator"
    [showTime]="showTime"
    [timeOnly]="timeOnly"
    [yearRange]="yearRange"
    [icon]="icon"
    [style]="style"
    [autoZIndex]="autoZIndex"
    [baseZIndex]="baseZIndex"
    [clearButtonStyleClass]="clearButtonStyleClass"
    [dataType]="dataType"
    [defaultDate]="defaultDate"
    [disabledDates]="disabledDates"
    [disabledDays]="disabledDays"
    [hideOnDateTimeSelect]="hideOnDateTimeSelect"
    [hideTransitionOptions]="hideTransitionOptions"
    [hourFormat]="hourFormat"
    [inline]="inline"
    [inputId]="inputId"
    [inputStyle]="inputStyle"
    [inputStyleClass]="inputStyleClass"
    [keepInvalid]="keepInvalid"
    [maxDateCount]="maxDateCount"
    [multipleSeparator]="multipleSeparator"
    [name]="name"
    [numberOfMonths]="numberOfMonths"
    [panelStyle]="panelStyle"
    [panelStyleClass]="panelStyleClass"
    [rangeSeparator]="rangeSeparator"
    [readonlyInput]="readonlyInput"
    [selectionMode]="selectionMode"
    [selectOtherMonths]="selectOtherMonths"
    [shortYearCutoff]="shortYearCutoff"
    [showOnFocus]="showOnFocus"
    [showOtherMonths]="showOtherMonths"
    [showSeconds]="showSeconds"
    [showTransitionOptions]="showTransitionOptions"
    [showWeek]="showWeek"
    [stepHour]="stepHour"
    [stepMinute]="stepMinute"
    [stepSecond]="stepSecond"
    [styleClass]="styleClass"
    [tabindex]="tabindex"
    [timeSeparator]="timeSeparator"
    [todayButtonStyleClass]="todayButtonStyleClass"
    [touchUI]="touchUI"
    [view]="view"
    [locale]="locale"
    (onFocus)="onFocus.emit($event)"
    (onBlur)="blur($event)"
    (onClose)="onClose.emit($event)"
    (onSelect)="select($event, calendar)"
    (onInput)="onInput.emit($event)"
    (onTodayClick)="onTodayClick.emit($event)"
    (onClearClick)="onClearClick.emit($event)"
    (onMonthChange)="onMonthChange.emit($event)"
    (onYearChange)="onYearChange.emit($event)"
    (onClickOutside)="onClickOutside.emit($event)"
    (onShow)="onShow.emit($event)"
></p-calendar>
