import { NUMBER_REGEXP } from './regex.utils';

import * as shortid from 'shortid';

export class StringUtils {
  public static searchReplace(block: string, find: string, replace: string): string {
    return block.split(find).join(replace);
  }

  // Check num BCE
  // Si param2 est true -> n° d'unité d'établissement BCE
  // Si parame2 est null ou false -> n° d'entreprise BCE
  public static checkNumBCE(numBCE: string, isOtherBCE: boolean = false): boolean {
    if (numBCE === '') return true;

    numBCE = this.searchReplace(numBCE, ' ', '');
    numBCE = this.searchReplace(numBCE, '.', '');
    numBCE = this.searchReplace(numBCE, '-', '');

    if (numBCE.length > 10 || numBCE.length < 10) return false;

    let digitToCheck: number = +numBCE.substr(0, 1);

    if (isOtherBCE) {
      if (digitToCheck < 2 || digitToCheck > 8) return false;
    } else {
      if (digitToCheck > 1) return false;
    }

    let valueToVerifNumBCE: number = +numBCE.substring(numBCE.length - 2, numBCE.length);
    let verifNumBCE: number = 97 - (+numBCE.substring(0, numBCE.length - 2) % 97);

    return verifNumBCE === valueToVerifNumBCE;
  }

  public static checkRN(RN: string) {
    if (RN === '') return true;

    RN = this.searchReplace(RN, ' ', '');
    RN = this.searchReplace(RN, '.', '');
    RN = this.searchReplace(RN, '-', '');
    RN = this.searchReplace(RN, '_', '');

    if (RN.length !== 11) return false;

    let year: string = RN.substr(0, 2);
    let modRN: number;
    let correctRN: string;

    if (year >= '50' && year <= '99') modRN = 97 - (+RN.substring(0, RN.length - 2) % 97);
    else if (year < '50' && year >= '0') modRN = 97 - (+`2${RN.substring(0, RN.length - 2)}` % 97);

    if (modRN >= 0 && modRN <= 9) correctRN = `${RN.substr(0, 9)}0${modRN}`;
    else correctRN = RN.substr(0, 9) + modRN;

    return RN === correctRN;
  }

  public static isNumber(number: any) {
    if (number instanceof Number) {
      return true;
    } else {
      let numberAsString: string = String(number);
      return numberAsString.match(NUMBER_REGEXP);
    }
  }

  static uniqueId(): string {
    return shortid.generate();
  }

  /**
   * Compares 2 string values.
   * @param {string} s1
   * @param {string} s2
   * @returns {number}
   */
  static stringComparer(s1: string, s2: string): number {
    return s1 && s2 && s1.localeCompare(s2);
  }
}
