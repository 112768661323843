import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { ConfigService } from '../services/config.service';
import { AppConfig } from '../models/app-config.model';
import { LogLevelEnum, LOGGER } from '../services/logger.service';
import { TouchAction, RefreshToken } from '../states/authentication.state';

export async function init(injector: Injector): Promise<void> {
  console.log('Initializing application');

  // Retrieve services via the Injector (workaround for cyclic dependency error)
  addSplashItem('Configuration');
  const config: ConfigService = injector.get(ConfigService);
  const i18n: TranslateService = injector.get(TranslateService);
  const store: Store = injector.get(Store);

  await config.load('app', 'assets/config/app-config.json');
  const app = config.app as AppConfig;

  // Logging
  addSplashItem('Logging');
  LOGGER.clientLogLevel = LogLevelEnum.DEBUG;
  LOGGER.serverLogLevel = LogLevelEnum.ERROR;

  // LOGGER.loggingServiceUrl = '/api/log';

  // Translation
  addSplashItem('Translation');
  if (app.languages) {
    i18n.addLangs(app.languages);
  }

  i18n.setDefaultLang(app.lang || 'fr');

  // Langue du navigateur
  // let browserLang = i18n.getBrowserLang();
  let browserLang = i18n.getDefaultLang();
  LOGGER.debug(`Detected browser language : ${browserLang}`);

  if (i18n.getLangs().indexOf(browserLang) === -1) {
    browserLang = 'fr';
  }

  LOGGER.debug(`Using language : ${browserLang}`);
  await i18n.use(browserLang).toPromise();

  LOGGER.info('Core initialized');
  addSplashItem('Finishing core initialization');

  if (app.security) {
    if (app.securityForm) {
      await store.dispatch(new TouchAction()).toPromise();
    } else {
      await store.dispatch(new RefreshToken()).toPromise();
    }
  }
}

export function addSplashItem(txt: string): void {
  if (!document || !document.querySelector) {
    return;
  }

  const el: any = document.querySelector('#splash-text ul');
  if (el) {
    el.innerHTML += `<li>${txt}</li>`;
  }
}
