<!-- Fields -->
<div class="ui-g-12">
    <ng-container *ngIf="fieldRef" [ngSwitch]="fieldRef.fieldTypeCode">
        <!-- Text -->
        <span class="md-inputfield" *ngSwitchCase="FieldTypeCode.TXT">
            <label for="text" [class.required]="fieldRef.isMandatory">
                {{ fieldRef.label }}
            </label>
            <br />
            <input *ngIf="!fieldRef.values" [formControl]="formControl" id="text" type="text" pInputText />
            <!-- Options drowpdown -->
            <p-dropdown
                *ngIf="fieldRef.values"
                [formControl]="formControl"
                [placeholder]="'common.choose' | translate"
                [options]="fieldRef.options"
                [appendTo]="'body'"
            ></p-dropdown>
            <val-errors [control]="formControl"></val-errors>
        </span>

        <!-- Float -->
        <span class="md-inputfield" *ngSwitchCase="FieldTypeCode.FLOAT">
            <label for="float" [class.required]="fieldRef.isMandatory">
                {{ fieldRef.label }}
                <span *ngIf="fieldRef.minVal != null && fieldRef.minVal.length > 0">
                    ({{ 'ObjectModel.minVal' | translate }} : {{ fieldRef.minVal != null ? fieldRef.minVal : '' }} /
                </span>
                <span *ngIf="fieldRef.maxVal != null && fieldRef.maxVal.length > 0">
                    {{ 'ObjectModel.maxVal' | translate }} : {{ fieldRef.maxVal != null ? fieldRef.maxVal : '' }})
                </span>
            </label>
            <br />
            <input
                id="float"
                [formControl]="formControl"
                type="number"
                pInputText
                [min]="fieldRef.minVal != null ? fieldRef.minVal.toString() : ''"
                [max]="fieldRef.maxVal != null ? fieldRef.maxVal.toString() : ''"
            />
            <val-errors [control]="formControl"></val-errors>
        </span>

        <!-- Int -->
        <span class="md-inputfield" *ngSwitchCase="FieldTypeCode.INT">
            <label for="int" [class.required]="fieldRef.isMandatory">
                {{ fieldRef.label }}
                <span *ngIf="fieldRef.minVal != null && fieldRef.minVal.length > 0">
                    ({{ 'ObjectModel.minVal' | translate }} : {{ fieldRef.minVal != null ? fieldRef.minVal : '' }} /
                </span>
                <span *ngIf="fieldRef.maxVal != null && fieldRef.maxVal.length > 0">
                    {{ 'ObjectModel.maxVal' | translate }} : {{ fieldRef.maxVal != null ? fieldRef.maxVal : '' }})
                </span>
            </label>
            <br />
            <input
                id="int"
                [formControl]="formControl"
                type="number"
                pInputText
                [min]="fieldRef.minVal != null ? fieldRef.minVal.toString() : ''"
                [max]="fieldRef.maxVal != null ? fieldRef.maxVal.toString() : ''"
            />
            <val-errors [control]="formControl"></val-errors>
        </span>

        <!-- Date -->
        <span class="md-inputfield" *ngSwitchCase="FieldTypeCode.DATE">
            <label [class.required]="fieldRef.isMandatory">
                {{ fieldRef.label }}
            </label>
            <br />
            <app-calendar
                [formControl]="formControl"
                [showIcon]="false"
                [inputStyleClass]="'dateNoDisable'"
                [styleClass]="'dateNoDisable'"
            ></app-calendar>
            <val-errors [control]="formControl"></val-errors>
        </span>

        <!-- Timestamp -->
        <span class="md-inputfield" *ngSwitchCase="FieldTypeCode.TIMESTAMP">
            <label [class.required]="fieldRef.isMandatory">
                {{ fieldRef.label }}
            </label>
            <br />
            <app-calendar
                [formControl]="formControl"
                [showIcon]="false"
                [inputStyleClass]="'dateNoDisable'"
                [styleClass]="'dateNoDisable'"
                [showTime]="true"
            ></app-calendar>
            <val-errors [control]="formControl"></val-errors>
        </span>

        <!-- Image -->
        <span class="md-inputfield" *ngSwitchCase="FieldTypeCode.PIC">
            <label [class.required]="fieldRef.isMandatory">
                {{ fieldRef.label }}
            </label>
            <br />
            <div *ngIf="this.formControl.value != null">
                <img [src]="this.formControl.value" class="max-width-100" />
            </div>
            <div *ngIf="!this.formControl.disabled">
                <p-fileUpload
                    #fileUpload
                    auto="true"
                    mode="basic"
                    name="file"
                    accept="image/*"
                    customUpload="true"
                    (uploadHandler)="upload($event, fileUpload)"
                    [chooseLabel]="'common.choose-file' | translate"
                ></p-fileUpload>
            </div>
            <val-errors [control]="formControl"></val-errors>
        </span>
    </ng-container>
</div>
