import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { OrderModule } from 'ngx-order-pipe';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { NgxSecurityModule } from 'ngx-security';
import { ValdemortModule } from 'ngx-valdemort';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PickListModule } from 'primeng/picklist';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SpinnerModule } from 'primeng/spinner';
// PrimeNG import
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { DividerComponent } from './components/divider/divider.component';
import { TableCellComponent } from './components/table/table-cell/table-cell.component';
import { TableFilterHeaderComponent } from './components/table/table-filter-header/table-filter-header.component';
import {
  PropercasePipe,
  SafeHtmlPipe,
  TranslateLabelPipe,
  TranslateLov,
  TranslateRnPipe,
  StripHtmlPipe,
  EnumPipe,
  MapPipe,
} from './pipes';
import { FocusDirective, SelectDirective, DisableControlDirective, AddAndGoToPageDirective } from './directives';
import {
  CardTitleComponent,
  CardTitleToolbarComponent,
  ToolbarComponent,
  ToolbarDividerComponent,
  FormActionsComponent,
  SimpleDialogComponent,
  ConfirmDialogComponent,
  ErrorComponent,
  ActionIconComponent,
  CalendarComponent,
  ValdemortDefaultComponent,
  LoadingComponent,
  RichTextAreaComponent,
  HighlightComponent,
  ErrorAccessElementComponent,
  ShowcaseViewerModule,
} from './components';
import { ToggleButtonModule } from 'primeng/togglebutton';

const MODULES: any[] = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  TranslateModule,

  FontAwesomeModule, // Font awesome
  FlexLayoutModule, // Angular Flex Layout

  MomentModule, // ngx-moment
  NgxSecurityModule, // ngx-security
  OrderModule, // ngx-order-pipe
  ValdemortModule, // ngx‑valdemort

  // ngx-progressbar
  NgProgressModule,
  NgProgressHttpModule,
  NgProgressRouterModule,

  // PrimeNG
  ScrollPanelModule,
  InputTextModule,
  InputTextareaModule,
  TooltipModule,
  PanelModule,
  DropdownModule,
  ButtonModule,
  MenuModule,
  DialogModule,
  ConfirmDialogModule,
  BlockUIModule,
  RadioButtonModule,
  CalendarModule,
  TabViewModule,
  TreeModule,
  CheckboxModule,
  TriStateCheckboxModule,
  InputTextareaModule,
  AutoCompleteModule,
  FieldsetModule,
  InputMaskModule,
  FileUploadModule,
  PickListModule,
  InputSwitchModule,
  TreeTableModule,
  TableModule,
  DynamicDialogModule,
  ListboxModule,
  OverlayPanelModule,
  SpinnerModule,
  ToggleButtonModule,
  ShowcaseViewerModule,
];

const DECLARATIONS: any[] = [
  // Pipes
  PropercasePipe,
  SafeHtmlPipe,
  TranslateLov,
  TranslateLabelPipe,
  TranslateRnPipe,
  StripHtmlPipe,
  EnumPipe,
  MapPipe,

  // Directives
  FocusDirective,
  SelectDirective,
  DisableControlDirective,
  AddAndGoToPageDirective,

  // Components
  CardTitleComponent,
  CardTitleToolbarComponent,
  ToolbarComponent,
  ToolbarDividerComponent,
  FormActionsComponent,
  SimpleDialogComponent,
  ConfirmDialogComponent,
  ErrorComponent,
  ActionIconComponent,
  DividerComponent,
  CalendarComponent,
  ValdemortDefaultComponent,
  LoadingComponent,
  RichTextAreaComponent,
  ErrorAccessElementComponent,
  HighlightComponent,
  TableCellComponent,
  TableFilterHeaderComponent,
];

@NgModule({
  imports: MODULES,
  declarations: DECLARATIONS,
  exports: [...MODULES, ...DECLARATIONS],
})
export class ShowcaseSharedModule {}
