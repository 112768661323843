import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { CollectionAssignationModel } from '../../models/collection-assignation.model';

@Pipe({ name: 'formatCollection' })
export class FormatageCollectionPipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private i18n: TranslateService) {}

  transform(data: any, field: string): any {
    switch (field) {
      case 'assignations':
        const assignations: CollectionAssignationModel[] = data;
        return assignations && assignations.length > 0
          ? assignations
              .map((a) => a.user)
              .map((u) => `${u.firstName} ${u.lastName}`)
              .join(',')
          : this.i18n.instant('collection.no-user-assigned');
      case 'createAt':
      case 'updateAt':
        return this.datePipe.transform(data, 'dd-MM-yyyy');
      case 'statusCode':
        return this.i18n.instant('status.' + data);
      default:
        return data;
    }
  }
}
