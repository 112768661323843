import { FontAwesomeIconMetadata } from 'font-awesome-icon-chars';
import fa from 'font-awesome-icon-chars';

export interface Icon {
  unicode: string;
  name: string;
  type: string;
  char: string;
}
export class IconUtils {
  static fab = fa.brands.map((i) => IconUtils.toIcon(i, 'fab'));
  static fas = fa.solid.map((i) => IconUtils.toIcon(i, 'fas'));
  static far = fa.regular.map((i) => IconUtils.toIcon(i, 'far'));

  static icons = [...IconUtils.fab, ...IconUtils.far, ...IconUtils.fas].sort((a, b) => a.name.localeCompare(b.name));

  static toIcon(i: FontAwesomeIconMetadata, type: string): Icon {
    return {
      unicode: i.unicode,
      name: i.name,
      type: type,
      char: String.fromCharCode(parseInt(i.unicode, 16)),
    };
  }
  static getTypeFromClass(_class: string) {
    return _class.substring(0, 3);
  }
  static getNameFromClass(_class: string) {
    return _class.substring(7);
  }
  static getIconFromClass(_class: string) {
    const type = IconUtils.getTypeFromClass(_class);
    const name = IconUtils.getNameFromClass(_class);
    return this.icons.find((i) => i.name === name && i.type === type);
  }
}
