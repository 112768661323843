<ng-container *ngFor="let action of actions">
    <app-action-icon
        [key]="action.key"
        [title]="action.title | translate"
        [icon]="action.icon"
        [iconSize]="action.iconSize"
        [iconColorClass]="action.iconColorClass"
        [url]="action.url"
        [routerLink]="action.routerLink"
        [active]="action.active"
        [disabled]="action.disabled"
        [tooltipPosition]="action.tooltipPosition"
        [command]="action.command"
        [class]="action.class"
        [menuItems]="action.menuItems"
        [readonly]="readonly"
        [context]="context"
    ></app-action-icon>
</ng-container>
