import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { AuthenticationState, UserAdministrationState } from '@nsi-showcase/components';

const MODULES: any[] = [NgxsModule.forFeature([AuthenticationState, UserAdministrationState])];

const DECLARATIONS: any[] = [];

@NgModule({
  imports: MODULES,
  declarations: DECLARATIONS,
})
export class StatesModule {}
