import { AbstractSpwGeoviewerHelper } from './geoviewer-abstract.helper';
import { Subject } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class IdentifySpwGeoviewerHelper extends AbstractSpwGeoviewerHelper {
  tolerance = 10;
  _identifyHandler;

  activateIdentify(layerUrl) {
    const spwMap = this.spwMap;
    const identify$ = new Subject();
    this.require('dojo/on').subscribe(([on]) => {
      this._identifyHandler = on(spwMap, spwMap.events.MapClicked, (x, y, srid) => {
        this.onMapClick(layerUrl, x, y, srid).subscribe((result) => {
          identify$.next(result);
        });
      });
    });

    return identify$;
  }

  onMapClick(layerUrl, x, y, srid) {
    const task$ = new Subject();
    this.require('esri/SpatialReference', 'esri/geometry/Point', 'esri/tasks/QueryTask', 'esri/tasks/query').pipe(
      switchMap(([SpatialReference, Point, QueryTask, Query]) => {
        const mapPoint = new Point(x, y, new SpatialReference({ wkid: srid }));
        return this.pointToExtent(mapPoint, this.tolerance).pipe(
          tap((geometry) => {
            const query = new Query();
            query.outFields = ['*'];
            query.returnGeometry = true;
            query.distance = 10;
            query.geometry = geometry;
            const queryTask = new QueryTask(layerUrl);
            queryTask.execute(
              query,
              (point, event) => {
                task$.next({ geom: point, event: event, success: true });
              },
              (event) => {
                task$.next({ geom: undefined, event: event, success: false });
              }
            );
          })
        );
      })
    );

    return task$;
  }

  pointToExtent(point, pixelTol) {
    return this.require('esri/geometry/Extent').pipe(
      map(([Extent]) => {
        const esriMap = this.spwMap.esriMap;
        const pixelWidth = esriMap.extent.getWidth() / esriMap.width;
        const tolerance = pixelTol * pixelWidth;
        return new Extent(
          point.x - tolerance,
          point.y - tolerance,
          point.x + tolerance,
          point.y + tolerance,
          esriMap.spatialReference
        );
      })
    );
  }

  deactivateIdentify() {
    if (this._identifyHandler) {
      this._identifyHandler.remove();
    }
  }
}
