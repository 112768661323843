import { Pipe, PipeTransform } from '@angular/core';

/*
 * Strips HTML
 * Takes an input parameter HTML.
 * Usage:
 *   content | striphtml
 * Example:
 *   <p [innerHTML]="content | striphtml"></p>
 */
@Pipe({
  name: 'striphtml',
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: any): any {
    if (value === null || value === '') {
      return '';
    } else {
      value = value.replace(/<(?:.|\n)*?>/gm, ' ');
      value = value.replace(/&nbsp;/g, ''); // Remove more than one space, exemple = a  b (two spaces between a and b)
      return value;
    }
  }
}
