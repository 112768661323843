import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { of, Subject } from 'rxjs';
import { catchError, exhaustMap, filter, map, tap } from 'rxjs/operators';
import { LoginAction } from '../../states';
import { markFormAsTouched } from '../../core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  private onSignin$: Subject<MouseEvent>;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private router: Router,
    private title: Title,
    private i18n: TranslateService
  ) {
    this.loginForm = fb.group({
      username: fb.control(null, Validators.required),
      password: fb.control(null, Validators.required),
    });

    this.onSignin$ = new Subject();
  }

  ngOnInit() {
    // Navigator Title
    this.title.setTitle(`${this.i18n.instant('app.title')} - ${this.i18n.instant('login.title')}`);

    const login$ = this.onSignin$.pipe(
      tap((event) => markFormAsTouched(this.loginForm)),
      filter((event) => this.loginForm.valid),
      map((event) => this.loginForm.value),
      // exhaustMap will wait the end of the action before dispatching a new one
      exhaustMap((credentials) =>
        this.store.dispatch(new LoginAction(credentials)).pipe(
          // when exhaustMap has error, it completes the observable chain (which means no retry after)
          catchError((err) => {
            // do custom notification here
            // console.log(err);
            return of(null);
          }),
          // since we always return a value because of catchError, we want to ignore null and prevent subscribe call
          filter((action) => action)
        )
      )
    );

    login$.subscribe(() => this.router.navigate(['/']));
  }

  public onClickSigninButton(event: MouseEvent) {
    this.onSignin$.next(event);
  }
}
