import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ObjectModel, PointModel, TypeModel, VSearchObjectModel } from '../models';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MapService {
  protected objectList = new BehaviorSubject<VSearchObjectModel[]>(null);
  protected typeList = new BehaviorSubject<TypeModel[]>(null);
  protected coordinateSource = new BehaviorSubject<Coordinates>(null);

  protected mapPointClickedSource = new Subject<PointModel>();
  protected objectSource = new BehaviorSubject<ObjectModel>(null);
  protected mapObjectIdSource = new BehaviorSubject<number>(null);
  protected editedSource = new BehaviorSubject<ObjectModel>(null);
  protected highlightedSource = new Subject<VSearchObjectModel>();

  objectList$ = this.objectList.asObservable();
  typeList$ = this.typeList.asObservable();
  selected$ = this.objectSource.asObservable();
  highlighted$ = this.highlightedSource.asObservable();
  mapSelectedObjectId$ = this.mapObjectIdSource.asObservable();
  mapPointClicked$ = this.mapPointClickedSource.asObservable().pipe(filter((p) => !!p));

  edited$ = this.editedSource.asObservable();

  ngViewerInitialized$ = new BehaviorSubject<boolean>(null);

  setObjectList(list: VSearchObjectModel[]) {
    this.objectList.next(list);
  }

  setTypeList(list: TypeModel[]) {
    this.typeList.next(list);
  }

  selectObject(object) {
    this.objectSource.next(object);
  }

  highlightObject(object) {
    this.highlightedSource.next(object);
  }

  activateEdit(object) {
    this.editedSource.next(object);
  }

  deactivateEdit() {
    this.editedSource.next(null);
  }

  mapSelectObject(id: number) {
    this.mapObjectIdSource.next(id);
  }

  mapPointClicked(evt) {
    this.mapPointClickedSource.next(evt);
  }
}
