import { NgModule } from '@angular/core';

import { LayoutBreadcrumbComponent } from './layout.breadcrumb.component';
import { LayoutComponent } from './layout.component';
import { LayoutFooterComponent } from './layout.footer.component';
import { LayoutMenuComponent } from './layout.menu.component';
import { LayoutInlineProfileComponent } from './layout.profile.component';
import { LayoutRightpanelComponent } from './layout.rightpanel.component';
import { LayoutTopbarComponent } from './layout.topbar.component';
import { ShowcaseSharedModule } from '../../shared';
import { LayoutMenuitemComponent } from './layout.menuitem.component';
import { LayoutConfigComponent } from './layout.config.component';

@NgModule({
  declarations: [
    LayoutComponent,
    LayoutTopbarComponent,
    LayoutFooterComponent,
    LayoutInlineProfileComponent,
    LayoutMenuComponent,
    LayoutMenuitemComponent,
    LayoutRightpanelComponent,
    LayoutBreadcrumbComponent,
    LayoutConfigComponent,
  ],
  imports: [ShowcaseSharedModule],
  exports: [LayoutComponent],
})
export class MainLayoutModule {}
