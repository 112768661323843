import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormUtils } from '@nsi/core-utils';
import { Table } from 'primeng/table';
import { flatten } from '../../../../core/utils';
import { TableColumn } from '../../../../models/primeng';

@Component({
  selector: 'app-table-filter-header',
  templateUrl: './table-filter-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableFilterHeaderComponent<T> implements OnInit {
  @Input() column: TableColumn<T>;
  @Input() pTable: Table;
  FormUtils = FormUtils;

  constructor() {}

  ngOnInit(): void {}

  filter(value: any) {
    this.pTable.filter(value, this.flattenField, this.matchMode);
  }

  filterField(value: any, field: any) {
    this.pTable.filter(value, field, this.matchMode);
  }

  get flattenField() {
    return flatten(this.column.field);
  }

  get matchMode() {
    if (this.column.filterMatchMode) {
      return this.column.filterMatchMode;
    } else {
      let compare = 'contains';
      switch (this.column.type) {
        case 'number':
        case 'boolean':
          {
            compare = 'equals';
          }
          break;
        case 'date':
          {
            compare = 'dateStringMatch';
          }
          break;
        case 'timestamp':
          {
            compare = 'dateStringMatch';
          }
          break;
        case 'rn':
          {
            compare = 'rnMatch';
          }
          break;
      }
      return compare;
    }
  }
}
