import Feature from 'ol/Feature';
import { LineString, Point } from 'ol/geom';
import WKT from 'ol/format/WKT';
import { ObjectModel } from '../../models';
import Projection from 'ol/proj/Projection';

export class OlUtils {
  private static wkt = new WKT();

  static getWKTPointFromCoordinates(c1: number, c2: number, projection: string) {
    return this.wkt.writeFeature(new Feature(new Point([c1, c2])), {
      dataProjection: projection,
    });
  }

  static getWKTLinestringFromCoordinates(c1: number, c2: number, c3: number, c4: number, projection: string) {
    return this.wkt.writeFeature(
      new Feature(
        new LineString([
          [c1, c2],
          [c3, c4],
        ])
      ),
      {
        dataProjection: projection,
      }
    );
  }

  static getFeaturesFromObjects(
    objects: ObjectModel[],
    dataProjection: string | Projection,
    featureProjection?: string | Projection
  ): Feature[] {
    const array = objects.map((o) => this.getFeaturesFromObject(o, dataProjection, featureProjection));
    if (array.length > 0) {
      return array.reduce((a, b) => a.concat(b));
    }
  }

  static getFeaturesFromObject(
    object: ObjectModel,
    dataProjection: string | Projection,
    featureProjection?: string | Projection
  ): Feature[] {
    return [...this.getWKTsForObject(object)]
      .filter((wkt) => wkt != null)
      .map((wkt) => {
        const feature = this.wkt.readFeature(wkt, {
          dataProjection: dataProjection,
          featureProjection: featureProjection ?? dataProjection,
        });
        feature.setProperties(object);
        return feature;
      });
  }

  static getWKTsForObject(object: ObjectModel): string[] {
    return [object?.position?.wkt, object?.segment?.wkt, object?.segment?.wktEnd, object?.segment?.wktStart];
  }

  static getFeatureFromWKT(
    wkt: string,
    dataProjection: string | Projection,
    featureProjection?: string | Projection
  ): Feature {
    return this.wkt.readFeature(wkt, {
      dataProjection: dataProjection,
      featureProjection: featureProjection ?? dataProjection,
    });
  }
}
