import { Component, ElementRef, ViewChild, Input, OnInit, OnDestroy } from '@angular/core';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { GeoviewerState } from '../../../../services/viewer/geoviewer.state';

export enum VIEWER_FRAME_MODE {
  INSTANT = 'INSTANT',
  DEFERED = 'DEFERED',
}
/**
 * Encapsulate the geoviewer iframe
 * Create a loader while the geoviewer loads
 * Provide methods for geoviewer service to use it
 *
 */
@Component({
  selector: 'nsi-viewer-iframe',
  templateUrl: './viewer-iframe.component.html',
  styleUrls: ['./viewer-iframe.component.scss'],
})
export class ViewerIframeComponent implements OnInit {
  @Input()
  mode = VIEWER_FRAME_MODE.INSTANT;

  @Input()
  context: string;

  @Input()
  token: string;

  @Input()
  apiUrl: string;

  loaded$ = new BehaviorSubject<boolean>(false);

  @ViewChild('iframe', { static: true })
  private _iframe: ElementRef;

  @ViewChild('loader', { static: true })
  private _loader: ElementRef;

  /**
   * Get the inner iframe
   */
  get iframe(): HTMLIFrameElement {
    return this._iframe?.nativeElement;
  }

  get viewer() {
    const contentWindow: any = this.iframe?.contentWindow;
    return contentWindow?.showcaseViewerComponent;
  }

  /**
   * Get the loader
   */
  get loader(): HTMLDivElement {
    return this._loader.nativeElement;
  }

  constructor(private geoviewerState: GeoviewerState) {}
  ngOnInit(): void {
    if (this.mode === VIEWER_FRAME_MODE.INSTANT) {
      this.load().subscribe(() => {
        this.loader.classList.toggle('hide-loader');
      });
    }
  }

  load(apiUrl = this.apiUrl, token = this.token, context = this.context) {
    this.geoviewerState
      .load(this, token, context)
      .pipe(filter((l) => l))
      .subscribe(() => {
        if (this.iframe.contentWindow) {
          this.iframe.contentWindow['loaded$'] = this.loaded$;
        }
      });
    return this.loaded$;
  }
}
