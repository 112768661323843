import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TopBarService implements OnDestroy {
  private event$: Subject<string>;

  constructor() {
    this.event$ = new Subject();
  }

  ngOnDestroy() {
    this.event$.complete();
  }

  public select(item?: string) {
    return item ? this.event$.pipe(filter((event) => event === item)) : this.event$;
  }

  public dispatch(item: string) {
    this.event$.next(item);
  }
}
