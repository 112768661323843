import { FormGroup } from '@angular/forms';
import { ObjectModel, FieldRefsModel, FieldModel, FieldTypeCode, FieldFormModel } from '../../models';

export class FieldModelUtils {
  static getField(item: ObjectModel, fieldRef: FieldRefsModel): FieldModel {
    let field: FieldModel = null;
    if (item) {
      switch (fieldRef.fieldTypeCode) {
        case FieldTypeCode.TXT:
          field = item.fieldTxts.find((f) => f.fieldRefId === fieldRef.id);
          break;
        case FieldTypeCode.FLOAT:
          field = item.fieldFloats.find((f) => f.fieldRefId === fieldRef.id);
          break;
        case FieldTypeCode.INT:
          field = item.fieldInts.find((f) => f.fieldRefId === fieldRef.id);
          break;
        case FieldTypeCode.DATE:
          field = item.fieldDates.find((f) => f.fieldRefId === fieldRef.id);
          break;
        case FieldTypeCode.TIMESTAMP:
          field = item.fieldTimeStamps.find((f) => f.fieldRefId === fieldRef.id);
          break;
        case FieldTypeCode.PIC:
          field = item.fieldPictures.find((f) => f.fieldRefId === fieldRef.id);
          break;
      }
    }
    return field;
  }

  static getFiedForm(item: ObjectModel, fieldRef: FieldRefsModel): FieldFormModel {
    return { field: this.getField(item, fieldRef), fieldRef };
  }

  static getFiedFormArray(item: ObjectModel, fieldRef: FieldRefsModel[]): FieldFormModel[] {
    return fieldRef.map((fr) => this.getFiedForm(item, fr));
  }

  static parseAsObjectModel(item: ObjectModel, itemForm: FormGroup): ObjectModel {
    const model = Object.assign({}, item, itemForm.value);

    if (!model.objectTypeCode) model.objectTypeCode = item.objectTypeCode;

    model.fieldDates = [];
    model.fieldFloats = [];
    model.fieldInts = [];
    model.fieldPictures = [];
    model.fieldTimeStamps = [];
    model.fieldTxts = [];
    model.fieldForms.forEach((fieldForm) => {
      switch (fieldForm.fieldRef.fieldTypeCode) {
        case FieldTypeCode.TXT:
          if (!model.fieldTxts) {
            model.fieldTxts = [];
          }
          model.fieldTxts.push(fieldForm.field);
          break;
        case FieldTypeCode.FLOAT:
          if (!model.fieldFloats) {
            model.fieldFloats = [];
          }
          model.fieldFloats.push(fieldForm.field);
          break;
        case FieldTypeCode.INT:
          if (!model.fieldInts) {
            model.fieldInts = [];
          }
          model.fieldInts.push(fieldForm.field);
          break;
        case FieldTypeCode.DATE:
          if (!model.fieldDates) {
            model.fieldDates = [];
          }
          model.fieldDates.push(fieldForm.field);
          break;
        case FieldTypeCode.TIMESTAMP:
          if (!model.fieldTimeStamps) {
            model.fieldTimeStamps = [];
          }
          model.fieldTimeStamps.push(fieldForm.field);
          break;
        case FieldTypeCode.PIC:
          if (!model.fieldPictures) {
            model.fieldPictures = [];
          }
          model.fieldPictures.push(fieldForm.field);
          break;
      }
    });
    return model;
  }
}
