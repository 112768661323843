import { AbstractSpwGeoviewerHelper } from './geoviewer-abstract.helper';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class ZoomSpwGeoviewerHelper extends AbstractSpwGeoviewerHelper {
  zoomToPolygon(polygon) {
    this.zoomToGeomType(polygon, 'Polygon');
  }

  zoomToPolyline(polyline) {
    this.zoomToGeomType(polyline, 'Polyline');
  }

  zoomToPoint(point) {
    this.zoomToGeomType(point, 'Point');
  }

  zoomToGeomType(_geom, type) {
    this.getGraphic(_geom, { type: type }).subscribe((graphic) => {
      this.spwMap.zoomToFeature(graphic);
    });
  }

  zoomToFeatue(feature) {
    this.zoomToFeatures([feature]);
  }

  zoomToFeatures(features) {
    this.spwMap.zoomToFeatures(features);
  }

  zoomToBbox(bbox) {
    this.spwMap.zoomToBbox(bbox.xMin, bbox.xMax, bbox.yMin, bbox.yMax);
  }
}
