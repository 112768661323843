import { NgModule } from '@angular/core';
import { LoginLayoutComponent } from './login-layout.component';

import { LoginComponent } from './login.component';
import { ShowcaseSharedModule } from '../../shared';

@NgModule({
  declarations: [LoginLayoutComponent, LoginComponent],
  imports: [ShowcaseSharedModule],
  exports: [LoginLayoutComponent],
})
export class LoginLayoutModule {}
