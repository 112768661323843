import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'enum' })
export class EnumPipe implements PipeTransform {
  result: string;
  private enum: string;
  private value: string;

  transform(value: string, ...args: any[]): string | any {
    if (typeof value !== 'string' || (args.length !== 1 && args.length !== 2)) {
      return value;
    }
    if (this.enum === args[0] && this.value === value.toUpperCase()) {
      return this.result;
    }

    this.enum = args[0];
    this.value = value.toUpperCase();

    if (args.length === 1) {
      this.result = this.enum[this.value];
    } else if (args.length === 2) {
      this.result = `${args[1]}.${this.enum[this.value]}`;
    }
    return this.result;
  }
}
