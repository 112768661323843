import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';

import { Footer, Header } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { Observable, Subject } from 'rxjs';
import { ConfigService } from '../../../../services/config.service';
import { AppConfig } from '../../../../models/app-config.model';

@Component({
  selector: 'app-dialog',
  template: `
    <p-dialog
      [(visible)]="context"
      [header]="header"
      [draggable]="draggable"
      [keepInViewport]="keepInViewport"
      [resizable]="resizable"
      [contentStyle]="contentStyle"
      [modal]="modal"
      [position]="position"
      [blockScroll]="blockScroll"
      [closeOnEscape]="closeOnEscape"
      [dismissableMask]="dismissableMask"
      [rtl]="rtl"
      [closable]="closable"
      [appendTo]="appendTo"
      [style]="style"
      [styleClass]="styleClass"
      [showHeader]="showHeader"
      [baseZIndex]="baseZIndex"
      [autoZIndex]="autoZIndex"
      [minX]="minX"
      [minY]="minY"
      [focusOnShow]="focusOnShow"
      [focusTrap]="focusTrap"
      [maximizable]="maximizable"
      [transitionOptions]="transitionOptions"
      [closeIcon]="closeIcon"
      [minimizeIcon]="minimizeIcon"
      [maximizeIcon]="maximizeIcon"
      (onShow)="shown.emit($event)"
      (onHide)="hidden.emit($event)"
    >
      <p-header *ngIf="headerFacet && headerFacet.first">
        <ng-content select="p-header"></ng-content>
      </p-header>

      <ng-content></ng-content>

      <p-footer *ngIf="footerFacet && footerFacet.first">
        <ng-content select="p-footer"></ng-content>
      </p-footer>
    </p-dialog>
  `,
})
export class SimpleDialogComponent implements OnInit {
  // From PrimeNG Dialog
  @Input() header: string;
  @Input() draggable = true;
  @Input() resizable = true;
  @Input() width: string;
  @Input() height: string;
  @Input() contentStyle: any;
  @Input() visible = false;
  @Input() modal = true;
  @Input() blockScroll = false;
  @Input() closeOnEscape = true;
  @Input() dismissableMask = false;
  @Input() rtl = false;
  @Input() closable = true;
  @Input() appendTo: any;
  @Input() style: any;
  @Input() styleClass: string;
  @Input() showHeader = true;
  @Input() position = 'center';
  @Input() baseZIndex: number;
  @Input() autoZIndex = true;
  @Input() minX = 0;
  @Input() minY = 0;
  @Input() focusOnShow = true;
  @Input() focusTrap = false;
  @Input() maximizable = true;
  @Input() transitionOptions = '150ms cubic-bezier(0, 0, 0.2, 1)';
  @Input() closeIcon = 'pi pi-times';
  @Input() minimizeIcon = 'pi pi-window-minimize';
  @Input() maximizeIcon = 'pi pi-window-maximize';
  @Input() maximize = false;
  @Input() keepInViewport = true;

  @Output() shown = new EventEmitter<void>();
  @Output() hidden = new EventEmitter<void>();

  @Output() confirmed = new EventEmitter<void>();
  @Output() dismissed = new EventEmitter<void>();

  @ContentChildren(Header, { descendants: false }) headerFacet: QueryList<Header>;
  @ContentChildren(Footer, { descendants: false }) footerFacet: QueryList<Footer>;

  @ViewChild(Dialog, { static: true }) dialog: Dialog;

  public confirmSubject: Subject<boolean>;
  public context: any;
  public result: any;

  constructor(private config: ConfigService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    const appConfig = <AppConfig>this.config.app;

    if (this.maximize) {
      this.style = { ...this.style, ...{ width: '100%', height: '100%' } };
      this.contentStyle = {
        ...this.contentStyle,
        ...{ minHeight: 'calc(100vh - 114px)' },
      };
      this.maximizable = false;
    } else {
      if (this.width) {
        this.style = { ...this.style, ...{ width: this.width } };
      } else if (!this.width && appConfig.dialogWidth) {
        this.style = { ...this.style, ...{ width: appConfig.dialogWidth } };
      }

      if (this.height) {
        this.contentStyle = {
          ...this.contentStyle,
          ...{ height: this.height },
        };
      }
    }
  }

  public show(context: any = {}): Observable<boolean> {
    this.context = context;
    this.cdr.detectChanges();

    this.confirmSubject = new Subject<boolean>();
    const obs = this.confirmSubject.asObservable();

    obs.subscribe((result) => {
      if (result) {
        this.confirmed.emit();
      } else {
        this.dismissed.emit();
      }

      this.reset();
    });

    return obs;
  }

  public hide(): void {
    this.reset();
  }

  public confirm(result?: any): void {
    this.result = result != null ? result : this.context;
    this.confirmSubject.next(true);
  }

  public dismiss(): void {
    this.result = null;
    if (this.confirmSubject != null) {
      this.confirmSubject.next(false);
    }
  }

  /** @deprecated */
  public center(): void {
    // Center not supported by PrimeNG
    // setTimeout(() => {
    //   if (this.dialog.container) {
    //     this.dialog.center();
    //   }
    // });
  }

  private reset(): void {
    if (this.confirmSubject != null) {
      this.confirmSubject.unsubscribe();
      this.confirmSubject = null;
      this.context = null;
      this.cdr.detectChanges();
    }
  }
}
