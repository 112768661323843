<p-confirmDialog #cd [baseZIndex]="10000">
    <p-footer>
        <ng-container *ngIf="isError">
            <button
                type="button"
                pButton
                icon="ui-icon-check"
                label="{{ 'common.ok' | translate }}"
                (click)="cd.accept()"
            ></button>
        </ng-container>

        <ng-container *ngIf="!isError">
            <button
                type="button"
                pButton
                icon="ui-icon-cancel"
                label="{{ rejectLabel }}"
                class="ui-button-secondary mr-12"
                (click)="cd.reject()"
            ></button>
            <button type="button" pButton icon="ui-icon-check" label="{{ acceptLabel }}" (click)="cd.accept()"></button>
        </ng-container>
    </p-footer>
</p-confirmDialog>
