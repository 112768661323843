import { NgModule } from '@angular/core';
import { LoginMultipleComponent } from './login-multiple.component';
import { ShowcaseSharedModule } from '../../shared';

@NgModule({
  declarations: [LoginMultipleComponent],
  imports: [ShowcaseSharedModule],
  exports: [LoginMultipleComponent],
})
export class LoginMultipleModule {}
