import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoadingComponent, ErrorComponent, ErrorAccessElementComponent } from '../shared';

export const ROUTES: Routes = [
  { path: 'loading', component: LoadingComponent },
  { path: 'access-element-denied', component: ErrorAccessElementComponent },
  {
    path: 'access-denied',
    component: ErrorComponent,
    data: { errorCode: 401 },
  },
  { path: 'error', component: ErrorComponent },
  { path: '**', component: ErrorComponent, data: { errorCode: 404 } },
];

@NgModule({
  providers: [],
  imports: [
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
