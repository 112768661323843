import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-actions',
  templateUrl: './form-actions.component.html',
  styleUrls: ['./form-actions.component.scss'],
})
export class FormActionsComponent implements OnInit, AfterViewChecked {
  @Input() cancelDisabled: boolean;
  @Input() cancelLabel: string;
  @Input() cancelIcon: string;
  @Input() cancelClass: string;
  @Input() cancelHidden: boolean;

  @Input() saveDisabled: boolean;
  @Input() saveLabel: string;
  @Input() saveIcon: string;
  @Input() saveClass: string;
  @Input() saveHidden: boolean;

  @Input() otherDisabled: boolean;
  @Input() otherLabel: string;
  @Input() otherIcon: string;
  @Input() otherClass: string;
  @Input() otherHidden: boolean;
  @Input() otherLeft: boolean;

  @Input() readonly: boolean;

  @Output() cancel = new EventEmitter(null);
  @Output() save = new EventEmitter();
  @Output() other = new EventEmitter();

  constructor(private route: ActivatedRoute, private i18n: TranslateService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  ngAfterViewChecked(): void {
    const readOnly =
      this.readonly === undefined
        ? !!this.route && this.route.snapshot && this.route.snapshot.data && this.route.snapshot.data['readOnly']
          ? this.route.snapshot.data['readOnly']
          : false
        : this.readonly;
    if (readOnly && !this.saveHidden) {
      this.saveHidden = true;
      this.cancelLabel = this.i18n.instant('common.close');
      this.cdr.detectChanges();
    }
  }

  onCancel(evt: any): void {
    this.cancel.emit(evt);
  }

  onSave(evt: any): void {
    this.save.emit(evt);
  }

  onOther(evt: any): void {
    this.other.emit(evt);
  }

  get isCancel(): boolean {
    return this.cancel.observers.length > 0;
  }

  get isSave(): boolean {
    return this.save.observers.length > 0;
  }

  get isOther(): boolean {
    return this.other.observers.length > 0;
  }
}
