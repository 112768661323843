import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Environment } from '@nsi-showcase/components';
import { AmieEnvironment } from '@spw-amie/components';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic([
  { provide: Environment, useValue: environment },
  { provide: AmieEnvironment, useValue: environment },
])
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
