import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'translateDecimal', pure: false })
export class TranslateDecimal implements PipeTransform {
  constructor() {}

  transform(number: number | any): number | any {
    if (number === null || number === undefined || number === '') {
      return '';
    }

    if (typeof number !== 'number') {
      return 'Only number are supported';
    }
    let translate = number.toString().replace('.', ',');
    return translate;
  }
}
