import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { LightCollectionModel, TypeModel } from '../../models';
import { TypeService } from '../../services';
import { HttpParams } from '@angular/common/http';
import { TypeSearchModel } from '../../models';
import { Page } from '@nsi-showcase/components';

@Injectable({
  providedIn: 'root',
})
export class ObjectTypeState {
  private types: BehaviorSubject<TypeModel[]> = new BehaviorSubject<TypeModel[]>(null);
  public types$: Observable<TypeModel[]> = this.types.asObservable();

  private selectedObjectType: BehaviorSubject<TypeModel> = new BehaviorSubject<TypeModel>(null);
  public selectedObjectType$: Observable<TypeModel> = this.selectedObjectType.asObservable();

  public lastSearch: TypeSearchModel = {} as TypeSearchModel;
  private lastParams: HttpParams = new HttpParams().set('page', '0').set('size', '10').set('sort', 'code');

  private currentPage: BehaviorSubject<Page<LightCollectionModel>> = new BehaviorSubject<Page<LightCollectionModel>>(
    null
  );
  public currentPage$: Observable<Page<LightCollectionModel>> = this.currentPage.asObservable();

  constructor(private typeService: TypeService) {}

  public select(object: TypeModel) {
    this.selectedObjectType.next(object);
  }

  public findByCollectionId(collectionId: number): Observable<TypeModel[]> {
    return this.typeService.findByCollectionId(collectionId).pipe(
      first(),
      tap((types) => this.types.next(types))
    );
  }

  public search(
    eventSearch: TypeSearchModel = this.lastSearch,
    params: HttpParams = this.lastParams
  ): Observable<Page<TypeModel>> {
    this.lastSearch = eventSearch;
    this.lastParams = params;

    return this.typeService.search(eventSearch, params).pipe(
      first(),
      tap((page) => this.currentPage.next(page))
    );
  }

  public delete(id: number) {
    return this.typeService.delete(id).pipe(
      first(),
      tap(() => this.search().subscribe())
    );
  }
}
