import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DateUtils } from '@nsi/core-utils';
import { get as _get } from 'lodash';
import { flatten } from '../../../../core/utils';
import { TableColumn } from '../../../../models/primeng';

@Component({
  selector: 'app-table-cell',
  template: `
    <ng-container [ngSwitch]="column.type">
      <!-- Display booleans -->
      <ng-container *ngSwitchCase="'boolean'">
        <p-checkbox binary="true" [disabled]="true" [(ngModel)]="fieldValue"></p-checkbox>
      </ng-container>
      <!-- Display numbers -->
      <ng-container *ngSwitchCase="'number'">
        {{ displayed }}
      </ng-container>
      <ng-container *ngSwitchCase="'rn'">
        <span [pTooltip]="displayed | translateRn">{{ displayed | translateRn }}</span>
      </ng-container>
      <!-- Default display (string) -->
      <ng-container *ngSwitchDefault>
        <span [pTooltip]="displayed">{{ displayed }}</span>
      </ng-container>
    </ng-container>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCellComponent<T> implements OnInit {
  @Input() column: TableColumn<T>;
  @Input() rowData: T;

  fieldValue: string;
  displayed: string;

  constructor() {}

  ngOnInit(): void {
    this.fieldValue = this.column.get ? this.column.get(this.rowData) : _get(this.rowData, flatten(this.column.field));

    switch (this.column.type) {
      case 'timestamp':
        this.displayed = this.fieldValue ? DateUtils.format(this.fieldValue, 'DD-MM-YYYY hh:mm') : '';
        break;
      case 'date':
        this.displayed = this.fieldValue ? DateUtils.format(this.fieldValue, 'DD-MM-YYYY') : '';
        break;
      default:
        this.displayed = this.fieldValue;
    }
  }
}
