<ng-container [ngSwitch]="column.type" *ngIf="column.filter">
    <!-- Boolean -->
    <p-triStateCheckbox
        *ngSwitchCase="'boolean'"
        (onChange)="filter($event.value)"
        class="w-100-p"
    ></p-triStateCheckbox>
    <!-- Number -->
    <p-spinner #spinner *ngSwitchCase="'number'" (onChange)="filter(spinner.value)" class="w-100-p"></p-spinner>
    <!--Calendar-->
    <app-calendar
        *ngSwitchCase="'date'"
        [appendTo]="'body'"
        [yearRange]="FormUtils.searchDateRange"
        [dateFormat]="'dd-mm-yy'"
        (outDateChange)="filter($event)"
        [styleClass]="'w-100-p'"
        [inputStyleClass]="'w-100-p'"
    ></app-calendar>
    <app-calendar
        *ngSwitchCase="'timestamp'"
        [appendTo]="'body'"
        [yearRange]="FormUtils.searchDateRange"
        [dateFormat]="'dd-mm-yy'"
        (outDateChange)="filter($event)"
        [styleClass]="'w-100-p'"
        [inputStyleClass]="'w-100-p'"
    ></app-calendar>
    <!--  RN-->
    <p-inputMask
        *ngSwitchCase="'rn'"
        [mask]="FormUtils.rnMask"
        (onInput)="filter($event.target.value)"
        class="w-100-p"
    ></p-inputMask>
    <!-- default -->
    <input
        *ngSwitchDefault
        pInputText
        type="text"
        class="text-uppercase w-100-p"
        (input)="filter($event.target.value)"
    />
</ng-container>
