import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApplicationError } from '../app/app.errors';
import { Environment } from '../environment';

/**
 * BaseModel class for rest api services.
 */
export abstract class ApiService {
  protected http: HttpClient;
  protected environment: Environment;

  /**
   * Creates the ApiService instance.
   * @param {ApiServiceContext} context
   */
  protected constructor(protected context: ApiServiceContext) {
    this.http = context.http;
    this.environment = context.environment;
  }

  /**
   * Hanldes an error in the context of a Rest request.
   */
  protected handleError(error: any): Observable<any> {
    return throwError(new ApplicationError(error));
  }

  protected parseBlobError(err: any): Observable<any> {
    const reader: FileReader = new FileReader();

    return of(reader.readAsText(err.error)).pipe(
      tap(() => {
        reader.onloadend = () => {
          let jsonResult = JSON.parse(reader.result.toString());
          throwError(new ApplicationError({ code: jsonResult.status, error: jsonResult })).subscribe();
        };
      })
    );
  }

  protected handleTextError(error: any): Observable<any> {
    let jsonResult = JSON.parse(error.error);
    return throwError(
      new ApplicationError({
        code: jsonResult.status,
        error: jsonResult,
      })
    );
  }

  protected createRequestOption(req?: any): HttpParams {
    let options: HttpParams = new HttpParams();
    if (req) {
      Object.keys(req).forEach((key) => {
        if (key !== 'sort') {
          options = options.set(key, req[key]);
        }
      });
      if (req.sort) {
        req.sort.forEach((val) => {
          options = options.append('sort', val);
        });
      }
    }
    return options;
  }
}

/**
 * Container for services required by the ApiService.
 * This allows future extension of dependencies without changing all classes that derives from ApiService.
 */
@Injectable({ providedIn: 'root' })
export class ApiServiceContext {
  constructor(public http: HttpClient, public environment: Environment) {}
}
