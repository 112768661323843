import { Component, Input, OnInit } from '@angular/core';
import { Action } from '../../action';

@Component({
  selector: 'app-card-title-toolbar',
  templateUrl: './card-title-toolbar.component.html',
  styleUrls: ['./card-title-toolbar.component.scss'],
})
export class CardTitleToolbarComponent implements OnInit {
  @Input() title: string;
  @Input() icon: string;
  @Input() iconFa: string;
  @Input() actions: Action[];
  @Input() readonly: boolean;

  constructor() {}

  ngOnInit() {}
}
