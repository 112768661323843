import { NgModule } from '@angular/core';
import { ShowcaseViewerRoutingModule } from './viewer-routing.module';
import { ShowcaseViewerComponent } from './viewer.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ViewerIframeModule } from './viewer-iframe/viewer-iframe.module';
import { SpwGeoviewerModule } from './spw-geoviewer/spw-geoviewer.module';

const MODULES: any[] = [
  ShowcaseViewerRoutingModule,
  SpwGeoviewerModule,
  ViewerIframeModule,
  TranslateModule,
  CommonModule,
];

const DECLARATIONS: any[] = [ShowcaseViewerComponent];

@NgModule({
  imports: MODULES,
  declarations: DECLARATIONS,
  exports: [...DECLARATIONS, ...MODULES],
})
export class ShowcaseViewerModule {}
