import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'map' })
export class MapPipe implements PipeTransform {
  array: Array<any>;
  field: string;
  separator: string;
  result: string;

  transform(array: Array<any>, ...args: any[]): string | any {
    if (args.length < 1 && args.length > 2) {
      return '';
    }
    if (this.array === array && this.field === args[0] && this.separator === args[1]) {
      return this.result;
    }

    this.array = array;
    this.field = args[0];
    this.separator = args[1];
    this.result = array.map((item) => item[this.field]).join(this.separator || ', ');
    return this.result;
  }
}
