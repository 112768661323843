import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, forwardRef, Inject, Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SpecificError } from '../models/specific-error.model';
import { UIService } from '../services/ui.service';
import { LOGGER } from '../services/logger.service';

/**
 * Represents a server error.
 */
export class ApplicationError {
  public code: number;
  public status: string;
  public message: string;

  public specificError: SpecificError;

  constructor(public sourceError: any) {
    // Parse error
    if (sourceError instanceof HttpErrorResponse) {
      let r: HttpErrorResponse = sourceError as HttpErrorResponse;

      this.code = r.status;
      this.status = r.statusText;
      this.message = r.message;
    } else if (sourceError instanceof Error) {
      let r: Error = sourceError as Error;

      this.code = -1;
      this.status = r.name;
      this.message = r.message;
    } else {
      this.code = sourceError.code ? sourceError.code : -1;
      this.status = sourceError.status ? sourceError.status : '';
      this.message = sourceError.message ? sourceError.message : '';
    }

    if (sourceError.error != null) {
      this.specificError = sourceError.error;
    }
  }
}

/**
 * Application global error handler.
 */
@Injectable({ providedIn: 'root' })
export class AppErrorHandler implements ErrorHandler {
  constructor(@Inject(forwardRef(() => Injector)) private injector: Injector) {}

  handleError(error: any): void {
    let originalError: any = null;

    if (!originalError) originalError = error;

    if ((originalError.message || '').startsWith('ExpressionChangedAfterItHasBeenCheckedError')) {
      console.error(originalError.message, originalError);
      return;
    }

    // Log error (and send to server)
    LOGGER.error('General Error', originalError.message, originalError);

    const ui = this.injector.get(UIService);
    let applicationError: ApplicationError;
    if (originalError instanceof ApplicationError) {
      applicationError = originalError as ApplicationError;
    } else if (originalError.rejection instanceof ApplicationError) {
      applicationError = originalError.rejection as ApplicationError;
    }

    if (applicationError) {
      const i18n = this.injector.get(TranslateService);

      if (applicationError.code === 401 && applicationError.specificError != null) {
        if (applicationError.specificError.detail) {
          ui.notif.warn(
            i18n.instant(applicationError.specificError.message, {
              detail: applicationError.specificError.detail,
            }),
            i18n.instant('error.unauthorized')
          );
        } else {
          ui.notif.warn(i18n.instant(applicationError.specificError.message), i18n.instant('error.unauthorized'));
        }
        // window.location.href = 'unauthorized.html';
        ui.progressComplete();
        return;
      }
      if (applicationError.code === 400 && applicationError.specificError != null) {
        if (applicationError.specificError.detail) {
          ui.notif.error(
            i18n.instant(applicationError.specificError.message, {
              detail: applicationError.specificError.detail,
            })
          );
        } else {
          ui.notif.error(i18n.instant(applicationError.specificError.message));
        }
        ui.progressComplete();
        return;
      }
    }

    // Show error on dialog
    // ui.showError(originalError.message.toString());
    ui.notif.error(originalError.message.toString());
    ui.progressComplete();
  }
}
