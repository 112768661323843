import { NgModule } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { ShowcaseSharedModule } from '../shared';
import { MainLayoutModule } from './main';
import { LoginLayoutModule } from './login';
import { LoginMultipleModule } from './login-multiple';

const MODULES = [ShowcaseSharedModule, MainLayoutModule, LoginLayoutModule, LoginMultipleModule];

const PROVIDERS: any[] = [DialogService];

@NgModule({
  imports: MODULES,
  exports: [MainLayoutModule, LoginLayoutModule, LoginMultipleModule],
  providers: PROVIDERS,
})
export class LayoutsModule {}
