import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-layout',
  template: `
    <div class="login-full">
      <div class="card login-panel ui-fluid">
        <div class="ui-g">
          <div class="ui-g-12">
            <img src="assets/images/wallonie-loader.png" alt="Wallonie" width="150" />
          </div>

          <app-login></app-login>
        </div>
      </div>
    </div>
  `,
})
export class LoginLayoutComponent implements OnInit, OnDestroy {
  private loginBodyClass: string;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.loginBodyClass = 'login-body';
  }

  ngOnInit() {
    this.document.body.classList.add(this.loginBodyClass);
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove(this.loginBodyClass);
  }
}
