import { Injectable } from '@angular/core';
import { PointModel } from '../models';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ViewerService {
  private _visible = true;
  get visible(): boolean {
    return this._visible;
  }
  set visible(value: boolean) {
    this._visible = value;
    this.show$.next(true);
  }

  public features$ = new BehaviorSubject<PointModel[]>([]);
  private _features: PointModel[];
  get features(): PointModel[] {
    return this._features;
  }
  set features(value: PointModel[]) {
    this._features = value;
    this.features$.next(this._features);
  }

  public show$ = new BehaviorSubject<boolean>(true);

  public show() {
    this.visible = true;
  }

  public hide() {
    this.visible = false;
  }
}
