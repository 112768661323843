import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { tap } from 'rxjs/internal/operators';
import { FieldTypeService } from '../../../services/field-type.service';
import { FieldRefsModel, FieldTypeModel } from '../../../models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const RangeValidator: ValidatorFn = (fg: FormGroup) => {
  const min = fg.get('minVal').value;
  const max = fg.get('maxVal').value;
  if (min == null || min.length <= 0 || max == null || max.length <= 0) {
    return null;
  } else {
    return Number(min) < Number(max) ? null : { range: true };
  }
};

@UntilDestroy()
@Component({
  selector: 'app-new-type-form',
  templateUrl: './new-type-form.component.html',
  styleUrls: ['./new-type-form.component.scss'],
})
export class NewTypeFormComponent implements OnInit, OnDestroy {
  constructor(private fb: FormBuilder, public fieldTypeService: FieldTypeService, private i18n: TranslateService) {}

  public formGroup: FormGroup;
  public types$;
  public fieldTypes: SelectItem[] = [];
  @Input() lightEdit = true;

  @Output() fieldAdded = new EventEmitter<FieldRefsModel>();

  ngOnInit() {
    this.buildForm();
    this.types$ = this.fieldTypeService.getAll().pipe(
      map((res) => {
        res.map((type) => {
          this.fieldTypes.push({
            label: this.i18n.instant('type.' + (<FieldTypeModel>type).code),
            value: (<FieldTypeModel>type).code,
          });
        });
        this.fieldTypes = this.fieldTypes.sort((first, second) => first.label.localeCompare(second.label));
        return res;
      }),
      tap((r) => {
        const value = {
          fieldTypeCode: this.fieldTypes && this.fieldTypes.length > 0 ? this.fieldTypes[0].value : null,
        };
        this.formGroup.patchValue(value);
      })
    );
    this.formGroup.controls['fieldTypeCode'].valueChanges
      .pipe(
        tap((code) => {
          this.formGroup.controls['minVal'].reset();
          this.formGroup.controls['maxVal'].reset();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  addType() {
    const value: FieldRefsModel = this.formGroup.getRawValue();
    if (value.values) {
      value.values = (<string[]>value.values).join('|´|');
    }
    this.fieldAdded.emit(value);
  }

  buildForm() {
    this.formGroup = this.fb.group(
      {
        isMobile: [false],
        isMandatory: [false],
        label: ['', Validators.required],
        fieldTypeCode: ['', Validators.required],
        fieldorder: [''],
        minVal: [''],
        maxVal: [''],
        values: [''],
      },
      { validator: RangeValidator }
    );
  }

  updateField(field: FieldRefsModel) {
    if (field.values) {
      field.values = (<string>field.values).split('|´|');
    }
    this.formGroup.patchValue(field);
  }

  public reset() {
    this.formGroup.reset(this.defaultValue);
  }

  private get defaultValue() {
    return {
      isMobile: false,
      isMandatory: false,
      label: '',
      fieldTypeCode: this.fieldTypes[0].value,
    };
  }

  public onMandatoryChecked(event: any) {
    if (event.checked) {
      this.formGroup.get('isMobile').setValue(true);
      this.formGroup.get('isMobile').disable();
    } else {
      this.formGroup.get('isMobile').enable();
    }
  }

  ngOnDestroy(): void {}
}
