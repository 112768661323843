import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { ViewerOpener } from '../../models/viewer/viewer-opener.model';

@Injectable({ providedIn: 'root' })
export class WindowService {
  private _win: Window;

  /**
   * Get the main geoviewer window
   */
  static getMainWindow(): Window {
    let mainWindow = window;
    while (mainWindow.opener) {
      mainWindow = mainWindow.opener;
    }
    return mainWindow;
  }

  constructor() {}

  /**
   * Open a geoviewer instance in a new window,
   * or refocus on the existing window and only execute the callback
   *
   * @param opener the info to open the geoviewer
   */
  public open(opener: ViewerOpener) {
    if (this._win == null || this._win.closed) {
      this.openNewWindow(opener);
    } else {
      this.refreshWindow(opener);
    }
  }

  /**
   * In this order :
   * Open a new window
   * Load the geoviewer
   * Execute the callback
   *
   * @param opener the info to open the geoviewer
   */
  private openNewWindow(opener: ViewerOpener) {
    const h = window.innerHeight;
    const w = window.innerWidth;
    this._win = window.open(
      opener.url + '/' + opener.context,
      'geoviewer',
      'left=0,top=0,height=' + h + ',width=' + w + ',menubar=no,status=no,titlebar=no,resizable=1'
    );

    fromEvent(this._win, 'widgetsCreated').subscribe((e) => this.refreshWindow(opener));
    fromEvent(this._win, 'beforeunload').subscribe((e) => (this._win = null));
    fromEvent(WindowService.getMainWindow(), 'beforeunload').subscribe((e) => this.close());
  }

  /**
   * Re execute the callback
   *
   * @param opener the info to open the geoviewer
   */
  private refreshWindow(opener: ViewerOpener) {
    opener.callback(this._win);
    this._win.focus();
  }

  /**
   * Close the geoviewer window
   */
  public close(): void {
    this._win.close();
  }
}
