import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroupDirective,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FieldRefsModel, FieldTypeCode } from '../../../models';
import { FormUtils } from '../../utils';

@UntilDestroy()
@Component({
  selector: 'app-object-field',
  templateUrl: 'object-field.component.html',
  styles: [
    `
      :host {
        display: contents;
      }

      .max-width-100 {
        max-width: 100%;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ObjectFieldComponent),
      multi: true,
    },
    [
      {
        multi: true,
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => ObjectFieldComponent),
      },
    ],
  ],
})
export class ObjectFieldComponent implements ControlValueAccessor, Validator {
  _fieldRef: FieldRefsModel;
  formControl = new FormControl();
  FieldTypeCode = FieldTypeCode;
  validatorFns: ValidatorFn[] = [];

  @Input() set fieldRef(fieldRef: FieldRefsModel) {
    this._fieldRef = fieldRef;
    this.formControl.setValidators(FormUtils.getValidatorsByFieldRef(fieldRef));
    this.onValidatorChange();
    setTimeout((_) => this.formControl.updateValueAndValidity({ emitEvent: true }));
  }
  get fieldRef() {
    return this._fieldRef;
  }

  constructor() {}

  writeValue(obj: any): void {
    this.formControl.patchValue(obj);
  }

  onChange: (fieldRef: any) => void = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.formControl.valueChanges.subscribe(fn);
  }

  onTouched: () => void = () => {};
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onValidatorChange: () => void = () => {};
  registerOnValidatorChange(fn: () => void) {
    this.onValidatorChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  upload(event, fileUpload) {
    if (event.files && event.files[0]) {
      const file = event.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.onChange(reader.result);
        this.formControl.setValue(reader.result);
        fileUpload.clear();
      };
      reader.readAsDataURL(file);
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.formControl.valid ? null : { invalid: true };
  }
}
