import { FormGroup, FormArray, AbstractControl } from '@angular/forms';

function forEachControls(form: FormGroup | FormArray, markAs: (control: AbstractControl) => void) {
  for (const controlName in form.controls) {
    if (form.controls.hasOwnProperty(controlName)) {
      const control = form.controls[controlName];
      if (control instanceof FormGroup || control instanceof FormArray) {
        forEachControls(control, markAs);
      } else {
        markAs(control);
      }
    }
  }
}

export function markFormAsTouched(form: FormGroup | FormArray) {
  forEachControls(form, (control) => control.markAsTouched());
}
