import { Component, OnDestroy } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { LayoutBreadcrumbService } from '../../services';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './layout.breadcrumb.component.html',
  styleUrls: ['./layout.breadcrumb.component.scss'],
})
export class LayoutBreadcrumbComponent implements OnDestroy {
  subscription: Subscription;

  items: MenuItem[];

  constructor(public breadcrumbService: LayoutBreadcrumbService) {
    this.subscription = breadcrumbService.items$.subscribe((response) => {
      this.items = response;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
