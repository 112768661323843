import { NgModule } from '@angular/core';
import { SpwGeoviewerComponent } from './spw-geoviewer.component';

const MODULES: any[] = [];

const DECLARATIONS: any[] = [SpwGeoviewerComponent];

@NgModule({
  imports: MODULES,
  declarations: DECLARATIONS,
  exports: [...DECLARATIONS, ...MODULES],
})
export class SpwGeoviewerModule {}
