import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'formatUser' })
export class FormatageUserPipe implements PipeTransform {
  constructor(private i18n: TranslateService) {}

  transform(data: any, field: string): any {
    if (field == 'authorities') {
      return (<string[]>data).map((auth) => ' ' + this.i18n.instant('collection.' + auth));
    }
    return data;
  }
}
