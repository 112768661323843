<div class="layout-wrapper" [ngClass]="{ 'layout-compact': layoutCompact }" (click)="onLayoutClick()">
    <div
        #layoutContainer
        class="layout-container"
        [ngClass]="{
            'menu-layout-static': !isOverlay(),
            'menu-layout-overlay': isOverlay(),
            'layout-menu-overlay-active': overlayMenuActive,
            'menu-layout-horizontal': isHorizontal(),
            'menu-layout-slim': isSlim(),
            'layout-menu-static-inactive': staticMenuDesktopInactive,
            'layout-menu-static-active': staticMenuMobileActive
        }"
    >
        <app-topbar *ngIf="layout.headerView"></app-topbar>
        <app-breadcrumb *ngIf="layout.headerView && layout.breadcrumb"></app-breadcrumb>

        <div
            class="layout-menu"
            [ngClass]="{ 'layout-menu-dark': darkMenu }"
            (click)="onMenuClick($event)"
            [hidden]="!layout.headerView"
        >
            <app-inline-profile
                *ngIf="profileMode == 'inline' && !isHorizontal()"
                [hidden]="!layout.profileView"
            ></app-inline-profile>
            <app-menu></app-menu>
        </div>

        <div class="layout-main" [ngClass]="{ 'layout-main-no-header': !layout.headerView }">
            <div class="layout-content">
                <router-outlet></router-outlet>

                <app-footer *ngIf="layout.footerView"></app-footer>
            </div>
        </div>

        <app-rightpanel *ngIf="layout.rightPanel"></app-rightpanel>
        <app-layout-config *ngIf="layout.headerView && layout.layoutConfig"></app-layout-config>

        <div class="layout-mask"></div>
    </div>

    <app-confirm-dialog></app-confirm-dialog>
</div>
