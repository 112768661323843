<div fxFill>
  <app-card-title-toolbar [icon]="'input'" [title]="'collection.fields' | translate"
    [actions]="allowAdd ? actionsAdd : []"></app-card-title-toolbar>
  <div style="height: 55vh; overflow: auto;">
    <p-table [value]="baseType.fieldRefs" [autoLayout]="false" class="ui-table" cdkDropList
      (cdkDropListDropped)="drop($event)">
      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-index="rowIndex">
        <div class="ui-g-12" [pReorderableRow]="index" cdkDrag [cdkDragData]="rowData">
          <div *ngIf="index === 0" [ngStyle]="{ height: '15px' }"></div>
          <div class="ui-g">
            <div class="ui-g-9" [ngSwitch]="rowData.fieldTypeCode">
              <!--TXT-->
              <span *ngSwitchCase="TYPES.TXT">
                <label class="input-label" for="text">
                  {{ rowData.label }}
                  <span *ngIf="rowData.isMandatory" class="red">*</span>
                  <fa-icon class="mobile" *ngIf="rowData.isMobile" [icon]="'mobile-alt'"></fa-icon>
                </label>
                <input class="form-field" id="text" type="text" pInputText disabled
                  [placeholder]="'common.enter-text' | translate" />
              </span>

              <!--FLOAT-->
              <span *ngSwitchCase="TYPES.FLOAT">
                <label class="input-label" for="float">
                  {{ rowData.label }}
                  <span *ngIf="rowData.isMandatory" class="red">*</span>
                  <fa-icon class="mobile" *ngIf="rowData.isMobile" [icon]="'mobile-alt'"></fa-icon>
                </label>
                <input class="form-field" id="float" type="number" pInputText disabled [placeholder]="'100.50'" />
              </span>

              <!--INT-->
              <span *ngSwitchCase="TYPES.INT">
                <label class="input-label" for="int">
                  {{ rowData.label }}
                  <span *ngIf="rowData.isMandatory" class="red">*</span>
                  <fa-icon class="mobile" *ngIf="rowData.isMobile" [icon]="'mobile-alt'"></fa-icon>
                </label>
                <input class="form-field" id="int" type="number" pInputText disabled [placeholder]="'10000'" />
              </span>

              <!--DATE-->
              <span class="md-inputfield" *ngSwitchCase="TYPES.DATE">
                <label>
                  {{ rowData.label }}
                  <span *ngIf="rowData.isMandatory" class="red">*</span>
                  <fa-icon class="mobile" *ngIf="rowData.isMobile" [icon]="'mobile-alt'"></fa-icon>
                </label>
                <br />
                <app-calendar [showIcon]="false" [inputStyleClass]="'dateNoDisable'" [styleClass]="'dateNoDisable'"
                  disabled [(ngModel)]="defaultDate"></app-calendar>
              </span>

              <!--TIMESTAMP-->
              <span class="md-inputfield" *ngSwitchCase="TYPES.TIMESTAMP">
                <label class="input-label">
                  {{ rowData.label }}
                  <span *ngIf="rowData.isMandatory" class="red">*</span>
                  <fa-icon class="mobile" *ngIf="rowData.isMobile" [icon]="'mobile-alt'"></fa-icon>
                </label>
                <br />
                <app-calendar [showIcon]="false" [inputStyleClass]="'dateNoDisable'" [styleClass]="'dateNoDisable'"
                  [showTime]="true" disabled [(ngModel)]="defaultDate"></app-calendar>
              </span>

              <!--PIC-->
              <span class="md-inputfield" *ngSwitchCase="TYPES.PIC">
                <label for="pic">
                  {{ rowData.label }}
                  <span *ngIf="rowData.isMandatory" class="red">*</span>
                  <fa-icon class="mobile" *ngIf="rowData.isMobile" [icon]="'mobile-alt'"></fa-icon>
                </label>
                <br />
                <p-fileUpload id="pic" #fileUpload auto="true" mode="basic" name="file" accept="image/*"
                  customUpload="true" disabled [chooseLabel]="'common.choose-file' | translate"></p-fileUpload>
              </span>
            </div>
            <div class="ui-g-3">
              <app-action-icon *ngIf="allowDelete" [icon]="'delete_forever'" [title]="'common.delete' | translate"
                (action)="confirmRemoveField(rowData)"></app-action-icon>
              <app-action-icon [icon]="'edit'" [title]="'common.edit' | translate" (action)="editField(rowData)">
              </app-action-icon>
              <i *ngIf="allowAdd" class="material-icons mover" cdkDragHandle>reorder</i>
            </div>
          </div>
        </div>
      </ng-template>
    </p-table>
  </div>
</div>

<p-dialog header="{{ 'collection.new-field' | translate }}" (onHide)="onHideDialog()" [(visible)]="dialogVisible">
  <app-new-type-form [lightEdit]="fieldToUpdate && !allowEdit " #newFieldForm (fieldAdded)="newFieldAdded($event)">
  </app-new-type-form>
</p-dialog>
