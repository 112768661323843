import { Component, OnInit } from '@angular/core';
import { AppConfig } from '../../../models';
import { ConfigService } from '../../../services/config.service';
import { ActivatedRoute } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'nsi-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
})
export class ShowcaseViewerComponent implements OnInit {
  appConfig: AppConfig;
  token;
  context;
  params$;

  constructor(private config: ConfigService, private route: ActivatedRoute) {
    this.appConfig = <AppConfig>config.app;
  }

  ngOnInit(): void {
    this.params$ = combineLatest([this.route.data, this.route.paramMap]).pipe(
      map(([data, params]) => {
        return {
          apiUrl: data.apiUrl,
          viewer: params.get('viewer'),
          token: params.get('token'),
          context: params.get('context'),
        };
      }),
      tap((p) => {
        console.log('[ShowcaseViewerComponent] params', p);
      })
    );
  }
}
