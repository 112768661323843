import { BaseAuditable } from '../base-auditable.model';
import { User } from '../user.model';
import { Base } from '../base.model';

export interface Role extends BaseAuditable {
  name?: string;
  label?: string;
}

export interface SecUserRole extends Base {
  defaultRole?: boolean;
  role: Role;
  user: User;
}

export interface Profile extends BaseAuditable {
  name?: string;
  label?: string;
  principal?: boolean;
}

export interface Policy extends BaseAuditable {
  name?: string;
  description?: string;
  action?: string;
  priority?: 'NORMAL' | 'BLOCKING';
  target?: string;
  condition?: string;
}

export interface UserRole extends BaseAuditable {
  roleId?: number;
  user?: User;
}

export enum PermissionType {
  CONTROL = 'CONTROL',
  ELEMENT = 'ELEMENT',
}

export interface Permission extends BaseAuditable {
  name?: string;
  access?: boolean;
  read?: boolean;
  write?: boolean;
}

export interface RouteElementData {
  id?: string;
  url?: string;
  path?: string;
}

export function securityRoutePermsData(perms: string[], redirectTo: string = '/access-denied'): any {
  return {
    security: {
      permissions: perms,
      redirectTo: redirectTo,
    },
  };
}

export function securityAuthenticatedRoutePermsData(perms: string[], redirectTo: string = '/access-denied'): any {
  return {
    security: {
      authenticated: true,
      permissions: perms,
      redirectTo: redirectTo,
    },
  };
}

export function securityRouteRolesData(roles: string[], redirectTo: string = '/access-denied'): any {
  return {
    security: {
      roles: roles,
      redirectTo: redirectTo,
    },
  };
}

export function securityAuthenticatedRouteRolesData(roles: string[], redirectTo: string = '/access-denied'): any {
  return {
    security: {
      authenticated: true,
      roles: roles,
      redirectTo: redirectTo,
    },
  };
}
