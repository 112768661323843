import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Role, Permission, PermissionType } from '../models/security/security.model';
import { User } from '../models/user.model';
import { SecurityService } from '../services/security.service';

// Roles
export class GetRoles {
  static readonly type = '[Role] Get all roles';
}

export class SelectRole {
  static readonly type = '[Role] Selected role';

  constructor(public role: Role) {}
}

export class SaveRole {
  static readonly type = '[Role] Saved role';

  constructor(public role: Role) {}
}

export class DeleteRole {
  static readonly type = '[Role] Deleted role';

  constructor(public role: Role) {}
}

// Permissions
export class GetPermissionsByTypeAndRole {
  static readonly type = '[Permission] Get permissions by type and role';

  constructor(public type: string, public role: string) {}
}

export class PatchPermission {
  static readonly type = '[Permission] Patch permission';

  constructor(public type: string, public role: string, public permission: Permission) {}
}

// Users
export class GetUsersRole {
  static readonly type = '[User] Got users for role';

  constructor(public roleId: number) {}
}

export class SecurityStateModel {
  public roles?: Role[];
  public selectedRole?: Role;
  public permissions?: Permission[];
  public users?: User[];
}

const defaults = {
  roles: [],
  selectedRole: null,
  permissions: [],
  users: [],
};

@State<SecurityStateModel>({ name: 'role', defaults })
@Injectable()
export class SecurityState {
  constructor(private securityService: SecurityService) {}

  // Selectors

  // Roles
  @Selector()
  static roles(state: SecurityStateModel) {
    return state.roles ? state.roles.map((r) => <Role>{ ...r }) : [];
  }

  @Selector()
  static selectedRole(state: SecurityStateModel) {
    return { ...state.selectedRole };
  }

  // Permissions
  @Selector()
  static permissions(state: SecurityStateModel) {
    return state.permissions ? state.permissions.map((p) => <Permission>{ ...p }) : [];
  }

  // Users
  @Selector()
  static users(state: SecurityStateModel) {
    return state.users.slice();
  }

  // Actions

  // Roles
  @Action(GetRoles)
  public getAll({ patchState }: StateContext<SecurityStateModel>): Observable<Role[]> {
    return this.securityService.getRoles().pipe(tap((roles) => patchState({ roles: roles })));
  }

  @Action(SelectRole)
  public selectRole({ dispatch, patchState }: StateContext<SecurityStateModel>, action: SelectRole): Observable<Role> {
    return of(action.role).pipe(
      tap((role) => {
        patchState({ selectedRole: role });
        dispatch(new GetPermissionsByTypeAndRole(PermissionType.CONTROL, role.name));
        dispatch(new GetUsersRole(role.id));
      })
    );

    // patchState({selectedRole: action.role});
    // return of(action.role);
  }

  @Action(SaveRole)
  public saveRole({ dispatch, patchState }: StateContext<SecurityStateModel>, action: SaveRole): Observable<Role> {
    return this.securityService.saveRole(action.role).pipe(
      tap((role) => patchState({ selectedRole: role })),
      switchMap((role) => dispatch(new GetRoles()).pipe(map(() => role)))
    );
  }

  @Action(DeleteRole)
  public deleteRole({ dispatch, getState }: StateContext<SecurityStateModel>, action: DeleteRole): Observable<Role> {
    return this.securityService
      .deleteRole(action.role.id)
      .pipe(switchMap(() => dispatch(new GetRoles()).pipe(map(() => action.role))));
  }

  // Permissions
  @Action(GetPermissionsByTypeAndRole)
  public getPermissionsByTypeAndRole(
    { patchState }: StateContext<SecurityStateModel>,
    action: GetPermissionsByTypeAndRole
  ): Observable<Permission[]> {
    return this.securityService
      .getPermissionsByTypeAndRole(action.type, action.role)
      .pipe(tap((permissions) => patchState({ permissions: permissions })));
  }

  @Action(PatchPermission)
  public patchPermission(
    { dispatch, getState }: StateContext<SecurityStateModel>,
    action: PatchPermission
  ): Observable<Permission[]> {
    return this.securityService
      .patchPermission(action.type, action.role, action.permission)
      .pipe(
        switchMap(() =>
          dispatch(new GetPermissionsByTypeAndRole(action.type, action.role)).pipe(map(() => getState().permissions))
        )
      );
  }

  // Users
  @Action(GetUsersRole)
  public getUsersRole({ patchState }: StateContext<SecurityStateModel>, action: GetUsersRole): Observable<User[]> {
    return this.securityService.getUsersByRole(action.roleId).pipe(tap((users) => patchState({ users })));
  }
}
