import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { replace } from 'lodash';
import { NgxSecurityGuardOptions } from 'ngx-security';
import { UIService } from '../../../services/ui.service';
import { LayoutBreadcrumbService } from '../../../services/layout-breadcrumb.service';
import { RouteElementData } from '../../../models/security/security.model';

@Component({
  templateUrl: './error-access-element.component.html',
  styleUrls: ['./error-access-element.component.scss'],
})

// Example : this is an Error access component specific for TWICE to manage read/write elements => work with SecurityAnyPermissionsChildGuard
export class ErrorAccessElementComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private ui: UIService,
    private breadcrumbService: LayoutBreadcrumbService,
    private i18n: TranslateService
  ) {
    const securityGuardOptions =
      !!route.snapshot && route.snapshot.data ? (route.snapshot.data['security'] as NgxSecurityGuardOptions) : {};
    const routeElementData =
      !!route.snapshot && route.snapshot.data ? (route.snapshot.data['routeElementData'] as RouteElementData) : {};

    const isDossier = routeElementData.url.indexOf('dossier') !== -1;
    const routerLink = isDossier
      ? {
          label: this.i18n.instant('dossier.search.title'),
          routerLink: ['/dossiers'],
        }
      : {
          label: this.i18n.instant('etablissement.recherche.titre'),
          routerLink: ['/etablissements'],
        };

    let permissionToTranslate = '';
    if (securityGuardOptions.permissions && securityGuardOptions.permissions.length > 0) {
      permissionToTranslate = replace(securityGuardOptions.permissions[0], '_READ', '');
      permissionToTranslate = replace(permissionToTranslate, '_WRITE', '');
    }

    // LayoutBreadcrumb
    this.breadcrumbService.setItems([
      routerLink,
      { label: routeElementData.id },
      { label: this.i18n.instant(`security.element.${permissionToTranslate}`) },
    ]);
  }

  ngOnInit(): void {}
}
