import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ValdemortConfig } from 'ngx-valdemort';

@Component({
  selector: 'app-valdemort-default',
  templateUrl: './valdemort-default.component.html',
  styleUrls: ['./valdemort-default.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ValdemortDefaultComponent implements OnInit {
  constructor(private valdemortConfig: ValdemortConfig) {}

  ngOnInit() {
    // Config Valdemort
    this.valdemortConfig.errorsClasses = 'validator-error';
  }
}
