import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LayoutBreadcrumbService {
  items$ = new BehaviorSubject<MenuItem[]>([]);

  setItems(items: MenuItem[]) {
    setTimeout(() => this.items$.next(items));
  }

  getLast(): Observable<string> {
    return this.items$.pipe(
      map((items) => {
        if (items && items.length > 0) {
          return items[items.length - 1].label;
        } else {
          of('');
        }
      })
    );
  }
}
