<form [formGroup]="loginForm">
    <div class="ui-g ui-fluid form-group">
        <div class="ui-g-12">
            <span class="md-inputfield">
                <input formControlName="username" id="username" type="text" size="30" pInputText required autofocus />
                <label for="username">{{ 'login.username' | translate }}</label>
                <val-errors controlName="username"></val-errors>
            </span>
        </div>

        <div class="ui-g-12">
            <span class="md-inputfield">
                <input
                    formControlName="password"
                    id="password"
                    type="password"
                    autocomplete="off"
                    size="30"
                    pInputText
                    required
                />
                <label for="password">{{ 'login.password' | translate }}</label>
                <val-errors controlName="password"></val-errors>
            </span>
        </div>

        <div class="ui-g-12">
            <button
                pButton
                type="submit"
                icon="fa fa-fw ui-c ui-icon-person"
                [label]="'login.signin' | translate"
                (click)="onClickSigninButton($event)"
            ></button>
        </div>
    </div>
</form>
