import { Component, Input, OnInit } from '@angular/core';
import { Action } from '../action.model';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
})
export class ToolbarComponent implements OnInit {
  @Input() actions: Action[];
  @Input() readonly: boolean;
  @Input() context: any;

  constructor() {}

  ngOnInit() {}
}
