import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StatusModel } from '../models';
import { AbstractService } from './abstract.service';
import { UiNotifI18nService } from './ui-notif.service';
import { AmieEnvironment } from '../environment';

@Injectable({ providedIn: 'root' })
export class StatusService extends AbstractService<StatusModel> {
  resourceName = 'statusRest';

  constructor(protected http: HttpClient, protected notif: UiNotifI18nService, protected env: AmieEnvironment) {
    super(http, notif, env);
  }
}
