<p-autoComplete
    [(ngModel)]="value"
    [suggestions]="segments$ | async"
    (completeMethod)="searchSegments($event)"
    [field]="field"
    [delay]="delay"
    [minLength]="minLength"
    [disabled]="disabled"
    (onSelect)="onSelectSegment($event)"
    (onClear)="onClear()"
    [forceSelection]="true"
    [styleClass]="'d-block'"
    [inputStyleClass]="'w-100-p'"
    class="d-block"
></p-autoComplete>
<label>Min: {{min}} - Max: {{max}}</label>