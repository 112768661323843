/**
 * Test if matches an email.
 * ___
 * ACCEPTED
 * john.doe@domain.com
 * john!#$%&'*+/=?^_`{|}~-doe@domain.com
 * ___
 * REJECTED
 * john.doedomain.com
 * john.doe@domain!#$%&'*+/=?^_`{|}~-.com
 *
 * @link http://regexr.com/4bc53
 */
export const EMAIL_REGEXP: RegExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

/**
 * Test if matches a number.
 * ___
 * ACCEPTED
 * 0123456789
 * ___
 * REJECTED
 * abcde
 * ABCDE
 *
 */
export const NUMBER_REGEXP: RegExp = new RegExp('^[0-9]*$');

/**
 * Test if matches a lov code as an upper snake case.
 * ___
 * ACCEPTED
 * I_LOVE_LOV_4VER
 * ___
 * REJECTED
 * why_so_much_hate_?
 *
 * @link https://regexr.com/4bemo
 */
export const LOV_CODE_REGEXP: RegExp = new RegExp('^[_0-9A-Z]*$');

export function lovDomCodeRegexBuilder(separator: string) {
  return new RegExp(`^${separator}[_0-9A-Z]${separator}[_0-9A-Z]${separator}$`);
}

export const LOV_DOMCODE_REGEXP: RegExp = lovDomCodeRegexBuilder('#');

/**
 * Test if matches an IBAN account number.
 * ___
 * ACCEPTED
 *
 * ___
 * REJECTED
 *
 *
 * @link https://regexr.com/2viok
 */
export const IBAN_REGEXP: RegExp = /(NO)[0-9A-Z]{2}[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{3}|(NO)[0-9A-Z]{15}|(BE)[0-9A-Z]{2}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}|(BE)[0-9A-Z]{16}|(DK|FO|FI|GL|NL)[0-9A-Z]{2}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{2}|(DK|FO|FI|GL|NL)[0-9A-Z]{18}|(MK|SI)[0-9A-Z]{2}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{3}|(MK|SI)[0-9A-Z]{19}|(BA|EE|KZ|LT|LU|AT)[0-9A-Z]{2}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}|(BA|EE|KZ|LT|LU|AT)[0-9A-Z]{20}|(HR|LI|LV|CH)[0-9A-Z]{2}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{1}|(HR|LI|LV|CH)[0-9A-Z]{21}|(BG|DE|IE|ME|RS|GB)[0-9A-Z]{2}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{2}|(BG|DE|IE|ME|RS|GB)[0-9A-Z]{22}|(GI|IL)[0-9A-Z]{2}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{3}|(GI|IL)[0-9A-Z]{23}|(AD|CZ|SA|RO|SK|ES|SE|TN)[0-9A-Z]{2}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}|(AD|CZ|SA|RO|SK|ES|SE|TN)[0-9A-Z]{24}|(PT)[0-9A-Z]{2}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{1}|(PT)[0-9A-Z]{25}|(IS|TR)[0-9A-Z]{2}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{2}|(IS|TR)[0-9A-Z]{26}|(FR|GR|IT|MC|SM)[0-9A-Z]{2}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{3}|(FR|GR|IT|MC|SM)[0-9A-Z]{27}|(AL|CY|HU|LB|PL)[0-9A-Z]{2}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}|(AL|CY|HU|LB|PL)[0-9A-Z]{28}|(MU)[0-9A-Z]{2}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{2}|(MU)[0-9A-Z]{30}|(MT)[0-9A-Z]{2}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{4}[ ]?[0-9A-Z]{3}|(MT)[0-9A-Z]{31}/g;

/**
 * Test if matches a BIC account number.
 * ___
 * ACCEPTED
 *
 * ___
 * REJECTED
 *
 * @link https://regexr.com/39lq5
 */
export const BIC_REGEXP: RegExp = /^([a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?)$/;

/**
 * Test if matches a VAT number.
 * ___
 * ACCEPTED
 * BE0123456789
 * BE9876543210
 * BE001234567
 * BE076543210
 * ___
 * REJECTED
 * BE01234567891
 * BE98765432109
 * BE101234567
 * BE176543210
 *
 * @link https://regexr.com/4bemf
 */
export const VAT_REGEXP: RegExp = /^([a-zA-Z]{2})([0-9]{10}|0[0-9]{8})$/;
