import { Injectable } from '@angular/core';
import { first, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { GeoviewerService } from './geoviewer.service';
import { GeoviewerError } from './geoviewer.error';
import { ViewerIframeComponent } from '../../shared/components/viewer/viewer-iframe/viewer-iframe.component';

@Injectable({ providedIn: 'root' })
export class GeoviewerState {
  private _window: Window = null;

  private loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loaded$: Observable<boolean> = this.loaded.asObservable();

  private polygonSelectionActivated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public polygonSelectionActivated$: Observable<boolean> = this.polygonSelectionActivated.asObservable();

  private polygonSelectionGeometry: BehaviorSubject<any> = new BehaviorSubject<boolean>(null);
  public polygonSelectionGeometry$: Observable<any> = this.polygonSelectionGeometry.asObservable();

  private pointSelectionActivated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public pointSelectionActivated$: Observable<boolean> = this.pointSelectionActivated.asObservable();

  private pointSelectionGeometry: BehaviorSubject<any> = new BehaviorSubject<boolean>(null);
  public pointSelectionGeometry$: Observable<any> = this.pointSelectionGeometry.asObservable();

  private identifiedFeature: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public identifiedFeature$: Observable<any> = this.identifiedFeature.asObservable();

  constructor(private readonly geoviewerService: GeoviewerService) {}

  /**
   * Load the geoviewer iFrame
   */
  public load(component: ViewerIframeComponent, token = 'DEFAULT', context = 'DEFAULT') {
    this.geoviewerService
      .openIFrame(component, token, context)
      .pipe(
        first(),
        tap((win) => this.injectState(win)),
        tap((win) => this.setCurrentWindow(win)),
        tap((_) => this.loaded.next(true))
      )
      .subscribe();
    return this.loaded$;
  }

  /**
   * Set current geoviewer window
   * @param win the window
   */
  setCurrentWindow(win: Window): void {
    this._window = win;
  }

  /**
   * Inject the current class and the actions as a global variable for the geoviewer
   * @param win the geoviewer window
   */
  private injectState(win: Window) {
    win['state'] = this;
  }
}
