/**
 * Util class containing all permissions constants
 * !!! Generated code, any manual modification will be erased !!!
 */
export enum SecPermissions {
  /**
   * Affichage du menu d'administration
   */
  ADMIN = 'ADMIN',

  /**
   * Administration des utilisateurs
   */
  ADMIN_USER = 'ADMIN_USER',

  /**
   * Gestion des formulaires
   */
  FORM_MANAGEMENT = 'FORM_MANAGEMENT',

  /**
   * Recherche de campagne de recensement
   */
  FORM_SEARCH = 'FORM_SEARCH',

  /**
   * Creation de campagne de recensement
   */
  FORM_CREATE = 'FORM_CREATE',

  /**
   * Edition de campagne de recensement
   */
  FORM_EDIT = 'FORM_EDIT',

  /**
   * Copie de campagne de recensement
   */
  FORM_COPY = 'FORM_COPY',

  /**
   * Copie de campagne de recensement
   */
  FORM_DELETE = 'FORM_DELETE',

  /**
   * Recherche de campagne de recensement
   */
  COLLECTION_SEARCH = 'COLLECTION_SEARCH',

  /**
   * Creation de campagne de recensement
   */
  COLLECTION_CREATE = 'COLLECTION_CREATE',

  /**
   * Edition de campagne de recensement
   */
  COLLECTION_EDIT = 'COLLECTION_EDIT',

  /**
   * Assignation de campagne de recensement
   */
  COLLECTION_ASSIGN = 'COLLECTION_ASSIGN',

  /**
   * Terminer un recensement
   */
  COLLECTION_INVENTORY_CLOSE = 'COLLECTION_INVENTORY_CLOSE',

  /**
   * Copie de campagne de recensement
   */
  COLLECTION_COPY = 'COLLECTION_COPY',

  /**
   * Suppression de campagne de recensement
   */
  COLLECTION_DELETE = 'COLLECTION_DELETE',

  /**
   * Recherche d'objects
   */
  OBJECT_SEARCH = 'OBJECT_SEARCH',

  /**
   * Creation d'objects
   */
  OBJECT_CREATE = 'OBJECT_CREATE',

  /**
   * Edition d'objects
   */
  OBJECT_EDIT = 'OBJECT_EDIT',

  /**
   * Copie d'objects
   */
  OBJECT_COPY = 'OBJECT_COPY',
  /**
   * Suppression d'objects
   */
  OBJECT_DELETE = 'OBJECT_DELETE',

  /**
   * Changement de statut d'objects
   */
  OBJECT_STATUS_CHANGE = 'OBJECT_STATUS_CHANGE',

  /**
   * Sécurité : Sauvegarde d'un utilisateur
   */
  SEC_USER_SAVE = 'USER_SAVE',

  /**
   * Sécurité : Lecture d'un utilisateur
   */
  SEC_USER_READ = 'USER_READ',

  /**
   * Sécurité : Lecture d'un rôle
   */
  SEC_ROLE_READ = 'ROLE_READ',
}
