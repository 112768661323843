import { FormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Component, HostListener } from '@angular/core';

@UntilDestroy()
@Component({ template: '' })
export abstract class FormCanDeactivateComponent {
  protected forms: FormGroup[] = [];

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) $event.returnValue = true;
  }

  canDeactivate(): boolean {
    if (this.forms == null || this.forms.length === 0) return true;

    // const results = this.forms.find(f => f.pristine || !f.dirty);
    let formInvalid = false;
    for (let form of this.forms) {
      if (!form.pristine || form.dirty) {
        formInvalid = true;
        break;
      }
    }
    return !formInvalid;
  }
}
