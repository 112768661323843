import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { User } from '../models';
import { UserService } from '../services';

const ACTION_PREFIX = '[User administration] ';

export class GetAllUsers {
  static readonly type = ACTION_PREFIX + 'Get all users';
}

export class CreateUser {
  static readonly type = ACTION_PREFIX + 'Create user';

  constructor(public user: User) {}
}

export class SaveUser {
  static readonly type = ACTION_PREFIX + 'Save user';

  constructor(public user: User, public currentUser: boolean = false) {}
}

export class SetCurrentUser {
  static readonly type = ACTION_PREFIX + 'Saved current user';

  constructor(public user: User) {}
}

export class UserAdministrationStateModel {
  public users: User[];
}

const defaults: UserAdministrationStateModel = {
  users: [],
};

@State<UserAdministrationStateModel>({ name: 'userAdministration', defaults })
@Injectable()
export class UserAdministrationState {
  constructor(private userService: UserService) {}

  @Selector()
  static users(state: UserAdministrationStateModel): User[] {
    return state.users ? state.users.map((e) => <User>{ ...e }) : [];
  }

  @Action(GetAllUsers)
  public findAllUsers({ patchState }: StateContext<UserAdministrationStateModel>): Observable<User[]> {
    return this.userService.findAll().pipe(tap((users) => patchState({ users })));
  }

  @Action(SaveUser)
  public save({ dispatch }: StateContext<UserAdministrationStateModel>, action: SaveUser): Observable<User> {
    return this.userService.save(action.user).pipe(
      switchMap((user) => {
        if (action.currentUser) {
          dispatch(new SetCurrentUser(action.user));
        }
        return dispatch(new GetAllUsers()).pipe(map(() => user));
      })
    );
  }

  @Action(CreateUser)
  public create({ dispatch }: StateContext<UserAdministrationStateModel>, action: CreateUser): Observable<User> {
    return this.userService.create(action.user).pipe(
      switchMap((user) => {
        return dispatch(new GetAllUsers()).pipe(map(() => user));
      })
    );
  }
}
