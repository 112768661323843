import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';
import { UiNotifI18nService } from './ui-notif.service';
import { Role } from '@nsi-showcase/components';
import { AmieEnvironment } from '../environment';

@Injectable({ providedIn: 'root' })
export class RoleService extends AbstractService<Role> {
  resourceName = 'auth/roles';

  constructor(protected http: HttpClient, protected notif: UiNotifI18nService, protected env: AmieEnvironment) {
    super(http, notif, env);
  }
}
