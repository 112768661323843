<val-default-errors>
    <ng-template valError="required">{{ 'common.form.error-required' | translate }}</ng-template>
    <ng-template valError="maxlength" let-error="error">
        {{ 'common.form.error-max-length' | translate: error }}
    </ng-template>
    <ng-template valError="minlength" let-error="error">
        {{ 'common.form.error-min-length' | translate: error }}
    </ng-template>
    <ng-template valError="max" let-error="error">{{ 'common.form.error-max' | translate: error }}</ng-template>
    <ng-template valError="min" let-error="error">{{ 'common.form.error-min' | translate: error }}</ng-template>
    <ng-template valError="validateEmail">{{ 'common.form.error-mail' | translate }}</ng-template>
    <ng-template valError="validateBCE">{{ 'common.form.error-bce' | translate }}</ng-template>
    <ng-template valError="validateBCE_UE">{{ 'common.form.error-bce' | translate }}</ng-template>
    <ng-template valError="validateRN">{{ 'common.form.error-rn' | translate }}</ng-template>
    <ng-template valError="validateNumber">{{ 'common.form.error-number' | translate }}</ng-template>
    <ng-template valError="validateLovCode">{{ 'common.form.error-lov-code' | translate }}</ng-template>
    <ng-template valError="validateOneCapitalCharacter">
        {{ 'common.form.error-one-capital-character' | translate }}
    </ng-template>
    <ng-template valError="validateDecimalNumber">{{ 'common.form.error-decimal-number' | translate }}</ng-template>
    <ng-template valError="validateSecCode">{{ 'common.form.error-sec-code' | translate }}</ng-template>
    <ng-template valError="validateUniqueCode">{{ 'common.form.error-unique-code' | translate }}</ng-template>
    <ng-template valError="validDateDebutFin">{{ 'common.form.start-end-date-error' | translate }}</ng-template>
    <ng-template valError="validateCondition">{{ 'common.form.error-condition' | translate }}</ng-template>
</val-default-errors>
