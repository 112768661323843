import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService, ApiServiceContext } from './api.service';
import { LovDomain } from '../models';

@Injectable({ providedIn: 'root' })
export class LovDomainsService extends ApiService {
  private readonly url = this.environment.apiUrl + 'params';

  constructor(ctx: ApiServiceContext) {
    super(ctx);
  }

  getAllLovDomains(): Observable<LovDomain[]> {
    return this.http.get<LovDomain[]>(`${this.url}/lov-domains/`).pipe(catchError(this.handleError));
  }

  getDomain(domain: string): Observable<LovDomain> {
    return this.http.get<LovDomain>(`${this.url}/lov-domains/${domain}`).pipe(catchError(this.handleError));
  }
}
