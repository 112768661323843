// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MomentModule } from 'ngx-moment';
import { NgxSecurityModule } from 'ngx-security';
import { OrderModule } from 'ngx-order-pipe';
import { ValdemortModule } from 'ngx-valdemort';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { TableModule } from 'primeng/table';
import { DynamicDialogModule } from 'primeng/dynamicdialog';

// Pipes

// Directive

// Components
import * as PrimeNG from 'primeng';
import { AuthoritiesComponent } from './components/authorities/authorities.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { KeyFilterModule } from 'primeng';
import { TranslateDecimal, SafeUrlPipe, FormatageCollectionPipe, FormatageUserPipe } from './pipes';
import {
  LoadingComponent,
  PageHeaderComponent,
  FieldsListComponent,
  NewTypeFormComponent,
  ObjectEditSegmentComponent,
  ObjectEditPositionComponent,
} from './components';
import { httpLoaderFactory } from '@nsi-showcase/components';

import { ShowcaseSharedModule } from '@nsi-showcase/components';
import { SegmentAutocompleteComponent } from './components/segment-autocomplete/segment-autocomplete.component';
import { ObjectFieldsComponent } from './components/object-fields/object-fields.component';
import { ObjectFieldComponent } from './components/object-field/object-field.component';

const MODULES: any[] = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  RouterModule,
  TranslateModule.forChild({
    loader: {
      provide: TranslateLoader,
      useFactory: httpLoaderFactory,
      deps: [HttpClient],
    },
  }),

  FontAwesomeModule, // Font awesome
  FlexLayoutModule, // Angular Flex Layout

  MomentModule, // ngx-moment
  NgxSecurityModule, // ngx-security
  OrderModule, // ngx-order-pipe
  ValdemortModule, // ngx‑valdemort

  // ngx-progressbar
  NgProgressModule,
  NgProgressHttpModule,
  NgProgressRouterModule,

  PrimeNG.SharedModule,
  PrimeNG.ScrollPanelModule,
  PrimeNG.InputTextModule,
  PrimeNG.InputTextareaModule,
  PrimeNG.TooltipModule,
  PrimeNG.PanelModule,
  PrimeNG.DropdownModule,
  PrimeNG.ButtonModule,
  PrimeNG.MenuModule,
  PrimeNG.DialogModule,
  PrimeNG.ConfirmDialogModule,
  PrimeNG.BlockUIModule,
  PrimeNG.RadioButtonModule,
  PrimeNG.CalendarModule,
  PrimeNG.TabViewModule,
  PrimeNG.TreeModule,
  PrimeNG.CheckboxModule,
  PrimeNG.InputTextareaModule,
  PrimeNG.AutoCompleteModule,
  PrimeNG.FieldsetModule,
  PrimeNG.InputMaskModule,
  PrimeNG.FileUploadModule,
  PrimeNG.PickListModule,
  PrimeNG.InputSwitchModule,
  PrimeNG.CardModule,
  PrimeNG.TabMenuModule,
  PrimeNG.SidebarModule,
  PrimeNG.ChipsModule,
  PrimeNG.DropdownModule,
  PrimeNG.VirtualScrollerModule,
  TableModule,
  DynamicDialogModule,
  ShowcaseSharedModule,
];

const DECLARATIONS: any[] = [
  // Pipes
  TranslateDecimal,
  SafeUrlPipe,
  FormatageCollectionPipe,
  FormatageUserPipe,

  // Directives

  // Components
  LoadingComponent,
  PageHeaderComponent,
  AuthoritiesComponent,
  FieldsListComponent,
  NewTypeFormComponent,
  SegmentAutocompleteComponent,
  ObjectFieldsComponent,
  ObjectFieldComponent,
  ObjectEditSegmentComponent,
  ObjectEditPositionComponent,
];

@NgModule({
  imports: [MODULES, DragDropModule, KeyFilterModule],
  declarations: DECLARATIONS,
  exports: [...MODULES, ...DECLARATIONS],
})
export class SharedModule {}
