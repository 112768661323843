import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from '../../../services/ui.service';
import { LayoutBreadcrumbService } from '../../../services/layout-breadcrumb.service';
import { LOGGER } from '../../../services/logger.service';
import { Session } from '../../../app/app.session';

@Component({
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, OnDestroy {
  public errorCode: number;
  public errorDetails: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly session: Session,
    private readonly ui: UIService,
    private breadcrumbService: LayoutBreadcrumbService,
    private i18n: TranslateService
  ) {
    this.errorCode = route.snapshot.data['errorCode'] || this.session.lastErrorCode || -1;

    this.breadcrumbService.setItems([
      {
        label:
          this.errorCode === 401 ? this.i18n.instant('error.unauthorized') : this.i18n.instant('error.generic-title'),
      },
    ]);
  }

  ngOnInit(): void {
    this.treatError();
  }

  ngOnDestroy(): void {
    this.session.lastErrorCode = null;
    this.session.lastErrorDetails = null;
  }

  treatError() {
    if (this.errorCode === 404) {
      this.errorDetails = '/' + this.route.snapshot.url.join('/');
    } else if (this.errorCode === 401) {
      this.errorDetails = this.session.lastErrorDetails;
    }

    LOGGER.error(`Error ${this.errorCode} : ${this.errorDetails}`);
  }
}
