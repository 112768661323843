import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { first } from 'rxjs/operators';
import { ViewerOpener } from '../../models/viewer/viewer-opener.model';

@Injectable({ providedIn: 'root' })
export class IframeService {
  constructor() {}

  /**
   * Open a geoviewer instance in the {@code iframe}
   *
   * In this order :
   * Eventually show the loader
   * Load the geoviewer in the iframe
   * Execute the callback
   *
   * @param opener the info to open the geoviewer
   * @param iframe the containing iframe
   * @param loader the loader to show
   */
  public open(opener: ViewerOpener, iframe: HTMLIFrameElement, loader?: HTMLDivElement) {
    this.toggleLoader(loader);
    iframe.src = opener.url + '/' + opener.context;

    fromEvent(iframe, 'load')
      .pipe(first())
      .subscribe((_) => this.executeCallback(opener, iframe));
    fromEvent(iframe, 'load')
      .pipe(first())
      .subscribe((_) => this.toggleLoader(loader));
  }

  /**
   * Show the loader
   *
   * @param loader the loader div
   */
  private toggleLoader(loader?: HTMLDivElement) {
    if (loader) {
      loader.classList.toggle('hide-loader');
    }
  }

  /**
   * Execute the callback with the iFrame window as parameter
   *
   * @param opener the info to open the geoviewer
   * @param iframe the containing iframe
   */
  private executeCallback(opener: ViewerOpener, iframe: HTMLIFrameElement) {
    opener.callback(iframe.contentWindow);
  }
}
