import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { DomUtils } from '@nsi/core-utils';
import { switchMap, tap, filter } from 'rxjs/operators';
import {
  ZoomSpwGeoviewerHelper,
  ShowSpwGeoviewerHelper,
  DrawSpwGeoviewerHelper,
  IdentifySpwGeoviewerHelper,
} from './helpers';

export interface SpwHelpers {
  zoom: ZoomSpwGeoviewerHelper;
  show: ShowSpwGeoviewerHelper;
  draw: DrawSpwGeoviewerHelper;
  identify: IdentifySpwGeoviewerHelper;
}
export abstract class SpwGeoviewer {
  instance: any;

  _helpers: SpwHelpers;
  get helpers() {
    this._helpers = this._helpers
      ? this._helpers
      : {
          zoom: new ZoomSpwGeoviewerHelper(this),
          show: new ShowSpwGeoviewerHelper(this),
          draw: new DrawSpwGeoviewerHelper(this),
          identify: new IdentifySpwGeoviewerHelper(this),
        };
    return this._helpers;
  }
  require(...modules: string[]) {
    return new Observable((subscriber) => {
      const _require: any = window['require'];
      _require(modules, (...args) => {
        subscriber.next(args);
      });
    });
  }
}
@Component({
  selector: 'showcase-spw-geoviewer',
  templateUrl: './spw-geoviewer.component.html',
  styleUrls: ['./spw-geoviewer.component.scss'],
  providers: [
    // accessible reference
    {
      provide: SpwGeoviewer,
      useExisting: forwardRef(() => SpwGeoviewerComponent),
    },
  ],
})
export class SpwGeoviewerComponent extends SpwGeoviewer implements OnInit {
  _apiUrl = '//geoservices.wallonie.be/geoviewer-api/';

  @Input()
  set apiUrl(url) {
    this._apiUrl = url ? url : this.apiUrl;
  }

  get apiUrl() {
    return this._apiUrl;
  }

  @Input()
  token = 'DEFAULT';

  @Input()
  context = 'DEFAULT';

  @Input()
  params = 'colors=color1=03688d,color2=ffffff,color3=000000,color4=03688d,color5=03688d,color6=03688d';

  @Input()
  configs = {
    packages: [],
  };

  get spwMap() {
    return this.instance?.get('spwMap');
  }
  ngOnInit(): void {
    window['showcaseViewerComponent'] = this;
    const url = `${this.apiUrl}api/${this.token}/${this.context}?${this.params}`;
    console.log(`[SpwGeoviewerComponent] url ${url}`);
    this.setup();
    DomUtils.loadScript(url)
      .pipe(
        tap((e) => {
          console.log('[SPW-Geoviewer] geoviewer API loaded', e);
        }),
        switchMap((e) => this.loadViewer()),
        tap((e) => {
          console.log('[SPW-Geoviewer] geoviewer Viewer loaded', e);
        })
      )
      .subscribe();
  }

  applyStyle(element, style) {
    element.style.height = style.height;
    element.style.width = style.width;
    element.style.margin = style.margin;
    element.style.padding = style.padding;
  }

  setup() {
    window['dojoConfig'] = this.configs;
    // add claro class to body
    const body = document.getElementsByTagName('body').item(0);
    const html = document.getElementsByTagName('html').item(0);
    body.classList.add('claro');
    const style = {
      height: '100%',
      width: '100%',
      margin: 0,
      padding: 0,
    };
    this.applyStyle(body, style);
    this.applyStyle(html, style);
  }

  loadViewer() {
    const loading$ = new Subject<boolean>();
    this.require('spw/api/SpwViewer', 'dojo/ready', 'dojo/on', 'dojo/_base/lang', 'esri/config').subscribe(
      ([SpwViewer, ready, on, lang]) => {
        ready(() => {
          const viewer = SpwViewer.getInstance();
          window['spwViewer'] = viewer;
          this.instance = viewer;
          viewer.placeAt('spwViewerDiv').startup();
          const widgetManager = viewer.get('spwWidgetsManager');
          const onWidgetCreated = lang.hitch(this, (evt) => {
            window.dispatchEvent(new Event('widgetsCreated'));
            loading$.next(true);
            loading$.complete();
            window['loaded$'].next(true);
          });
          if (widgetManager.widgetsCreated) {
            onWidgetCreated();
          }
          on.once(viewer.get('spwWidgetsManager'), 'widgetsCreated', onWidgetCreated);
        });
      }
    );
    return loading$;
  }
  _loadViewer() {
    const loading$ = new Subject<boolean>();

    const _require: any = window['require'];
    _require(
      ['spw/api/SpwViewer', 'dojo/ready', 'dojo/on', 'dojo/_base/lang', 'esri/config'],
      (SpwViewer, ready, on, lang) => {
        ready(() => {
          const viewer = SpwViewer.getInstance();
          window['spwViewer'] = viewer;
          this.instance = viewer;
          viewer.placeAt('spwViewerDiv').startup();
          on.once(
            viewer.get('spwWidgetsManager'),
            'widgetsCreated',
            lang.hitch(this, (evt) => {
              window.dispatchEvent(new Event('widgetsCreated'));
              loading$.next(true);
              loading$.complete();
              window['loaded$'].next(true);
            })
          );
        });
      }
    );
    return loading$;
  }
}
