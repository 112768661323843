import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, share, switchMap, tap } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { ApiService, ApiServiceContext } from './api.service';
import { Lov } from '../models/lov.model';
import { FormService } from './form.service';

@Injectable({ providedIn: 'root' })
export class LovsService extends ApiService {
  private readonly cache: Map<string, Lov[]> = new Map();
  private readonly observables: Map<string, Observable<Lov[]>> = new Map();
  private readonly url = this.environment.apiUrl + 'params';

  constructor(ctx: ApiServiceContext, private formService: FormService) {
    super(ctx);
  }

  save(item: Lov): Observable<Lov> {
    let lov: Observable<Lov>;
    if (item.domCode) {
      lov = this.http.put<Lov>(`${this.url}/lovs`, item).pipe(catchError(this.handleError));
    } else {
      lov = this.http.post<Lov>(`${this.url}/lovs`, item).pipe(catchError(this.handleError));
    }
    if (this.cache.has(item.domain)) {
      this.cache.delete(item.domain);
    }
    return lov;
  }

  delete(domCode: string): Observable<number> {
    const encodedComCode = encodeURIComponent(domCode);
    return this.http.delete<number>(`${this.url}/lovs/${encodedComCode}`).pipe(
      tap((result: number) => {
        if (result === 0) {
          this.deleteInCache(domCode);
        }
      }),
      catchError(this.handleError)
    );
  }

  deleteInCache(domCode: string) {
    let deletedDomain: string = null;
    this.cache.forEach((lovs: Lov[]) => {
      lovs.forEach((lov: Lov) => {
        if (lov.domCode === domCode) {
          deletedDomain = lov.domain;
        }
      });
    });
    if (deletedDomain !== null && this.cache.has(deletedDomain)) {
      this.cache.delete(deletedDomain);
    }
  }

  getAllLovsByDomainCached(domain: string): Observable<Lov[]> {
    if (!this.cache.has(domain)) {
      let obs = this.observables.get(domain);
      if (!obs) {
        obs = this.http.get<Lov[]>(`${this.url}/lovs/domain/${domain}`).pipe(
          share(),
          tap((lovs) => {
            this.cache.set(domain, lovs);
          }),
          catchError(this.handleError)
        );
        this.observables.set(domain, obs);
      }
      return obs;
    } else {
      return of(this.cache.get(domain));
    }
  }

  getAllLovsByDomain(domain: string): Observable<Lov[]> {
    // No cache, for admin only
    return this.getAllLovsByDomainCached(domain);
  }

  getAllLovs(): Observable<Lov[]> {
    // No cache, for admin only
    return this.http.get<Lov[]>(`${this.url}/lovs/`).pipe(catchError(this.handleError));
  }

  getLovLabel(domCode: string, lang: string): Observable<string> {
    if (domCode && domCode.match(/#[_0-9A-Z]+#[_0-9A-Z]+#/g)) {
      const domain = domCode.split('#')[1];
      return this.getAllLovsByDomainCached(domain).pipe(
        map(() => {
          return this.getLovFromCache(lang, domain, domCode);
        })
      );
    }
    return of(null);
  }

  getLovFromCache(lang, domain, domCode) {
    const lov = this.cache.get(domain).find((l) => l.domCode === domCode);
    if (lov) {
      return lang === 'fr' ? lov.labelFr : lov.labelDe;
    }
  }

  extractLovCode(domCode: string): string {
    if (domCode && domCode.match(/#[_0-9A-Z]+#[_0-9A-Z]+#/g)) {
      return domCode.split('#')[2];
    }
    return '';
  }

  getLovByDomCode(domCode: string): Observable<Lov> {
    if (domCode && domCode.match(/#[_0-9A-Z]+#[_0-9A-Z]+#/g)) {
      const code = domCode.split('#')[0];
      const domain = domCode.split('#')[1];
      return this.getAllLovsByDomainCached(domain).pipe(map((lovs) => lovs.find((lov) => lov.code === code)));
    }
    return of(null);
  }

  getLov(domain: string, emptyLabel?: string, disabling?: boolean, order: boolean = true): Observable<SelectItem[]> {
    return this.getAllLovsByDomainCached(domain).pipe(
      switchMap((lovs) =>
        this.formService.getSelectItems(lovs, 'domCode', 'labelFr', null, order, null, disabling, emptyLabel)
      )
    );
  }

  getLovItem(domain: string, value: any): Observable<SelectItem> {
    return this.getLov(domain).pipe(map((items) => items.find((item) => item.value === value)));
  }
}
