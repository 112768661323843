import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { map, startWith } from 'rxjs/operators';

export function i18nLang(i18n: TranslateService) {
  return i18n.onLangChange.pipe(
    startWith(<LangChangeEvent>{ lang: i18n.currentLang }),

    map((event) => event.lang)
  );
}
