import { BaseDTO } from './base-dto.model';
import { FieldRefsModel } from './field-refs.model';

export enum GeometryType {
  POINT = 'POINT',
  LINE = 'LINE',
}
export interface TypeModel extends BaseDTO {
  geometryType: GeometryType;
  serviceUrl: string;
  label: string;
  code: string;
  description: string;
  icon: string;
  fieldRefs?: FieldRefsModel[];
  usedInCollection?: boolean;
}
