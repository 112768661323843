/**
 * Util class containing all LovDomain and Lovs constants
 * !!! Generated code, any manual modification will be erased !!!
 */
export class LovDomCodes {
  /**
   * Oui/Non
   */
  public static readonly OUI_NON = 'OUI_NON';
  /**
   * Oui
   */
  public static readonly OUI_NON__OUI = '#OUI_NON#OUI#';
  /**
   * Non
   */
  public static readonly OUI_NON__NON = '#OUI_NON#NON#';
  /**
   * Statut Actif
   */
  public static readonly STATUT_ACTIF = 'STATUT_ACTIF';
  /**
   * Actif
   */
  public static readonly STATUT_ACTIF__ACTIF = '#STATUT_ACTIF#ACTIF#';
  /**
   * Inactif
   */
  public static readonly STATUT_ACTIF__INACTIF = '#STATUT_ACTIF#INACTIF#';
}
