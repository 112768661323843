import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export function catchErrorOperator(): OperatorFunction<any, boolean> {
  return (obs: Observable<any>) =>
    obs.pipe(
      map(() => true),
      catchError(() => of(false))
    );
}
