import { Action } from './action.model';

export function actionChangeDisabled(actions: Action[], disabled: boolean, ...keys: string[]): void {
  if (!actions || !keys) {
    return;
  }
  keys.forEach((key) => {
    const action = actions.find((a) => a.key === key);
    if (action) {
      action.disabled = disabled;
    }
  });
}

export function actionChangeKey(actions: Action[], key: string, newKey: string): void {
  if (!actions || !key) {
    return;
  }
  const action = actions.find((a) => a.key === key);
  if (action) {
    action.key = newKey;
  }
}

export function actionRemove(actions: Action[], key: string): void {
  if (!actions || !key) {
    return;
  }
  const index = actions.findIndex((a) => a.key === key);
  if (index !== -1) {
    actions.splice(index, 1);
  }
}
