import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { Segment } from '../../../models/segment.model';
import { LocalisationService } from '../../../services/localisation.service';

@Component({
  selector: 'app-segment-autocomplete',
  templateUrl: './segment-autocomplete.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SegmentAutocompleteComponent),
      multi: true,
    },
  ],
})
export class SegmentAutocompleteComponent implements ControlValueAccessor {
  @Input() field = 'simplifiedCode';
  @Input() delay = 500;
  @Input() minLength = 2;
  @Output() onSelect = new EventEmitter<Segment>();

  value: any;
  min:number;
  max:number;
  @Input() disabled = false;
  private _onChange: (e: any) => void;
  private _onTouched: () => void;

  segments$: Observable<Segment[]>;

  constructor(private localisationsService: LocalisationService) {}

  searchSegments(event: any) {
    if (event.query !== null && event.query.trim() !== '') {
      this._onTouched();
      this.segments$ = this.localisationsService.searchSegments(event.query);
    }
  }

  writeValue(value: any): void {
    const old =  this.value;
    this.value = value;
    console.log('writeValue',value)
    if(!value){
      console.log('writeValue reset min max',value);
      this.min = null;
      this.max = null;
    }else{
      if(!old || (old.simplifiedCode!=value.simplifiedCode)){
        console.log('writeValue update min max',old,value);
        this.localisationsService.searchSegments(value.simplifiedCode).subscribe(results=>{
          if(results && results.length>0){
            this.onSelectSegment(results[0])
          }
        })
      }
    }
    

  }

  registerOnChange(fn: any) {
    this._onChange = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  onSelectSegment($event: Segment) {
    this.min = Math.min($event.cumulee_start,$event.cumulee_end);
    this.max = Math.max($event.cumulee_start,$event.cumulee_end);
    this._onChange($event);
    this.onSelect.emit($event);
  }

  onClear() {
    this._onChange(null);
    this.onSelect.emit(null);
  }
}
