import { Injector } from '@angular/core';
import { addSplashItem } from './core.initializer';
import { LOGGER } from '../services/logger.service';
import { ConfigService } from '../services/config.service';

export async function ultimaInitializer(injector: Injector): Promise<void> {
  LOGGER.info('Initializing Ultima');

  addSplashItem('Initializing Ultima');
  const config: ConfigService = injector.get(ConfigService);
  await config.load('layout', 'assets/config/layout-config.json');
}
