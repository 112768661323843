import { Injectable } from '@angular/core';
import { ObjectStatusModel } from '../models';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';
import { UiNotifI18nService } from './ui-notif.service';
import { AmieEnvironment } from '../environment';

@Injectable({ providedIn: 'root' })
export class ObjectStatusService extends AbstractService<ObjectStatusModel> {
  resourceName = 'objectStatusRest';

  constructor(protected http: HttpClient, protected notif: UiNotifI18nService, protected env: AmieEnvironment) {
    super(http, notif, env);
  }
}
