import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  NgZone,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { Calendar, LocaleSettings } from 'primeng/calendar';
import { UIService } from '../../../services/ui.service';
import { DateUtils } from '@nsi/core-utils';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarComponent),
      multi: true,
    },
  ],
})
export class CalendarComponent extends Calendar implements OnInit, ControlValueAccessor {
  // Custom config
  @Input() showIcon = true;
  @Input() showButtonBar = true;
  @Input() yearNavigator = true;
  @Input() monthNavigator = true;
  @Input() hideOnDateTimeSelect = true;
  @Input() showTime = false;
  @Input() timeOnly = false;

  @Input() defaultDate: Date;
  @Input() style: any;
  @Input() styleClass: string;
  @Input() inputStyle: any;
  @Input() inputId: string;
  @Input() name: string;
  @Input() inputStyleClass: string;
  @Input() placeholder: string;
  @Input() ariaLabelledBy: string;
  @Input() disabled: any;
  @Input() dateFormat = 'dd/mm/yy';
  @Input() multipleSeparator = ',';
  @Input() rangeSeparator = '-';
  @Input() inline = false;
  @Input() showOtherMonths = true;
  @Input() selectOtherMonths: boolean;
  @Input() icon = 'pi pi-calendar';
  @Input() appendTo: any;
  @Input() readonlyInput: boolean;
  @Input() shortYearCutoff: any = '+10';
  @Input() hourFormat = '24';
  @Input() stepHour = 1;
  @Input() stepMinute = 1;
  @Input() stepSecond = 1;
  @Input() showSeconds = false;
  @Input() required: boolean;
  @Input() showOnFocus = true;
  @Input() showWeek = false;
  @Input() dataType = 'date';
  @Input() selectionMode = 'single';
  @Input() maxDateCount: number;
  @Input() todayButtonStyleClass = 'ui-button-secondary';
  @Input() clearButtonStyleClass = 'ui-button-secondary';
  @Input() autoZIndex = true;
  @Input() baseZIndex = 0;
  @Input() panelStyleClass: string;
  @Input() panelStyle: any;
  @Input() keepInvalid = false;
  @Input() numberOfMonths = 1;
  @Input() view = 'date';
  @Input() touchUI: boolean;
  @Input() timeSeparator = ':';
  @Input() showTransitionOptions = '225ms ease-out';
  @Input() hideTransitionOptions = '195ms ease-in';
  @Input() tabindex: number;
  @Input() yearRange: string;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() locale: LocaleSettings;

  @Output() onFocus: EventEmitter<any> = new EventEmitter();
  @Output() onBlur: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onInput: EventEmitter<any> = new EventEmitter();
  @Output() onTodayClick: EventEmitter<any> = new EventEmitter();
  @Output() onClearClick: EventEmitter<any> = new EventEmitter();
  @Output() onMonthChange: EventEmitter<any> = new EventEmitter();
  @Output() onYearChange: EventEmitter<any> = new EventEmitter();
  @Output() onClickOutside: EventEmitter<any> = new EventEmitter();
  @Output() onShow: EventEmitter<any> = new EventEmitter();

  // Custom event
  @Output() outDateChange = new EventEmitter<any>();

  innerValue: Date;
  value: Date;

  constructor(private ui: UIService, el: ElementRef, renderer: Renderer2, cd: ChangeDetectorRef, zone: NgZone) {
    super(el, renderer, cd, zone);
  }

  ngOnInit() {
    super.ngOnInit();

    this.locale = DateUtils.getDateLocale(this.ui.i18n.getDefaultLang());

    if (!this.yearRange) {
      const date = new Date();
      const year = date.getFullYear();
      this.yearRange = year - 10 + ':' + (year + 10);
    }
  }

  writeValue(value: any): void {
    this.innerValue = null;
    if (value instanceof Date) {
      this.innerValue = value;
    } else if (value) {
      this.innerValue = new Date(value);
    }
  }

  updateModel(value: any) {
    this.innerValue = value;
    this.value = null;
    if (this.isValidDate(value)) {
      let m = moment(value);
      this.value = new Date(m.toISOString());
    }

    this.onModelChange(this.value);
    this.outDateChange.emit(this.value);
  }

  select(event, calendar) {
    this.onSelect.emit(event);
    calendar.inputfieldViewChild.nativeElement.focus();
  }

  blur(event) {
    this.onBlur.emit(event);
    this.onModelTouched();
  }

  isValidDate(d: any) {
    return !isNaN(d) && d instanceof Date;
  }
}
