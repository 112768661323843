import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Lov } from '../models/lov.model';
import { LovsService } from '../services/lovs.service';

const ACTION_PREFIX = '[Lov] ';

export class SaveLov {
  static readonly type = ACTION_PREFIX + 'Saved lov';

  constructor(public lov: Lov) {}
}

export class GetAllLovsByDomain {
  static readonly type = ACTION_PREFIX + 'Got all lovs by domain';

  constructor(public domain: string) {}
}

export class SelectLov {
  static readonly type = ACTION_PREFIX + 'Selected one lov';

  constructor(public id: string) {}
}

export class DeleteLov {
  static readonly type = ACTION_PREFIX + 'Deleted lov';

  constructor(public lov: Lov) {}
}

export class LovStateModel {
  public listByDomain?: Lov[];
  public selected?: Lov;
}

const defaults = {
  listByDomain: [],
  selected: null,
};

@State<LovStateModel>({ name: 'lov', defaults })
@Injectable()
export class LovState {
  constructor(private lovsService: LovsService) {}

  /* Selectors */
  @Selector()
  static listByDomain(state: LovStateModel) {
    return state.listByDomain ? state.listByDomain.map((e) => <Lov>{ ...e }) : [];
  }

  @Selector()
  static selected(state: LovStateModel) {
    return state.selected;
  }

  /* Actions */
  @Action(SaveLov)
  public save({ dispatch }: StateContext<LovStateModel>, action: SaveLov): Observable<Lov> {
    return this.lovsService
      .save(action.lov)
      .pipe(switchMap((lov) => dispatch(new GetAllLovsByDomain(action.lov.domain)).pipe(map(() => lov))));
  }

  @Action(GetAllLovsByDomain)
  public getAllByDomain({ patchState }: StateContext<LovStateModel>, action: GetAllLovsByDomain): Observable<Lov[]> {
    return this.lovsService.getAllLovsByDomain(action.domain).pipe(tap((listByDomain) => patchState({ listByDomain })));
  }

  @Action(SelectLov)
  public select({ patchState }: StateContext<LovStateModel>, action: SelectLov): Observable<Lov> {
    return this.lovsService.getLovByDomCode(action.id).pipe(tap((selected) => patchState({ selected })));
  }

  @Action(DeleteLov)
  public delete({ dispatch, getState }: StateContext<LovStateModel>, action: DeleteLov): Observable<Lov[]> {
    return this.lovsService
      .delete(action.lov.domCode)
      .pipe(
        switchMap(() => dispatch(new GetAllLovsByDomain(action.lov.domain)).pipe(map(() => getState().listByDomain)))
      );
  }
}
