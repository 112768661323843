import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngxs/store';
import { WindowService } from './window.service';
import { IframeService } from './iframe.service';
import { ViewerOpener } from '../../models/viewer/viewer-opener.model';
import { ViewerIframeComponent } from '../../shared/components/viewer/viewer-iframe/viewer-iframe.component';

/***
 * GeoviewerService class is used to instantiate the geoviewer in an external window, or in an iFrame.
 */
@Injectable({ providedIn: 'root' })
export class GeoviewerService {
  geoviewerContext = '#/viewer/spw/DEFAULT';
  constructor(
    private readonly location: Location,
    private readonly windowService: WindowService,
    private readonly iframeService: IframeService,
    private readonly store: Store
  ) {}

  // WINDOW
  /**
   * Open the geoviewer in an external window, emit the window when loaded
   *
   * @param context the corresponding backend url context
   */
  openWindow(context: string): Observable<Window> {
    const subject = new Subject<Window>();

    const opener = <ViewerOpener>{
      url: this.getBaseAppUrl() + this.geoviewerContext,
      context: context,
      callback: subject.next.bind(subject),
    };
    this.windowService.open(opener);

    return subject;
  }

  /**
   * Close the geoviewer window
   */
  closeWindow() {
    this.windowService.close();
  }

  // IFRAME
  /**
   * Open the geoviewer in an iFrame, emit the window when loaded
   *
   * @param component the iframe component, for the geoviewer to be placed in
   * @param context the corresponding backend url context
   */
  openIFrame(component: ViewerIframeComponent, token = 'DEFAULT', context = 'DEFAULT'): Observable<Window> {
    const subject = new Subject<Window>();

    const opener = <ViewerOpener>{
      url: this.getBaseAppUrl() + `#/viewer/spw/${token}`,
      context: context,
      callback: subject.next.bind(subject),
    };
    this.iframeService.open(opener, component.iframe);

    return subject;
  }

  /**
   * Get backend url for static resources ( like geoviewer page model )
   */
  getBaseAppUrl(): string {
    const baseAppUrl = this.location.prepareExternalUrl(this.location.path());
    return window.location.href.replace(baseAppUrl, '');
  }
}
