import { Inject, Injectable, NgModule } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { Environment } from '../environment';

const API_URL_TOKEN = '/api/';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  constructor(private environment: Environment) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(API_URL_TOKEN)) {
      const url =
        this.environment.apiUrl && this.environment.apiUrl !== API_URL_TOKEN
          ? this.environment.apiUrl + req.url.substr(API_URL_TOKEN.length)
          : req.url;

      req = req.clone({
        url,
        withCredentials: true,
      });
    }

    return next.handle(req);
  }
}

@NgModule({
  imports: [HttpClientModule],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true }],
})
export class InterceptorsModule {}
