import { toString } from 'lodash';
import { ObjectUtils } from './object.utils';

export class ArrayUtils {
  static hasDuplicates(array: any[], field: string): boolean {
    for (let i = 0; i < array.length; ++i) {
      let value = array[i];
      let duplicateValue = array.filter((c) => c[field] === value[field]);
      if (duplicateValue && duplicateValue.length > 1) return true;
    }
    return false;
  }

  static isEmpty(val: string | Array<any>) {
    if (ObjectUtils.isNullOrUndefined(val) || toString(val).trim() === '') return true;

    return val.length === 0;
  }

  static enumToList<T>(e: any): T[] {
    return Object.keys(e).map((k) => e[k]);
  }

  static flatten<T>(field): string {
    return (Array.isArray(field) ? field.join('.') : field) as string;
  }
}
