import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LayoutMenuService {
  items$ = new BehaviorSubject<any[]>([]);
  last$ = this.items$.pipe(map((items) => (items && items.length > 0 ? items[items.length - 1] : null)));
  menu$ = new Subject<string>();
  reset$ = new Subject();

  setItems(items: any[]) {
    setTimeout(() => this.items$.next(items));
  }

  onMenuStateChange(key: string) {
    this.menu$.next(key);
  }

  reset() {
    this.reset$.next();
  }
}
