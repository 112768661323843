import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { mergeMap, switchMap, tap, catchError } from 'rxjs/operators';
import { TokenVM } from '../models/authentication/token.model';
import { User } from '../models/user.model';
import { Role } from '../models/security/security.model';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';

const ACTION_PREFIX = '[Authentication] ';

export class LoginAction {
  static readonly type = ACTION_PREFIX + 'Login';

  constructor(public payload: { username: string; password: string }) {}
}

export class InternalLogin {
  static readonly type = ACTION_PREFIX + 'InternalLogin';
}

export class ExternalLogin {
  static readonly type = ACTION_PREFIX + 'ExternalLogin';
}

export class TouchAction {
  static readonly type = ACTION_PREFIX + 'Touch';
}

export class RefreshToken {
  static readonly type = ACTION_PREFIX + 'RefreshToken';
}

export class GetAuthoritiesAction {
  static readonly type = ACTION_PREFIX + 'Get authorities';
}

export class LogoutAction {
  static readonly type = ACTION_PREFIX + 'Logout';
}

export class AuthenticationStateModel {
  public token?: TokenVM;
  public username?: string;
  public fullname?: string;
  public roles?: string[];
  public groups?: string[];
  public user?: User;
  public activeRole?: string;
  public defaultRole?: Role;
}

const defaults = {
  token: null,
  username: null,
  fullname: null,
  roles: [],
  groups: [],
  user: null,
  activeRole: null,
  defaultRole: null,
};

@State<AuthenticationStateModel>({
  name: 'authentication',
  defaults,
})
@Injectable()
export class AuthenticationState {
  constructor(private authenticationService: AuthenticationService, private userService: UserService) {}

  @Selector()
  static token(state: AuthenticationStateModel) {
    return state.token;
  }

  @Selector()
  static authenticated(state: AuthenticationStateModel) {
    return !!state.token;
  }

  @Selector()
  static roles(state: AuthenticationStateModel) {
    return state.roles;
  }

  @Selector()
  static groups(state: AuthenticationStateModel) {
    return state.groups;
  }

  @Selector()
  static username(state: AuthenticationStateModel) {
    return state.username;
  }

  @Selector()
  static fullname(state: AuthenticationStateModel) {
    return state.fullname;
  }

  @Selector()
  static user(state: AuthenticationStateModel) {
    return state.user;
  }

  @Selector()
  static activeRole(state: AuthenticationStateModel) {
    return state.activeRole;
  }

  @Selector()
  static defaultRole(state: AuthenticationStateModel) {
    return state.defaultRole;
  }

  @Action(LoginAction)
  login(ctx: StateContext<AuthenticationStateModel>, { payload }: LoginAction) {
    return this.authenticationService.postToken(payload).pipe(
      tap((token) => ctx.patchState({ token, username: payload.username })),
      mergeMap(() => ctx.dispatch(new GetAuthoritiesAction()))
    );
  }

  @Action(InternalLogin)
  internalLogin() {
    this.authenticationService.internalLogin();
  }

  @Action(ExternalLogin)
  externalLogin() {
    this.authenticationService.externalLogin();
  }

  @Action(TouchAction)
  touch({ patchState, dispatch }: StateContext<AuthenticationStateModel>) {
    return this.authenticationService.getTouch().pipe(
      tap((token) => patchState({ token })),
      mergeMap((token) => (token ? dispatch(new GetAuthoritiesAction()) : of(null)))
    );
  }

  @Action(RefreshToken)
  refreshToken({ patchState, dispatch }: StateContext<AuthenticationStateModel>) {
    return this.authenticationService.refreshToken().pipe(
      catchError((e) => of(null)),
      tap((token) => patchState({ token })),
      mergeMap((token) => (token ? dispatch(new GetAuthoritiesAction()) : of(null)))
    );
  }

  @Action(GetAuthoritiesAction)
  getAuthorities({ patchState }: StateContext<AuthenticationStateModel>) {
    return this.authenticationService.getMe().pipe(
      tap((auth) =>
        patchState(
          auth
            ? { ...auth }
            : {
                roles: [],
                groups: [],
              }
        )
      ),
      switchMap((auth) =>
        this.userService.getByLogin(auth.username).pipe(
          catchError((e) => {
            const attributes = auth['attributes'] ? auth['attributes'] : {};
            const user = {
              login: auth.username,
              lastName: attributes.given_name,
              firstName: attributes.family_name,
              email: attributes.email,
              fullName: `${attributes.given_name} ${attributes.family_name}`,
              phone: attributes.phone_number,
              langKey: 'fr',
              imageUrl: null,
              user: <User>null,
              roles: [],
              deleteAt: null,
              deleteBy: null,
            };
            const user$ = this.userService.create(user);

            return user$;
          })
        )
      ),
      tap((user) =>
        patchState({
          fullname: user.fullName,
          user: user,
        })
      ),
      switchMap(() => this.authenticationService.getDefaultRole()),
      tap((role) => patchState({ defaultRole: role }))
    );
  }

  @Action(LogoutAction)
  logout({ setState }: StateContext<AuthenticationStateModel>) {
    return this.authenticationService.deleteToken().pipe(tap(() => setState(defaults)));
  }
}
