import { interval } from 'rxjs';
//import 'rxjs/add/observable/interval';
import { LOGGER } from '../../services/logger.service';

export class Threading {
  private static thread: Threading;
  source: any;
  subscribe: any;
  method: any;

  private constructor() {}

  static getThread() {
    if (!Threading.thread) Threading.thread = new Threading();
    return Threading.thread;
  }

  public isThreadRunning(): boolean {
    return !!this.source;
  }

  public create(itv: number, method: any): boolean {
    try {
      this.source = interval(itv);
      this.method = method;
      return true;
    } catch (exception) {
      LOGGER.debug(exception);
      return false;
    }
  }

  public start() {
    if (this.source && this.method) this.subscribe = this.source.subscribe(() => this.method());
    else LOGGER.debug('No thread created');
  }

  public stop() {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
      this.source = null;
      this.subscribe = null;
      this.method = null;
      Threading.thread = null;
    } else LOGGER.debug('No thread running');
  }
}
