import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

//TODO: fix import
//import '../../../assets/tinymce/plugins/placeholder/plugin.min.js';

import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/noneditable';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import { StringUtils } from '@nsi/core-utils';
import { ArrayUtils } from '@nsi/core-utils';

declare var tinymce: any;

export const RichTextToolbarLayout = {
  MINIMAL: ['bold italic forecolor | alignleft aligncenter alignright'],
  MEDIUM: ['bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent'],
  FULL: ['bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent'],
};

@Component({
  selector: 'app-rich-text-area',
  templateUrl: './rich-text-area.component.html',
  styleUrls: ['./rich-text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichTextAreaComponent),
      multi: true,
    },
  ],
})
export class RichTextAreaComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  private _value: string;
  private _editor: any;
  private _onChange: (e: any) => void;
  private _onTouched: () => void;

  private _isDisabled = false;

  @HostBinding('class.disabled') get isDisabled() {
    return this._isDisabled;
  }

  @Input() set value(value: string) {
    this.innerHtml = this._value = value;
  }

  @Input() active = false;
  @Input() toolbar: string[] = RichTextToolbarLayout.MEDIUM;
  @Input() configDialog: DynamicDialogConfig;
  @Input() placeholder: string;
  @Input() height: number;
  @Input() autoSave = true;

  @Output() blur = new EventEmitter<Event>();
  @Output() valueChange = new EventEmitter<string>();

  @ViewChild('tiny', { static: true }) tinySelector: ElementRef;

  readonly id = `tiny_mce_${StringUtils.uniqueId()}`;
  innerHtml: string;
  dialogWidth: string;
  dialogHeight: string;

  constructor(private elementRef: ElementRef) {}

  get isContentEdited(): boolean {
    return this.elementRef.nativeElement.getElementsByClassName('x-content-edited').length > 0;
  }

  ngOnInit() {
    this.dialogWidth = this.configDialog ? this.configDialog.width : null;
    this.dialogHeight = this.configDialog ? this.configDialog.height : null;
    this.height = this.height || 300;
  }

  ngAfterViewInit() {
    this.showPlaceholder();

    if (this.active) this._initEditor();
  }

  ngOnDestroy() {
    this._removeEditor();
  }

  writeValue(obj: any) {
    this.value = obj;

    this.showPlaceholder();

    if (ArrayUtils.isEmpty(obj)) {
      const node = this.tinySelector.nativeElement;
      // Clear the editor div
      // Bug with multiple tinymce, don't remove node since TinyMCE 5
      // if (node && node.firstChild)
      //   node.removeChild(node.firstChild);
    }

    // if (this._editor && this._value && node && node[0])
    //   this._editor.setContent(node[0], this._value);
  }

  registerOnChange(fn: any) {
    this._onChange = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this._isDisabled = isDisabled;

    if (this._isDisabled) this._removeEditor();
  }

  onClick(e: Event) {
    this._initEditor(true);
  }

  // private setTinyHeight(fullscreen: boolean = false): void {
  //   const classTiny = this.elementRef.nativeElement.getElementsByClassName('mce-tinymce');
  //   if (classTiny && classTiny.length === 1) {
  //     const elm = classTiny[0] as HTMLElement;
  //     if (fullscreen)
  //       elm.style.height = '100%';
  //     else
  //       elm.style.height = this.height;
  //   }
  // }

  private setContentEdited(edit: boolean): void {
    const classRichTextArea = this.elementRef.nativeElement.getElementsByClassName('x-rich-text-area');
    if (classRichTextArea && classRichTextArea.length === 1) {
      const elm = classRichTextArea[0] as HTMLElement;
      if (edit) elm.classList.add('x-content-edited');
      else elm.classList.remove('x-content-edited');
    }
  }

  private showPlaceholder(): void {
    const classPlaceholder = this.elementRef.nativeElement.getElementsByClassName('x-rich-text-area-placeholder');
    if (classPlaceholder && classPlaceholder.length === 1) {
      const elm = classPlaceholder[0] as HTMLElement;
      if (ArrayUtils.isEmpty(this._value) && !this.isContentEdited) elm.style.display = 'block';
      else elm.style.display = 'none';
    }
  }

  private _initEditor(focus?: boolean) {
    if (this._editor || this._isDisabled) return;

    this.setContentEdited(true);
    this.showPlaceholder();

    // Initialization is done in the ngAfterViewInit() to make sure the div element has been assigned with the generated unique Id
    // If the id is fixed, initialization may be done in ngOnInit()

    //TODO: fix import
    tinymce.init({
      selector: `#${this.id}`,
      inline: false,
      skin_url: 'assets/tinymce/skins/ui/oxide',
      content_css: 'assets/tinymce/tiny-mce.css',
      language_url: 'assets/tinymce/langs/fr_FR.js',
      language: 'fr_FR',
      placeholder: this.active ? this.placeholder : ' ',
      menubar: false,
      statusbar: false,
      browser_spellcheck: true,
      // forced_root_block : '',
      height: this.height || 300,
      plugins: [
        'placeholder',
        'save',
        'paste',
        'link',
        'image',
        'table',
        'code',
        'lists',
        'searchreplace',
        'fullscreen',
      ],
      contextmenu: '',
      // toolbar: this.toolbar,
      toolbar:
        'styleselect | undo redo | bold italic forecolor underline strikethrough subscript superscript | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image table | removeformat code fullscreen',
      // valid_elements: 'h,p[style],strong,em,span[style],a[href],ul,ol,li',
      // valid_styles: {
      //   '*': 'font-size,font-family,color,text-decoration,text-align'
      // },
      // powerpaste_word_import: 'clean',
      // powerpaste_html_import: 'clean',

      // Links
      link_context_toolbar: true,
      default_link_target: '_blank',

      // Drag&drop image & convert to base64
      paste_data_images: true,
      images_upload_handler: (blobInfo, success, failure) => {
        // No upload, just return the blobInfo.blob() as base64 data
        success('data:' + blobInfo.blob().type + ';base64,' + blobInfo.base64());
      },

      setup: (editor) => {
        this._editor = editor;
        const self = this;

        editor.on('init', () => {
          if (!focus) return;

          // self.setTinyHeight();
          editor.focus();

          // Set cursor at the end of the box
          editor.selection.select(editor.getBody(), true);
          editor.selection.collapse(false);
        });

        editor.on('blur ', (evt: Event) => {
          this.blur.emit(evt);

          this._triggerChange();
          this._removeEditor();
        });

        editor.on('remove', (evt: Event) => {
          self.setContentEdited(false);
          self.showPlaceholder();
        });

        editor.on('ExecCommand', (e) => {
          if (e.command === 'mceFullScreen' && self.configDialog) {
            if (!self.configDialog.width || self.configDialog.width === '100%') {
              // self.setTinyHeight();
              self.configDialog.width = self.dialogWidth;
              self.configDialog.height = self.dialogHeight;
            } else {
              // self.setTinyHeight(true);
              self.configDialog.width = '100%';
              self.configDialog.height = '100%';
            }
          }
        });

        if (this.autoSave) {
          editor.on('input', () => this._triggerChange());
        }
      },
    });
  }

  private _removeEditor() {
    if (!this._editor || this.active) return;

    setTimeout(() => {
      tinymce.remove(this._editor);
      this._editor = undefined;
    }, 100);
  }

  private _triggerChange() {
    if (!this._editor) return;

    const newValue = this._editor.getContent();
    if (StringUtils.stringComparer(this._value || '', newValue) === 0) return;

    this.innerHtml = this._value = newValue;

    // Triggers the events for the FormControl and ControlValueAccessor
    // Pay attention that the order of events here is really important !
    // The FormControl change its value with the "_onChange()" and trigger the value change on "_onTouched()"
    if (this._onChange) {
      this._onChange(newValue);

      if (this._onTouched) this._onTouched();
    }

    this.valueChange.emit(this._value);
  }
}
