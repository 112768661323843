import { Component } from '@angular/core';

export const USER = 'ROLE_USER';
export const ADMIN = 'ROLE_ADMIN';
export const MANAGER = 'ROLE_MANAGER';
export const COLLECTION_MANAGER = 'ROLE_COLLECTION_MANAGER';

@Component({
  selector: '',
  template: '',
  styles: [],
})
export class AuthoritiesComponent {
  public get user() {
    return USER;
  }

  public get admin() {
    return ADMIN;
  }

  public get manager() {
    return MANAGER;
  }

  public get collection_manager() {
    return COLLECTION_MANAGER;
  }
}
