import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxSecurityService } from 'ngx-security';
import { MenuItem } from 'primeng/api';
import { LayoutComponent } from './layout.component';
import { SimpleDialogComponent } from '../../shared';
import { LayoutMenuService } from '../../services/layout-menu.service';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-menu',
  template: `
    <ul *ngIf="items$ | async as items" class="ultima-menu ultima-main-menu clearfix">
      <li app-menuitem *ngFor="let item of items; let i = index" [item]="item" [index]="i" [root]="true"></li>
    </ul>
  `,
})
export class LayoutMenuComponent implements OnInit {
  items$: Observable<any[]>;

  constructor(
    public app: LayoutComponent,
    private security: NgxSecurityService,
    private router: Router,
    private menuService: LayoutMenuService
  ) {}

  showDialogIfAccess(dialog: SimpleDialogComponent, perm: string): void {
    this.security
      .hasPermission(perm)
      .pipe(untilDestroyed(this))
      .subscribe((hasPerm) => {
        if (hasPerm) dialog.show();
        else this.app.ui.showError(this.app._i18n.instant('error.401-detail'));
      });
  }

  navigateToRouteIfAccess(routerLink: any, perm: string): void {
    this.security
      .hasPermission(perm)
      .pipe(untilDestroyed(this))
      .subscribe((hasPerm) => {
        if (hasPerm) this.router.navigate(routerLink);
        else this.app.ui.showError(this.app._i18n.instant('error.401-detail'));
      });
  }

  async ngOnInit() {
    this.items$ = this.menuService.items$.pipe(
      tap((items) => {
        console.log('[LayoutMenuComponent]', items);
      })
    );
    //this.model = [{ label: 'dashboard', icon: 'dashboard', routerLink: ['/dashboard'] }];
    // // Check CONTROL_XXX
    // await this.security.hasPermission(SecPolicies.CONTROL_XXX).pipe(
    //   untilDestroyed(this),
    //   tap(hasPermission => {
    //     if (hasPermission) {
    //       this.model.push({
    //         label: 'xxx', icon: 'xxx',
    //         items: [
    //           {
    //             label: 'xxx', icon: 'add',
    //             command: () => this.showDialogIfAccess(this.app.xxxComponent.dialog, SecPolicies.CONTROL_XXX_NOUVEAU)
    //           },
    //           {
    //             label: 'xxx-search', icon: 'search',
    //             routerLink: ['/xxx']
    //           }
    //         ]
    //       });
    //     }
    //   })).toPromise();
    // // Custom NSI - Admin Menu
    // // Check CONTROL_ADMIN
    // await this.security.hasPermission(SecPolicies.CONTROL_ADMIN).pipe(
    //   untilDestroyed(this),
    //   tap(hasPermission => {
    //     if (hasPermission) {
    //       let adminItems: MenuItem[] = [
    //         {
    //           label: 'administration', icon: 'security',
    //           items: [
    //             {label: 'admin.lovs', icon: 'list', routerLink: ['/admin/lovs']},
    //             {
    //               label: 'notifications', icon: 'notifications',
    //               items: [
    //                 {label: 'notification-types', icon: 'notifications', routerLink: ['/admin/types-notification']},
    //                 {label: 'notification-modeles', icon: 'notifications', routerLink: ['/admin/modeles-notification']}
    //               ]
    //             },
    //             {
    //               label: 'xxx', icon: 'subject',
    //               items: [
    //                 {label: 'xxxaaa', icon: 'extension', routerLink: ['/admin/aaa']},
    //                 {label: 'xxxbbb', icon: 'subject', routerLink: ['/admin/bbb']}
    //               ]
    //             },
    //             {
    //               label: 'security', icon: 'security',
    //               items: [
    //                 {label: 'users-management', icon: 'people', routerLink: ['/admin/gestion-utilisateurs']},
    //                 {label: 'access-rights', icon: 'verified_user', routerLink: ['/admin/security']}
    //               ]
    //             },
    //             {label: 'admin.modeles-conditions-particulieres', icon: 'class', routerLink: ['/admin/modeles-conditions-particulieres']}
    //           ]
    //         }
    //       ];
    //
    //       this.model.push(...adminItems);
    //     }
    //   })).toPromise();
    // Custom NSI - Layout Menu
    /*
    if (this.app.layout.layoutMenu) {
      let layoutItems: MenuItem[] = [
        { label: 'Compact Size', icon: 'fiber_manual_record', command: () => (this.app.layoutCompact = true) },
        { label: 'Material Size', icon: 'fiber_smart_record', command: () => (this.app.layoutCompact = false) },
        { label: 'Static Menu', icon: 'menu', command: () => this.app.changeToStaticMenu() },
        { label: 'Overlay Menu', icon: 'exit_to_app', command: () => this.app.changeToOverlayMenu() },
        { label: 'Slim Menu', icon: 'more_vert', command: () => this.app.changeToSlimMenu() },
        { label: 'Horizontal Menu', icon: 'border_horizontal', command: () => this.app.changeToHorizontalMenu() },
        { label: 'Light Menu', icon: 'label_outline', command: () => (this.app.darkMenu = false) },
        { label: 'Dark Menu', icon: 'label', command: () => (this.app.darkMenu = true) },
      ];

      let profileItems: MenuItem[] = [
        { label: 'Inline Profile', icon: 'contacts', command: () => (this.app.profileMode = 'inline') },
        { label: 'Top Profile', icon: 'person_pin', command: () => (this.app.profileMode = 'top') },
      ];

      if (this.app.layout.profileView) {
        layoutItems.push(...profileItems);
      }

      this.model.push({
        label: 'layout',
        icon: 'palette',
        items: layoutItems,
      });
    }

    // Custom NSI - Showcase Menu
    if (this.app.config.showcase) {
      let showcaseItems: MenuItem[] = [
        {
          label: 'showcase',
          icon: 'slideshow',
          items: [
            { label: 'DEMO', icon: 'chevron_right', routerLink: ['/showcase/demo'] },
            {
              label: 'CRUD',
              icon: 'menu',
              items: [
                { label: 'Task S-Dialog', icon: 'chevron_right', routerLink: ['/showcase/crud/task'] },
                { label: 'Task D-Dialog (NGXS)', icon: 'chevron_right', routerLink: ['/showcase/crud/task-ngxs'] },
                { label: 'Task Select (NGXS)', icon: 'chevron_right', routerLink: ['/showcase/crud/task-select-ngxs'] },
              ],
            },
            {
              label: 'Template Layout',
              icon: 'menu',
              items: [
                {
                  label: 'Form with layout examples',
                  icon: 'chevron_right',
                  routerLink: ['/showcase/layout/layout-form'],
                },
                { label: 'Table', icon: 'chevron_right', routerLink: ['/showcase/layout/layout-table'] },
                {
                  label: 'Main UI',
                  icon: 'subject',
                  items: [
                    {
                      label: 'Main UI Left/Right & summary',
                      icon: 'chevron_right',
                      routerLink: ['/showcase/layout/layout-main'],
                    },
                    {
                      label: 'Main UI Tabview & summary',
                      icon: 'chevron_right',
                      routerLink: ['/showcase/layout/layout-main-tvs'],
                    },
                    {
                      label: 'Main UI Multiple contents',
                      icon: 'chevron_right',
                      routerLink: ['/showcase/layout/layout-main-mc'],
                    },
                    {
                      label: 'Main UI Left/Tabview',
                      icon: 'chevron_right',
                      routerLink: ['/showcase/layout/layout-main-ltv'],
                    },
                    {
                      label: 'Main UI Left/Right',
                      icon: 'chevron_right',
                      routerLink: ['/showcase/layout/layout-main-lr'],
                    },
                  ],
                },
                {
                  label: 'Search UI',
                  icon: 'subject',
                  items: [
                    {
                      label: 'Search Tabview',
                      icon: 'chevron_right',
                      routerLink: ['/showcase/layout/layout-search-tv'],
                    },
                    {
                      label: 'Search Left/Right',
                      icon: 'chevron_right',
                      routerLink: ['/showcase/layout/layout-search-lr'],
                    },
                    {
                      label: 'Search Left/Right & summary',
                      icon: 'chevron_right',
                      routerLink: ['/showcase/layout/layout-search-lrs'],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ];

      this.model.push(...showcaseItems);
    }

    // Custom NSI - Demo Menu
    if (this.app.layout.demoMenu) {
      let demoItems: MenuItem[] = [
        {
          label: 'Components',
          icon: 'list',
          badge: '2',
          badgeStyleClass: 'teal-badge',
          items: [
            { label: 'Sample Page', icon: 'desktop_mac', routerLink: ['/sample'] },
            { label: 'Forms', icon: 'input', routerLink: ['/forms'] },
            { label: 'Data', icon: 'grid_on', routerLink: ['/data'] },
            { label: 'Panels', icon: 'content_paste', routerLink: ['/panels'] },
            { label: 'Overlays', icon: 'content_copy', routerLink: ['/overlays'] },
            { label: 'Menus', icon: 'menu', routerLink: ['/menus'] },
            { label: 'Messages', icon: 'message', routerLink: ['/messages'] },
            { label: 'Charts', icon: 'insert_chart', routerLink: ['/charts'] },
            { label: 'File', icon: 'attach_file', routerLink: ['/file'] },
            { label: 'Misc', icon: 'toys', routerLink: ['/misc'] },
          ],
        },
        {
          label: 'Template Pages',
          icon: 'get_app',
          items: [
            { label: 'Empty Page', icon: 'hourglass_empty', routerLink: ['/empty'] },
            { label: 'Landing Page', icon: 'flight_land', url: 'assets/pages/landing.html', target: '_blank' },
            { label: 'Login Page', icon: 'verified_user', url: 'assets/pages/login.html', target: '_blank' },
            { label: 'Error Page', icon: 'error', url: 'assets/pages/error.html', target: '_blank' },
            { label: '404 Page', icon: 'error_outline', url: 'assets/pages/404.html', target: '_blank' },
            { label: 'Access Denied Page', icon: 'security', url: 'assets/pages/access.html', target: '_blank' },
          ],
        },
        {
          label: 'Menu Hierarchy',
          icon: 'menu',
          items: [
            {
              label: 'Submenu 1',
              icon: 'subject',
              items: [
                {
                  label: 'Submenu 1.1',
                  icon: 'subject',
                  items: [
                    { label: 'Submenu 1.1.1', icon: 'subject' },
                    { label: 'Submenu 1.1.2', icon: 'subject' },
                    { label: 'Submenu 1.1.3', icon: 'subject' },
                  ],
                },
                {
                  label: 'Submenu 1.2',
                  icon: 'subject',
                  items: [
                    { label: 'Submenu 1.2.1', icon: 'subject' },
                    { label: 'Submenu 1.2.2', icon: 'subject' },
                  ],
                },
              ],
            },
            {
              label: 'Submenu 2',
              icon: 'subject',
              items: [
                {
                  label: 'Submenu 2.1',
                  icon: 'subject',
                  items: [
                    { label: 'Submenu 2.1.1', icon: 'subject' },
                    { label: 'Submenu 2.1.2', icon: 'subject' },
                    { label: 'Submenu 2.1.3', icon: 'subject' },
                  ],
                },
                {
                  label: 'Submenu 2.2',
                  icon: 'subject',
                  items: [
                    { label: 'Submenu 2.2.1', icon: 'subject' },
                    { label: 'Submenu 2.2.2', icon: 'subject' },
                  ],
                },
              ],
            },
          ],
        },
      ];

      this.model.push(...demoItems);
      
    }*/
  }
}
