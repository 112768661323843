import { Injectable } from '@angular/core';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { ConfirmDialogComponent } from '../shared';
import { NotiService } from './noti.service';

@Injectable({ providedIn: 'root' })
export class UIService {
  public blocked$ = new BehaviorSubject<boolean>(false);
  public confirmDialogComponent: ConfirmDialogComponent;

  constructor(public i18n: TranslateService, public notif: NotiService, public ngProgress: NgProgress) {}

  // Block UI
  public blockUI(): void {
    this.blocked$.next(true);
  }

  public unblockUI(): void {
    this.blocked$.next(false);
  }

  // Progress Bar
  public progressStart(blockUI: boolean = false) {
    const progressRef: NgProgressRef = this.ngProgress.ref();
    progressRef.start();
    if (blockUI) {
      this.blockUI();
    }
  }

  public progressComplete() {
    const progressRef: NgProgressRef = this.ngProgress.ref();
    progressRef.complete();
    if (this.blocked$.getValue()) {
      this.unblockUI();
    }
  }

  // Dialogs
  private showConfirmMsg(
    type: string,
    msg: string,
    title?: string,
    icon?: string,
    dungeonKeeper?: boolean
  ): Promise<boolean> {
    if (this.confirmDialogComponent) {
      return this.confirmDialogComponent.showConfirmMsg(type, msg, title, icon, dungeonKeeper);
    }

    return Promise.reject('confirmDialogComponent is null');
  }

  showConfirm(msg: string, title?: string, icon?: string): Promise<boolean> {
    return this.showConfirmMsg('confirm', msg, title, icon);
  }

  showDeleteConfirm(msg: string, title?: string, icon?: string): Promise<boolean> {
    return this.showConfirmMsg('delete', msg, title, icon);
  }

  showDungeonKeeperConfirm(msg: string, title?: string, icon?: string): Promise<boolean> {
    return this.showConfirmMsg('warning', msg, title, icon, true);
  }

  showError(msg: string, title?: string, icon?: string): Promise<boolean> {
    return this.showConfirmMsg('error', msg, title, icon);
  }
}
