import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseDTO } from '../models';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UiNotifI18nService } from './ui-notif.service';
import { Page } from '@nsi-showcase/components';
import { AmieEnvironment } from '../environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export class AbstractService<T extends BaseDTO> {
  resourceName: string;

  protected http: HttpClient;
  protected notif: UiNotifI18nService;
  protected env: AmieEnvironment;

  protected constructor(http: HttpClient, notif: UiNotifI18nService, env: AmieEnvironment) {
    this.http = http;
    this.notif = notif;
    this.env = env;
  }

  get environment() {
    return this.env;
  }

  protected get path(): string {
    return this.env.apiUrl + this.resourceName;
  }

  public getHttpOptions() {
    return httpOptions;
  }

  public getAll(): Observable<T[]> {
    return this.http.get<T[]>(`${this.path}`, httpOptions).pipe(catchError(this.handleError<T[]>('getAll', [])));
  }

  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console
      this.notif.error(operation, 'common.error');
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  public getOne(id: number): Observable<T> {
    return this.http
      .get<T>(`${this.path}/${id}`, httpOptions)
      .pipe(catchError(this.handleError<T>('getOne' + this.resourceName, null)));
  }

  public queryPagePost(url: string, search: any = {}, params: HttpParams): Observable<Page<T>> {
    return this.http
      .post(url, search, { params: params, observe: 'response' })
      .pipe(map((response) => <Page<T>>response.body));
  }

  public delete(id: number): Observable<any> {
    return this.http.delete(`${this.path}/${id}`, httpOptions);
  }

  public post(type: T): Observable<any> {
    return this.http.post(`${this.path}`, type);
  }

  public put(type: T): Observable<any> {
    return this.http.put(`${this.path}`, type);
  }

  public patch(type: T): Observable<any> {
    return this.http.patch(`${this.path}`, type);
  }

  public patchSeveral(types: T[]): Observable<any> {
    return this.http.patch(`${this.path}/several`, types);
  }
}
