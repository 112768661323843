import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export class ExportUtils {
  static EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  static EXCEL_EXTENSION = '.xlsx';
  static CSV_TYPE = 'text/csv;charset=UTF-8';
  static CSV_EXTENSION = '.csv';
  static CSV_SEPARATOR = ';';
  static toExcel(json: any[], fileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsFile(excelBuffer, fileName + this.EXCEL_EXTENSION, {
      type: this.EXCEL_TYPE,
    });
  }

  static toCsv(json: any[], fileName: string) {
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(json[0]);
    let csv = json.map((row) =>
      header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(this.CSV_SEPARATOR)
    );
    csv.unshift(header.join(this.CSV_SEPARATOR));
    let csvArray = '\uFEFF' + csv.join('\r\n');

    this.saveAsFile(csvArray, fileName + this.CSV_EXTENSION, {
      type: this.CSV_TYPE,
    });
  }
  static saveAsFile(buffer: any, fileName: string, blobOptions: any): void {
    const data: Blob = new Blob([buffer], blobOptions);
    FileSaver.saveAs(data, fileName);
  }
}
