import { AfterViewInit, Component, Input } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ArrayUtils } from '@nsi/core-utils';

@UntilDestroy()
@Component({ template: '' })
export abstract class BaseFormComponent implements AfterViewInit {
  @Input() readonly: boolean;

  public form: FormGroup;
  public formInvalid: boolean;

  // public formInvalid$: Observable<boolean>;

  constructor(protected route: ActivatedRoute) {}

  // TODO - To remove
  ngAfterViewInit(): void {
    const readOnly =
      this.readonly === undefined
        ? !!this.route && this.route.snapshot && this.route.snapshot.data && this.route.snapshot.data['readOnly']
          ? this.route.snapshot.data['readOnly']
          : false
        : this.readonly;
    if (readOnly) {
      setTimeout(() => this.form.disable());
    }

    // this.form.statusChanges.pipe(
    //   // startWith(this.form.invalid),
    //   tap(() => this.formInvalid = this.form.invalid),
    //   untilDestroyed(this)
    // ).subscribe();

    // this.formInvalid$ = this.form.statusChanges.pipe(
    //   map(() => this.form.invalid)
    // );
    //
    // this.formInvalid$ = this.form.statusChanges.pipe(
    //   startWith(this.form.invalid),
    //   map(() => this.form.invalid)
    // );
  }

  protected invalidateForm() {
    (<any>Object).values(this.form.controls).forEach((control) => {
      control.markAsTouched();
      control.markAsDirty();

      if (control.controls) {
        control.controls.forEach((form) => this.invalidateControl(form));
      }
    });
  }

  protected invalidateControl(control: AbstractControl) {
    control.markAsTouched();
    control.markAsDirty();

    if (control['controls']) {
      const form = <FormGroup>control;
      Object.keys(form.controls).forEach((key) => this.invalidateControl(form.controls[key]));
    }
  }

  protected addValidators(controlNames: string | string[], validators: ValidatorFn[]) {
    if (this.form) {
      const names = Array.isArray(controlNames) ? controlNames : [controlNames];
      names.forEach((controleName) => {
        const abstractControl = this.form.get(controleName);
        if (abstractControl) {
          abstractControl.setValidators(validators);
          abstractControl.updateValueAndValidity();
        }
      });
    }
  }

  protected removeValidators(controlNames: string | string[]): void {
    if (this.form) {
      const names = Array.isArray(controlNames) ? controlNames : [controlNames];
      names.forEach((controleName) => {
        const abstractControl = this.form.get(controleName);
        if (abstractControl) {
          abstractControl.clearValidators();
          abstractControl.updateValueAndValidity();
        }
      });
    }
  }

  public cleanForm(): void {
    Object.keys(this.form.controls).forEach((key) => {
      let value = this.form.controls[key].value;
      if (!ArrayUtils.isEmpty(value) && typeof value === 'string')
        this.form.controls[key].setValue(value.toString().trim());
    });
  }
}
