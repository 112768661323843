import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CollectionModel, ObjectStatusCodeEnum } from '../models';
import { AbstractService } from './abstract.service';
import { Page } from '@nsi-showcase/components';
import { UiNotifI18nService } from './ui-notif.service';
import { CollectionSearchModel } from '../models';
import { LightCollectionModel } from '../models';
import { AmieEnvironment } from '../environment';

@Injectable({ providedIn: 'root' })
export class CollectionService extends AbstractService<CollectionModel> {
  resourceName = 'pgCollectionRest';

  constructor(protected http: HttpClient, protected notif: UiNotifI18nService, protected env: AmieEnvironment) {
    super(http, notif, env);
  }

  public closeInventory(collectionId: number, userLogin: string): Observable<any> {
    return this.http.post(`${this.path}/${collectionId}/inventory/${userLogin}`, {
      action: 'CLOSE',
    });
  }

  public post(collection: CollectionModel): Observable<any> {
    return this.http.post(`${this.path}`, collection);
  }

  public patch(collection: CollectionModel): Observable<any> {
    return this.http.patch(`${this.path}`, collection);
  }

  public getAllByTypeId(id: string): Observable<CollectionModel[]> {
    return this.http.get<CollectionModel[]>(`${this.path}/types/${id}`);
  }

  public search(eventSearch: CollectionSearchModel, params: HttpParams): Observable<Page<LightCollectionModel>> {
    return this.queryPagePost(`${this.path}/search`, eventSearch, params).pipe(catchError(this.handleError));
  }

  public searchProgressingCollectionsAssignedToCurrentUser(
    eventSearch: CollectionSearchModel,
    params: HttpParams
  ): Observable<Page<LightCollectionModel>> {
    return this.queryPagePost(`${this.path}/search/assigned`, eventSearch, params).pipe(catchError(this.handleError));
  }

  public getAssignedProgressingCollectionIfSingle(): Observable<CollectionModel> {
    return this.http.get<CollectionModel>(`${this.path}/single/assigned`).pipe(catchError(this.handleError));
  }

  public existsByObjectTypeId(objectTypeId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.path}/exists/object-type/${objectTypeId}`).pipe(catchError(this.handleError));
  }
}
