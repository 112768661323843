<div class="map-container">
    <iframe
        #iframe
        id="viewer-iframe"
        width="100%"
        height="100%"
        frameborder="0"
        marginwidth="0"
        marginheight="0"
        scrolling="no"
        data-html2canvas-ignore="true"
    ></iframe>
    <div #loader class="loader hide-loader">
        <i class="fas fa-2x fa-spin fa-spinner"></i>
    </div>
</div>
