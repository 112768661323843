import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LovDomain } from '../models/lov-domain.model';
import { LovDomainsService } from '../services/lovDomains.service';

const ACTION_PREFIX = '[LovDomain] ';

export class GetAllLovDomains {
  static readonly type = ACTION_PREFIX + 'Got all lovDomains';
}

export class GetOneLovDomain {
  static readonly type = ACTION_PREFIX + 'Got one lovDomain';

  constructor(public domain: string) {}
}

export class LovDomainStateModel {
  public list?: LovDomain[];
  public selected?: LovDomain;
}

const defaults = {
  list: [],
  selected: null,
};

@State<LovDomainStateModel>({ name: 'lovDomain', defaults })
@Injectable()
export class LovDomainState {
  constructor(private lovDomainsService: LovDomainsService) {}

  /* Selectors */
  @Selector()
  static list(state: LovDomainStateModel) {
    return state.list ? state.list.map((e) => <LovDomain>{ ...e }) : [];
  }

  @Selector()
  static selected(state: LovDomainStateModel) {
    return state.selected;
  }

  /* Actions */
  @Action(GetAllLovDomains)
  public getAll({ patchState }: StateContext<LovDomainStateModel>): Observable<LovDomain[]> {
    return this.lovDomainsService.getAllLovDomains().pipe(tap((list) => patchState({ list })));
  }

  @Action(GetOneLovDomain)
  public getOne({ patchState }: StateContext<LovDomainStateModel>, action: GetOneLovDomain): Observable<LovDomain> {
    return this.lovDomainsService.getDomain(action.domain).pipe(tap((selected) => patchState({ selected })));
  }
}
