import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CollectionModel, CollectionSearchModel, LightCollectionModel, ObjectModel, TypeModel } from '../models';
import { AbstractService } from './abstract.service';
import { UiNotifI18nService } from './ui-notif.service';
import { AmieEnvironment } from '../environment';
import { Page } from '@nsi-showcase/components';
import { TypeSearchModel } from '../models';

/* redirection des routes dans proxy-conf.conf */

@Injectable({ providedIn: 'root' })
export class TypeService extends AbstractService<TypeModel> implements Resolve<TypeModel> {
  private type: TypeModel;
  resourceName = 'pgObjectTypeRest';

  constructor(protected http: HttpClient, protected notif: UiNotifI18nService, protected env: AmieEnvironment) {
    super(http, notif, env);
  }

  public getAllNotDeleted(): Observable<TypeModel[]> {
    return this.http
      .get<TypeModel[]>(`${this.path}/NotDeleted`, this.getHttpOptions())
      .pipe(catchError(this.handleError<TypeModel[]>('getAll', [])));
  }

  public search(typeSearch: TypeSearchModel, params: HttpParams): Observable<Page<TypeModel>> {
    return this.queryPagePost(`${this.path}/search`, typeSearch, params).pipe(catchError(this.handleError));
  }

  copy(type) {
    this.type = type;
  }

  getType() {
    return this.type;
  }

  public findByCollectionId(collectionId: number) {
    return this.http.get<TypeModel[]>(`${this.path}/collection/${collectionId}`).pipe(catchError(this.handleError));
  }

  public getByCollectionIdIfSingle(collectionId: number): Observable<TypeModel> {
    return this.http
      .get<TypeModel>(`${this.path}/single/collection/${collectionId}`)
      .pipe(catchError(this.handleError));
  }

  public existsByCode(code: string): Observable<boolean> {
    return this.http
      .get<boolean>(`${this.path}/exists/code/${encodeURIComponent(code)}`)
      .pipe(catchError(this.handleError));
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<TypeModel> | Promise<TypeModel> | TypeModel {
    let id = Number(route.paramMap.get('id'));

    return this.getOne(id).pipe(
      mergeMap((object) => {
        if (object) {
          return of(object);
        } else {
          this.notif.error('error-get-types', 'common.error');
        }
      })
    );
  }
}
