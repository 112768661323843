import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule, NoopNgxsExecutionStrategy } from '@ngxs/store';
import { ToastrModule } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { init as coreInitializer } from './core.initializer';
import { InterceptorsModule } from './core.interceptors';
import { ultimaInitializer } from './ultima.initializer';
import { AppErrorHandler } from '../app/app.errors';
import { ServicesModule } from './core.services';

// Translation
export class CoreTranslationLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  public getTranslation(lang: string): any {
    return this.http.get(`assets/i18n/${lang}.json`).pipe(catchError(() => of({})));
  }
}

export function httpLoaderFactory(http: HttpClient) {
  return new CoreTranslationLoader(http);
}

// App init
const INITIALIZERS = [coreInitializer, ultimaInitializer];

export function initializersFactory(injector: Injector) {
  return async () => {
    for (const init of INITIALIZERS) {
      await init(injector);
    }
  };
}

const PROVIDERS = [
  // Init application
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: initializersFactory,
    deps: [Injector],
  },

  // AppErrorHandler
  { provide: ErrorHandler, useClass: AppErrorHandler },
];

const MODULES = [
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: httpLoaderFactory,
      deps: [HttpClient],
    },
  }),

  // NgProgress
  // NgProgressModule.forRoot(),

  // Toastr
  ToastrModule.forRoot(),

  // NGXS - States
  NgxsModule.forRoot([], {
    developmentMode: false,
    executionStrategy: NoopNgxsExecutionStrategy,
  }), //TODO !environment.production
  NgxsReduxDevtoolsPluginModule.forRoot(),

  CommonModule,
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  InterceptorsModule,
  ServicesModule,
];

@NgModule({
  providers: PROVIDERS,
  imports: MODULES,
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, library: FaIconLibrary) {
    // This imports all icons by default and might be reviewed if performance issues
    // In this case, import icons you use (ex : import {faAddressBook} from "@fortawesome/pro-solid-svg-icons";)
    // See https://github.com/FortAwesome/angular-fontawesome
    library.addIconPacks(fas);
    library.addIconPacks(far);
    library.addIconPacks(fal);
    library.addIconPacks(fab);

    if (parentModule) {
      throw new Error(`CoreModule has already been loaded. Import Core modules in the AppModule only.`);
    }
  }
}
