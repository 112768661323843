import { HttpParams } from '@angular/common/http';

export class HttpUtils {
  static toHttpParams(object: any, params?: HttpParams): HttpParams {
    if (!params) {
      params = new HttpParams();
    }
    Object.keys(object).forEach((key) => {
      if (!params.get(key)) {
        params = params.set(key.toString(), object[key].toString());
      }
    });
    return params;
  }

  static lazyEventToPageableHttpParams(event: any, params?: HttpParams): HttpParams {
    if (!params) {
      params = new HttpParams();
    }
    if (!params.get('page')) {
      params = params.set('page', (event.first / event.rows).toString());
    }
    if (!params.get('size')) {
      params = params.set('size', event.rows);
    }
    if (!params.get('sort') && event.sortField) {
      params = params.set('sort', event.sortField + (event.sortOrder === 1 ? ',asc' : ',desc'));
    }
    return params;
  }
}
