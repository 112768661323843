import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Coordinate } from 'ol/coordinate';
import { ObjectModel } from '../models/object.model';

@Injectable()
export class ObjectEditionState {
  private editedObjectPosition: BehaviorSubject<Coordinate> = new BehaviorSubject<Coordinate>(null);
  public editedObjectPosition$: Observable<Coordinate> = this.editedObjectPosition.asObservable();

  private editedObjectStep: BehaviorSubject<ObjectEditionStepEnum> = new BehaviorSubject<ObjectEditionStepEnum>(null);
  public editedObjectStep$: Observable<ObjectEditionStepEnum> = this.editedObjectStep.asObservable();

  private editedObject: BehaviorSubject<ObjectModel> = new BehaviorSubject<ObjectModel>(null);
  public editedObject$: Observable<ObjectModel> = this.editedObject.asObservable();

  constructor() {}

  public setEditedObjectPosition(position: Coordinate) {
    this.editedObjectPosition.next(position);
  }

  public setObjectEditionStep(step: ObjectEditionStepEnum) {
    this.editedObjectStep.next(step);
  }

  public setEditedObject(object: ObjectModel) {
    this.editedObject.next(object);
  }
}

export enum ObjectEditionStepEnum {
  POSITION1,
  POSITION2,
  INFO,
}
