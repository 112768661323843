import { AfterViewChecked, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appFocus]',
})
export class FocusDirective implements AfterViewChecked {
  constructor(private element: ElementRef) {}

  ngAfterViewChecked(): void {
    if (this.element && this.element.nativeElement && this.element.nativeElement.focus)
      this.element.nativeElement.focus();
  }
}
