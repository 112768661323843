import { LayoutComponent } from '../layouts';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LayoutService {
  _layout = null;
  layout$ = new BehaviorSubject<LayoutComponent>(null);
  set layout(layout) {
    this._layout = layout;
    this.layout$.next(layout);
  }
  get layout() {
    return this._layout;
  }
}
