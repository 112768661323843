import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService, ApiServiceContext } from './api.service';
import { Role, User, UserRole } from '../models';

@Injectable({ providedIn: 'root' })
export class UserService extends ApiService {
  private url = this.environment.apiUrl + 'user';

  constructor(ctx: ApiServiceContext) {
    super(ctx);
  }

  findAll(): Observable<User[]> {
    return this.http.get(`${this.url}`).pipe(catchError(this.handleError));
  }

  create(user: User): Observable<User> {
    return this.http.post(`${this.url}`, user).pipe(catchError(this.handleError));
  }

  save(user: User): Observable<User> {
    return this.http.put(`${this.url}`, user).pipe(catchError(this.handleError));
  }

  getByLogin(login: string, full: boolean = false): Observable<User> {
    return this.http.get(`${this.url}/login/${login}${full ? '/full' : ''}`).pipe(catchError(this.handleError));
  }

  getAll(): Observable<User[]> {
    return this.http.get(`${this.url}`).pipe(catchError(this.handleError));
  }

  getAllByService(service: string): Observable<UserRole[]> {
    if (!service) {
      return of([]);
    }
    return this.http.get(`${this.url}/service/${encodeURIComponent(service)}`).pipe(catchError(this.handleError));
  }
}
