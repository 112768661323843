import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShowcaseViewerComponent } from './viewer.component';

export const VIEWER_ROUTES: Routes = [
  {
    path: 'viewer/:viewer/:token/:context',
    component: ShowcaseViewerComponent,
  },
];

@NgModule({
  providers: [],
  imports: [RouterModule.forChild(VIEWER_ROUTES)],
})
export class ShowcaseViewerRoutingModule {}
