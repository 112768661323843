import { AbstractSpwGeoviewerHelper } from './geoviewer-abstract.helper';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
export const DEFAULT_ESRI_SYMBOLS = {
  POLYGON: {
    color: [0, 0, 0, 143],
    style: 'esriSFSSolid',
    outline: {
      color: [0, 0, 0, 255],
      width: 0.75,
      style: 'esriSLSSolid',
    },
  },

  POINT: {
    color: [235, 0, 0, 97],
    style: 'esriSMSCircle',
    size: 12,
    outline: {
      color: [0, 0, 0, 255],
      width: 0.75,
      style: 'esriSLSSolid',
    },
  },

  POLYLINE: {
    color: [58, 30, 210, 255],
    style: 'esriSLSSolid',
    width: 4,
  },
};

export const HIGHLIGHT_ESRI_SYMBOLS = {
  POLYGON: {
    color: [0, 0, 0, 143],
    style: 'esriSFSSolid',
    outline: {
      color: [0, 0, 0, 255],
      width: 1.5,
      style: 'esriSLSSolid',
    },
  },

  POINT: {
    color: [235, 0, 0, 97],
    style: 'esriSMSCircle',
    size: 15,
    outline: {
      color: [0, 0, 0, 255],
      width: 1.5,
      style: 'esriSLSSolid',
    },
  },

  POLYLINE: {
    color: [58, 30, 210, 255],
    style: 'esriSLSSolid',
    width: 8,
  },
};

@Injectable({ providedIn: 'root' })
export class ShowSpwGeoviewerHelper extends AbstractSpwGeoviewerHelper {
  mapServiceInfo: {
    id: 'DrawServiceId';
    name: 'Drawings';
    inTOC: false;
    identifiable: false;
    order: 1;
  };
  showPolygon(geometry, symbol = DEFAULT_ESRI_SYMBOLS.POLYGON) {
    this.viewer.helpers.draw.showPolygon(this.mapServiceInfo, geometry, symbol);
  }
  showPolyline(geometry, symbol = DEFAULT_ESRI_SYMBOLS.POLYLINE) {
    this.viewer.helpers.draw.showPolyline(this.mapServiceInfo, geometry, symbol);
  }

  showPoint(geometry, symbol = DEFAULT_ESRI_SYMBOLS.POINT): Observable<any> {
    return this.viewer.helpers.draw.showPoint(this.mapServiceInfo, geometry, symbol);
  }
  clearGeoms() {
    this.viewer.helpers.draw.clearService(this.mapServiceInfo);
  }
}
