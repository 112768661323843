import {
  Component,
  ElementRef,
  forwardRef,
  OnInit,
  ViewChild,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { map, tap, switchMap, filter } from 'rxjs/operators';
import { SelectItem } from 'primeng/public_api';
import { AsyncSubject, fromEvent, Observable, of } from 'rxjs';
import { SensRouteEnum } from '../../../../models/enums/sens-route.enum';
import { LocalisationService } from '../../../../services/localisation.service';
import { UIService } from '@nsi-showcase/components';
import { Localisation } from '../../../../models/geoservices/localisation.model';
import { LocalisationRoutiere } from '../../../../models/geoservices/localisation-routiere.model';
import { Geometry } from '../../../../models';
import { ObjectPosition } from '../../../../models/object/object-position.model';
import { Segment } from '../../../../models/segment.model';

import { convert as geoJsonToWkt } from 'terraformer-wkt-parser';
import { arcgisToGeoJSON } from '@esri/arcgis-to-geojson-utils';
@UntilDestroy()
@Component({
  selector: 'amie-object-edit-position',
  templateUrl: './object-edit-position.component.html',
  styleUrls: ['./object-edit-position.component.scss'],
})
export class ObjectEditPositionComponent implements OnInit {
  @ViewChild('formElement', { static: true })
  public formElement: ElementRef;

  _lastPosition:Segment;

  _coordiantes = null;
  @Input()
  set coordinates(coordinates: [number, number]) {
    console.log('[ObjectEditPositionComponent] coordinates', coordinates);
    if (coordinates) {
      this.fectLocalisationByCoordinates(coordinates).subscribe();
    }
  }
  get coordinates() {
    return this._coordiantes;
  }

  lastPosition: ObjectPosition;
  _position: ObjectPosition;
  @Input()
  set position(position: ObjectPosition) {
    const old = this._position;
    this._position = position;
    if (old !== position) {
      this.patchCoordinates(position as Localisation, position?.cumuleeRoute);
    }
  }

  get position() {
    return this._position;
  }

  @Output()
  positionChanged = new EventEmitter<ObjectPosition>();

  @Output()
  onError = new EventEmitter<string>();

  @ViewChild('loader', { static: true })
  private _loader: ElementRef;

  @Input()
  disabled: boolean;

  public form: FormGroup;
  public sensRouteItems: SelectItem[];

  localisation: Segment;
  result: ObjectPosition;
  _loading:boolean;
  constructor(
    public readonly localisationService: LocalisationService,
    public readonly fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    public readonly ui: UIService
  ) {
    this.form = this.fb.group({
      localisationRoutiere: [null, Validators.required],
      cumuleeRoute: [null, Validators.required],
      sensRoute: [SensRouteEnum.CROISSANT, Validators.required],
    });

    this.sensRouteItems = [
      { label: this.ui.i18n.instant(`sens.${SensRouteEnum.CROISSANT}`), value: SensRouteEnum.CROISSANT },
      { label: this.ui.i18n.instant(`sens.${SensRouteEnum.DECROISSANT}`), value: SensRouteEnum.DECROISSANT },
    ];
  }
  isNaN(val) {
    return isNaN(parseFloat(`${val}`));
  }

  get loading(){
    return this._loading;
  }
  set loading(value){
    this._loading = value;
    if(value){
      this._loader.nativeElement.classList.remove('hide-loader');
    }else{
      this._loader.nativeElement.classList.add('hide-loader');
    }
  }
  ngOnInit() {
    console.log('ngOnInit');
    this.patchCoordinates(this.position as Localisation, this.position?.cumuleeRoute);
    this.form.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((value) => {
          console.log('form.valueChanges', value);
          this.loading = true;
        }),
        filter((value) => {
          let valid = value.localisationRoutiere && value.sensRoute && !this.isNaN(value.cumuleeRoute);  
          
          if (valid) {
            return true;
          }
          this.loading = false;
          return false;
        }),
        tap((value) => {
          console.log('form.valueChanges update segment', this.lastPosition, value);
          this.loading = false;
        }),
        switchMap((l) => this.updateSegment()),
        tap((s) => {
          this._loader.nativeElement.classList.add('hide-loader');
          console.log('form.valueChanges', s, this.coordinates);
          this.emitChanges(s, s.geometry);
        })
      )
      //.subscribe();
  }

  onFormChange(){
    const complete$ = new AsyncSubject();
    this.loading = true;
    const cumuleeRoute = this.form.get('cumuleeRoute').value;
    const code = this.form.get('localisationRoutiere').value?.code;
    if (
      code &&
      !this.isNaN(cumuleeRoute)
    ) {
      this.updateSegment().subscribe(s=>{
        this.loading = false;
        this.emitChanges(s, s.geometry);
        complete$.next(true);
      complete$.complete();
      })
    } else {
      this.loading = false;
      complete$.next(true);
      complete$.complete();
    }
    return complete$;
  }
  fectLocalisationByCoordinates(coordinates: number[]) {
    this._loader.nativeElement.classList.remove('hide-loader');
    return this.localisationService.getLocalisationNearPoint(coordinates[0], coordinates[1]).pipe(
      filter((localisation) => {
        let cumulee = localisation.cumulee;
        this.patchCoordinates(localisation, cumulee);
        return true;
      }),
      switchMap((l) => this.updateSegment()),
      tap((s) => {
        this._loader.nativeElement.classList.add('hide-loader');
        console.log('fectLocalisationByCoordinates', s, coordinates);
        // disable snapping
        this.emitChanges(s, {
          geometryType: 'esriGeometryPoint',
          spatialReference: { wkid: 31370 },
          x: coordinates[0],
          y: coordinates[1],
        });
      })
    );
  }

  hasGeomChanged(){
    const cumulee = this.form.get('cumuleeRoute').value;
    const code = this.form.get('localisationRoutiere').value?.code;

    const old = this.position;
    return !old || old.cumuleeRoute != cumulee || old.codeRoute != code;
  }

  updateSegment() {
    let segment$:Observable<Segment>;
    if(this._lastPosition && !this.hasGeomChanged()){
      segment$ = of(this._lastPosition);
    }else{
      const cumulee = this.form.get('cumuleeRoute').value;
      const code = this.form.get('localisationRoutiere').value?.code;
      const sens = this.form.get('sensRoute').value;
      console.log('change localisation geometry from service');
      segment$ = this.localisationService.getSegment(code, sens, cumulee, cumulee).pipe(tap(s=>this._lastPosition = s));
    }
    return segment$;
  }

  emitChanges(localisation: Segment, shiftedGeom: any) {
    const form = this.form;
    form.patchValue(
      {
        cumuleeRoute:Math.min(localisation.cumulee_start,localisation.cumulee_end)
      },
      { emitEvent: false }
    );
    const position = {
      libelleRoute: form.get('localisationRoutiere').value.simplifiedCode,
      codeRoute: form.get('localisationRoutiere').value.code,
      sensRoute: form.get('sensRoute').value.toLocaleUpperCase(),
      cumuleeRoute: this.form.get('cumuleeRoute').value,
      wkt: !this.lastPosition || this.hasGeomChanged()?this.toWkt(shiftedGeom):this.lastPosition.wkt,
      wktOnRoad: this.toWkt(localisation.geometry),
    };
    const last = { ...this.lastPosition };
    const current = { ...position };
    last.wkt = undefined;
    current.wkt = undefined;
    if (JSON.stringify(last) !== JSON.stringify(current)) {
      this.lastPosition = position;
      this.positionChanged.emit(position);
    }
  }

  toWkt(geom: Geometry) {
    const geoJson = arcgisToGeoJSON(geom);
    const wkt = geoJsonToWkt(geoJson);
    return wkt;
  }

  patchCoordinates(loc: Localisation, cumulee = loc?.cumulee) {
    this.form.patchValue(
      {
        localisationRoutiere: loc
          ? ({
              code: loc.codeRoute,
              simplifiedCode: loc.libelleRoute,
            } as LocalisationRoutiere)
          : null,
        sensRoute: loc?loc.sensRoute.toUpperCase():SensRouteEnum.CROISSANT,
        cumuleeRoute: cumulee,
      },
      {
        emitEvent: false,
      }
    );
    this.cdr.detectChanges();
  }
}
