import { ofActionDispatched, ofActionCompleted } from '@ngxs/store';
import { merge, Observable, OperatorFunction } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ActionContext } from '@ngxs/store/src/actions-stream';

/**
 * RxJS operator for selecting out one specific action and then mapping :
 * - true - if action have just been dispatched (status as dispatched),
 * - false - if action has just been completed (status as successful, canceled, errored).
 *
 * With distinct value until changed
 *
 * @param allowedType type of specific action
 */
export function ofActionLoading(allowedType: any | any[]): OperatorFunction<ActionContext, boolean> {
  return (actions: Observable<ActionContext>) => {
    return merge(
      actions.pipe(
        ofActionDispatched(...allowedType),
        map(() => true)
      ),
      actions.pipe(
        ofActionCompleted(...allowedType),
        map(() => false)
      )
    ).pipe(distinctUntilChanged());
  };
}
