import { Component, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldFormModel, FieldModel } from '../../../models';
import { FormUtils } from '../../utils/form.utils';
import { map } from 'rxjs/operators';
import { AmieEnvironment } from '../../../environment';

@UntilDestroy()
@Component({
  selector: 'app-object-fields',
  templateUrl: 'object-fields.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ObjectFieldsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ObjectFieldsComponent),
      multi: true,
    },
  ],
})
export class ObjectFieldsComponent implements ControlValueAccessor, Validator {
  refs: FormArray = new FormArray([]);
  fieldFormModels: FieldFormModel[];

  constructor(public readonly environment: AmieEnvironment) {}

  writeValue(fieldFormModels: FieldFormModel[]): void {
    if (fieldFormModels != null) {
      this.refs.clear();
      this.fieldFormModels = fieldFormModels.sort(FormUtils.sortByFieldRefOrder());
      this.fieldFormModels
        .map((form) => FormUtils.fillFieldRefOptions(form))
        .forEach((form) => {
          this.refs.push(new FormControl(form.field?.value));
        });
    }
  }

  registerOnChange(fn: any): void {
    this.refs.valueChanges
      .pipe(untilDestroyed(this))
      .pipe(map((obj: any[]) => this.mapToFieldForms(obj)))
      .subscribe(fn);
  }

  private mapToFieldForms(obj: any[]) {
    return this.fieldFormModels.map((form, i) => {
      const newForm = { field: { ...form.field }, fieldRef: { ...form.fieldRef } } as FieldFormModel;
      newForm.field = newForm.field ?? ({} as FieldModel);
      newForm.field.value = obj[i];
      newForm.field.fieldRefId = newForm.fieldRef.id;
      return newForm;
    });
  }

  onTouched: () => void = () => {};

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.refs.disable();
    } else {
      this.refs.enable();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.refs.valid ? null : { invalid: true };
  }
}
