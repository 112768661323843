import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, ApiServiceContext } from '@nsi-showcase/components';
import { Localisation } from '../models/geoservices';
import { Segment } from '../models/geoservices';
import { SensRouteEnum } from '../models/enums/sens-route.enum';
import { catchError, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LocalisationService extends ApiService {
  constructor(ctx: ApiServiceContext) {
    super(ctx);
  }

  searchSegments(query: string): Observable<Segment[]> {
    return this.http
      .get<Segment[]>(`/api/localisation/segments/search/${encodeURIComponent(query)}`)
      .pipe(catchError(this.handleError));
  }

  getLocalisationNearPoint(x: number, y: number): Observable<Localisation> {
    return this.http.get<Localisation>(`/api/localisation/point/${x}/${y}`)
    .pipe(tap(s=>{
      s.codeRoute = s.codeRoute.replace('x','000');
    }))
    .pipe(catchError(this.handleError));
    
  }

  getSegment(code: string, sens: SensRouteEnum, start: number, end: number): Observable<Segment> {
    return this.http
      .get<Segment>(`/api/localisation/segment/${code}/${sens}/${start}/${end}`)
      .pipe(tap(s=>{
        s.cumulee_start *= 1000;
        s.cumulee_end *= 1000; 
      }))
      .pipe(catchError(this.handleError));
  }
}
