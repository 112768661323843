import { Directive, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { merge, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: 'p-table[currentRecord]',
})
export class AddAndGoToPageDirective {
  @Input() set currentRecord(record: any) {
    this._currentRecord = record;
    this.updateCurrentPage();
  }

  @Input() set value(value: any[]) {
    this.updateCurrentPage();
  }

  private _currentRecord: any;
  private _currentSize = 0;

  constructor(private table: Table) {}

  private updateCurrentPage() {
    if (this.table.value && this._currentSize !== this.table.value.length) {
      this._currentSize = this.table.value.length;
      if (this._currentRecord) {
        const observableTable: Observable<any>[] = [];
        if (this.table.hasFilter()) {
          observableTable.push(this.table.onFilter);
        }
        if (this.table.sortField || this.table.multiSortMeta) {
          observableTable.push(this.table.onSort);
        }
        if (!observableTable.length) {
          observableTable.push(of(true));
        }
        merge(...observableTable)
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            const values: any[] = this.table.hasFilter() ? this.table.filteredValue : this.table.value;
            const indexCurrentRecord = values.findIndex(
              (r) => r[this.table.dataKey] === this._currentRecord[this.table.dataKey]
            );
            if (indexCurrentRecord >= 0) {
              this.table.first = Math.floor(indexCurrentRecord / this.table.rows) * this.table.rows;
            }
          });
      }
    }
  }
}
