<div class="x-main-right">
    <div fxLayout="row" fxLayoutAlign="center center">
        <h1 class="animated fadeIn">
            <div fxLayout="row" fxLayoutAlign="start center">
                <i class="material-icons">error_outline</i>
                &nbsp;
                <span>{{ 'error.unauthorized' | translate }}</span>
            </div>
        </h1>
    </div>
</div>
