import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { sortBy } from 'lodash';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LayoutComponent } from './layout.component';
import { AuthenticationState } from '../../states';
import { AuthenticationService } from '../../services';

@UntilDestroy()
@Component({
  selector: 'app-topbar',
  templateUrl: './layout.topbar.component.html',
})
export class LayoutTopbarComponent implements OnInit {
  @Select(AuthenticationState.fullname) public fullname$: Observable<string>;
  @Select(AuthenticationState.activeRole) public activeRole$: Observable<string>;

  public manifest: any = {};
  public roles$: Observable<SelectItem[]>;
  public activeRole: string;
  public singleRole = false;

  constructor(
    public app: LayoutComponent,
    public i18n: TranslateService,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.manifest = await this.http.get('assets/app-manifest.json').toPromise();

    this.roles$ = this.authenticationService.getRoles().pipe(
      map((roles) => {
        let rolesSorted = sortBy(
          roles.map(
            (role) =>
              <SelectItem>{
                label: role.label,
                value: role.name,
              }
          ),
          ['label']
        );
        this.singleRole = rolesSorted.length === 1;
        if (!this.singleRole) {
          rolesSorted.unshift({
            value: null,
            label: this.i18n.instant('security.role-select'),
          });
        }
        return rolesSorted;
      })
    );

    this.activeRole$
      .pipe(
        tap((activeRole) => (this.activeRole = activeRole)),
        untilDestroyed(this)
      )
      .subscribe();
  }

  get isMultiLang(): boolean {
    return this.i18n.langs && this.i18n.langs.length > 1;
  }

  onLangChange(selectedLang: string) {
    this.i18n.use(selectedLang);
  }

  onChangeRole(evt: any): void {
    if (evt.value && evt.value !== this.activeRole) {
      this.authenticationService
        .switchRole(evt.value)
        .pipe(
          tap(() => this.router.navigate(['/']).then(() => window.location.reload())),
          untilDestroyed(this)
        )
        .subscribe();
    }
  }

  goToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }
}
