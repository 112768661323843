<div fxLayout="row" fxLayoutAlign="center center">
    <div class="card" fxFlex="50%" [ngSwitch]="errorCode">
        <ng-container *ngSwitchCase="404">
            <h1>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <i class="material-icons">error_outline</i>
                    &nbsp;
                    <span>{{ 'error.404-title' | translate }}</span>
                </div>
            </h1>

            <div>
                {{ 'error.404-detail' | translate }}
                <br />
                <em>{{ errorDetails }}</em>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="401">
            <h1>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <i class="material-icons">security</i>
                    &nbsp;
                    <span>{{ 'error.401-title' | translate }}</span>
                </div>
            </h1>

            <div>
                {{ 'error.401-detail' | translate }}
                <br />
                <em>{{ errorDetails }}</em>
            </div>
        </ng-container>

        <ng-container *ngSwitchDefault>
            <h1>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <i class="material-icons">error_outline</i>
                    &nbsp;
                    <span>{{ 'error.generic-title' | translate }}</span>
                </div>
            </h1>

            <div>{{ errorDetails }}</div>
        </ng-container>

        <br />
        <div fxLayout="row" fxLayoutAlign="center center">
            <a [routerLink]="['/dashboard']">
                <button
                    pButton
                    type="button"
                    label="{{ 'error.return-to-index' | translate }}"
                    icon="ui-icon-home"
                    class="ui-button-warn"
                ></button>
            </a>
        </div>
    </div>
</div>
