import { LocalisableObject, ObjectModel, TypeModel, VSearchObjectModel } from '../../models';

export class ObjectModelUtils {
  static emptyObject(type: TypeModel, collectionId: number): ObjectModel {
    return {
      id: null,
      label: null,
      remark: null,
      description: null,
      icon: type.icon,
      geometryType: type.geometryType,
      objectTypeCode: type.code,
      objectStatusCode: null,
      segment: null,
      position: null,
      collectionId: collectionId,
      objectType: type,
      fieldDates: [],
      fieldFloats: [],
      fieldInts: [],
      fieldPictures: [],
      fieldTimeStamps: [],
      fieldTxts: [],
      fieldForms: [],
    };
  }

  static objectModelToLocalisableObject(model: ObjectModel): LocalisableObject {
    return {
      id: model.id,
      segmentWkt: model.segment?.wkt,
      segmentStartWkt: model.segment?.wktStart,
      segmentEndWkt: model.segment?.wktEnd,
      pointWkt: model.position?.wkt,
      geometryType: model.geometryType,
      icon: model.objectType?.icon,
    };
  }

  static vSearchObjectModelToLocalisableObject(model: VSearchObjectModel): LocalisableObject {
    return {
      id: model.id,
      segmentWkt: model.segment,
      segmentStartWkt: model.segmentStart,
      segmentEndWkt: model.segmentEnd,
      pointWkt: model.point,
      geometryType: model.geometryType,
      icon: model.icon,
    };
  }

  static cleanBeforeSave(object: ObjectModel) {
    if (object.objectType) {
      delete object.objectType;
    }
    if (object.fieldDates && object.fieldDates.length === 0) {
      delete object.fieldDates;
    }
    if (object.fieldFloats && object.fieldFloats.length === 0) {
      delete object.fieldFloats;
    }
    if (object.fieldInts && object.fieldInts.length === 0) {
      delete object.fieldInts;
    }
    if (object.fieldPictures && object.fieldPictures.length === 0) {
      delete object.fieldPictures;
    }
    if (object.fieldTimeStamps && object.fieldTimeStamps.length === 0) {
      delete object.fieldTimeStamps;
    }
    if (object.fieldTxts && object.fieldTxts.length === 0) {
      delete object.fieldTxts;
    }
  }

  static setDateFromDb(object: ObjectModel) {
    object.fieldDates.forEach((fieldDate) => {
      if (fieldDate.value) {
        fieldDate.value = new Date(fieldDate.value);
      }
    });
    object.fieldTimeStamps.forEach((fieldTimeStamp) => {
      if (fieldTimeStamp.value) {
        fieldTimeStamp.value = new Date(fieldTimeStamp.value);
      }
    });
  }
}
