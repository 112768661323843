import {
  Component,
  ElementRef,
  forwardRef,
  OnInit,
  ViewChild,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { map, tap, switchMap, filter } from 'rxjs/operators';
import { SelectItem } from 'primeng/public_api';
import { AsyncSubject, fromEvent, Observable, of } from 'rxjs';
import { SensRouteEnum } from '../../../../models/enums/sens-route.enum';
import { LocalisationService } from '../../../../services/localisation.service';
import { UIService } from '@nsi-showcase/components';
import { Localisation } from '../../../../models/geoservices/localisation.model';
import { LocalisationRoutiere } from '../../../../models/geoservices/localisation-routiere.model';
import { Geometry, GeometryType } from 'projects/lib-amie-components/src/lib/models';
import { ObjectSegment } from 'projects/lib-amie-components/src/lib/models/object/object-segment.model';

import { convert as geoJsonToWkt } from 'terraformer-wkt-parser';
import { arcgisToGeoJSON } from '@esri/arcgis-to-geojson-utils';
import { Segment } from '../../../../models/segment.model';
@UntilDestroy()
@Component({
  selector: 'amie-object-edit-segment',
  templateUrl: './object-edit-segment.component.html',
  styleUrls: ['./object-edit-segment.component.scss'],
})
export class ObjectEditSegmentComponent implements OnInit {
  @ViewChild('formElement', { static: true })
  public formElement: ElementRef;

  _coordiantes1 = null;
  @Input()
  set coordinates1(coordinates: [number, number]) {
    console.log('[ObjectEditRoadComponent] coordinates1', coordinates);
    if (coordinates) {
      this.fetchLocalisationByCoordinates(coordinates, 'start').subscribe();
    }
  }
  get coordinates1() {
    return this._coordiantes1;
  }

  _coordiantes2 = null;
  @Input()
  set coordinates2(coordinates: [number, number]) {
    console.log('[ObjectEditRoadComponent] coordinates2', coordinates);
    if (coordinates) {
      this.fetchLocalisationByCoordinates(coordinates, 'end').subscribe();
    }
  }
  get coordinates2() {
    return this._coordiantes2;
  }
  _lastPosition:Segment;
  lastSegment: ObjectSegment;

  _segment: ObjectSegment;
  @Input()
  set segment(segment: ObjectSegment) {
    const old = this._segment;
    this._segment = segment;
    if (old !== segment || !segment) {
      this.updateSegmentLocalisation(segment);
    }
  }

  get segment() {
    return this._segment;
  }

  @Output()
  segmentChanged = new EventEmitter<ObjectSegment>();

  @Output()
  onError = new EventEmitter<string>();

  @ViewChild('loader', { static: true })
  private _loader: ElementRef;

  @Input()
  disabled: boolean;

  public form: FormGroup;
  public sensRouteItems: SelectItem[];

  _loading:boolean;

  constructor(
    public readonly localisationService: LocalisationService,
    public readonly fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    public readonly ui: UIService
  ) {
    this.form = this.fb.group({
      localisationRoutiere: [null as LocalisationRoutiere, Validators.required],
      startCumuleeRoute: [null, Validators.required],
      endCumuleeRoute: [null, Validators.required],
      sensRoute: [SensRouteEnum.CROISSANT, Validators.required],
      wktStart: [null, Validators.required],
      wktStartOnRoad: [null, Validators.required],
      wktEnd: [null, Validators.required],
      wktEndOnRoad: [null, Validators.required],
    });
    this.sensRouteItems = [
      { label: this.ui.i18n.instant(`sens.${SensRouteEnum.CROISSANT}`), value: SensRouteEnum.CROISSANT },
      { label: this.ui.i18n.instant(`sens.${SensRouteEnum.DECROISSANT}`), value: SensRouteEnum.DECROISSANT },
    ];
  }

  updateSegmentLocalisation(segment: ObjectSegment) {
    console.log('updateSegmentLocalisation', segment);
    this.form.patchValue(
      {
        localisationRoutiere: segment
          ? {
              code: segment.codeRoute,
              simplifiedCode: segment.libelleRoute,
            }
          : (null as LocalisationRoutiere),
        startCumuleeRoute: segment ? segment.startCumuleeRoute : null,
        endCumuleeRoute: segment ? segment.endCumuleeRoute : null,
        sensRoute: segment ? this.segment.sensRoute.toUpperCase() : SensRouteEnum.CROISSANT,
        wktStart: segment ? segment.wktStart : null,
        wktStartOnRoad: segment ? segment.wktStartOnRoad : null,
        wktEnd: segment ? segment.wktEnd : null,
        wktEndOnRoad: segment ? segment.wktEndOnRoad : null,
      },
      { emitEvent: false }
    );
  }

  get loading(){
    return this._loading;
  }
  set loading(value){
    this._loading = value;
    if(value){
      this._loader.nativeElement.classList.remove('hide-loader');
    }else{
      this._loader.nativeElement.classList.add('hide-loader');
    }
  }

  ngOnInit() {
    console.log('ngOnInit');
    this.updateSegmentLocalisation(this.segment);

    this.form.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((value) => {
          console.log('form.valueChanges', value);
          this.loading = true;
        }),
        filter((value) => {
          this.cdr.detectChanges();
          if (
            value.localisationRoutiere &&
            value.sensRoute &&
            !this.isNaN(value.startCumuleeRoute) &&
            !this.isNaN(value.endCumuleeRoute)
          ) {
            
            return true;
          } else {
            this.loading = false;
            return false;
          }
        }),
        tap((value) => {
          console.log('form.valueChanges update segment', value);
          this._loader.nativeElement.classList.remove('hide-loader');
        }),
        switchMap((l) => this.updateSegment()),
        tap((s) => {
          this.loading = false;
          this.emitChanges(s);
        })
      )
      //.subscribe();
  }

  onFormChange(){
    const complete$ = new AsyncSubject();
    this.loading = true;
    const start = this.form.get('startCumuleeRoute').value;
    const end = this.form.get('endCumuleeRoute').value;
    const code = this.form.get('localisationRoutiere').value?.code;
    if (
      code &&
      !this.isNaN(start) &&
      !this.isNaN(end)
    ) {
      this.updateSegment().subscribe(s=>{
        this.loading = false;
        this.emitChanges(s);
        complete$.next(true);
        complete$.complete();
      })
    } else {
      this.loading = false;
      complete$.next(true);
      complete$.complete();
    }
    return complete$;
   
  }

  isNaN(val) {
    return isNaN(parseFloat(`${val}`));
  }

  fetchLocalisationByCoordinates(coordinates: number[], cumulee = 'start') {
    this.loading = true;
    return this.localisationService.getLocalisationNearPoint(coordinates[0], coordinates[1]).pipe(
      filter((localisation) => {
        const codeRoute = this.form.get('localisationRoutiere').value?.code;
        if (codeRoute && localisation.codeRoute !== codeRoute && cumulee !== 'start') {
          this.onError.emit('object-edit-road.error.not-same-road');
          this.loading = false;
          this.cdr.detectChanges();
          return false;
        } else {
          if (codeRoute && localisation.codeRoute !== codeRoute) {
            this.form.get('endCumuleeRoute').patchValue(null, { emitEvent: false });
          }
          let start = cumulee === 'start' ? localisation.cumulee : this.form.get('startCumuleeRoute').value;
          let end = !(cumulee === 'start') ? localisation.cumulee : this.form.get('endCumuleeRoute').value;
          this.patchCoordinates(localisation, start, end);
          if (this.isNaN(start) || this.isNaN(end)) {
            this.emitChanges(null, cumulee, coordinates);
            this.loading = false;
            return false;
          } else {
            return true;
          }
        }
      }),
      switchMap((l) => this.updateSegment()),
      tap((s) => {
        this.loading = false;
        this.emitChanges(s, cumulee, coordinates);
        this.cdr.detectChanges();
      })
    );
  }

  updateSegment() {
    let segment$:Observable<Segment>;
    if(this._lastPosition && !this.hasGeomChanged()){
      segment$ = of(this._lastPosition);
    }else{
      console.log('change localisation geometry from service');
      const start = this.form.get('startCumuleeRoute').value;
      const end = this.form.get('endCumuleeRoute').value;
      const code = this.form.get('localisationRoutiere').value?.code;
      const sens = this.form.get('sensRoute').value;
      segment$ = this.localisationService.getSegment(code, sens, start, end).pipe(tap(s=>this._lastPosition = s));
    }
        
    return segment$;
  }

  hasGeomChanged(){
    const start = this.form.get('startCumuleeRoute').value;
    const end = this.form.get('endCumuleeRoute').value;
    const code = this.form.get('localisationRoutiere').value?.code;

    const old = this.segment;
    const oldStart = old?.startCumuleeRoute;
    const oldEnd = old?.endCumuleeRoute;
    return oldStart != Math.min(start,end) || oldEnd != Math.max(start,end) || old?.codeRoute != code;
  }

  emitChanges(localisation?: Segment, position?: string, coordinates?: number[]) {
    const form = this.form;
    const wkt = localisation ? this.toWkt(localisation.geometry) : undefined;
    const geometry = localisation ? localisation.geometry : undefined;
    const type = geometry ? geometry.geometryType : undefined;
    let wktStart;
    let wktStartOnRoad;
    let wktEnd;
    let wktEndOnRoad;
    if(this.hasGeomChanged()){
      if (position === 'start' && coordinates) {
        wktStart = `POINT(${coordinates[0]} ${coordinates[1]})`;
        [wktStartOnRoad, wktEndOnRoad] = localisation ? this.getWktPointsBySegment(localisation) : [];
  
        this.form.patchValue(
          {
            wktStart: wktStart,
            wktStartOnRoad: wktStartOnRoad,
            wktEndOnRoad: wktEndOnRoad,
          },
          { emitEvent: false }
        );
      } else if (position === 'end' && coordinates) {
        wktEnd = `POINT(${coordinates[0]} ${coordinates[1]})`;
        [wktStartOnRoad, wktEndOnRoad] = localisation ? this.getWktPointsBySegment(localisation) : [];
  
        this.form.patchValue(
          {
            wktEnd: wktEnd,
            wktStartOnRoad: wktStartOnRoad,
            wktEndOnRoad: wktEndOnRoad,
          },
          { emitEvent: false }
        );
      } else {
        switch (type) {
          case 'esriGeometryPolyline':
            const paths: [number, number][][] = geometry.paths;
            const start = paths[0][0];
            const end = paths[paths.length - 1][paths[paths.length - 1].length - 1];
            wktStart = `POINT(${start[0]} ${start[1]})`;
            wktEnd = `POINT(${end[0]} ${end[1]})`;
            const min = Math.min(localisation.cumulee_start,localisation.cumulee_end);
            const max = Math.max(localisation.cumulee_start,localisation.cumulee_end);
            let startCum = form.get('startCumuleeRoute').value;
            startCum = startCum<min?min:(startCum>max?max:startCum); 

            let endCum = form.get('endCumuleeRoute').value;
            endCum = endCum<min?min:(endCum>max?max:endCum);
            this.form.patchValue(
              {
                startCumuleeRoute:startCum,
                endCumuleeRoute:endCum,
                wktStart: wktStart,
                wktStartOnRoad: wktStart,
                wktEnd: wktEnd,
                wktEndOnRoad: wktEnd,
              },
              { emitEvent: false }
            );
            break;
          case 'esriGeometryLine':
        }
      }
    }
    
    let inverted = false;
    if (form.get('startCumuleeRoute').value > form.get('endCumuleeRoute').value) {
      inverted = true;
    }

    const segment = {
      libelleRoute: form.get('localisationRoutiere').value.simplifiedCode,
      codeRoute: form.get('localisationRoutiere').value.code,
      sensRoute: form.get('sensRoute').value.toLocaleUpperCase(),
      startCumuleeRoute: form.get('startCumuleeRoute').value,
      endCumuleeRoute: form.get('endCumuleeRoute').value,
      wkt: wkt,
      wktStart: form.get('wktStart').value,
      wktStartOnRoad: inverted ? form.get('wktEndOnRoad').value : form.get('wktStartOnRoad').value,
      wktEnd: form.get('wktEnd').value,
      wktEndOnRoad: inverted ? form.get('wktStartOnRoad').value : form.get('wktEndOnRoad').value,
    };

    if (JSON.stringify(this.lastSegment) !== JSON.stringify(segment)) {
      this.lastSegment = segment;
      this.segmentChanged.emit(segment);
    }
  }

  private getWktPointsBySegment(localisation: Segment): string[] {
    const lastPoint = localisation.geometry.paths[localisation.geometry.paths.length - 1];
    const wktStartOnRoad = `POINT(${localisation.geometry.paths[0][0][0]} ${localisation.geometry.paths[0][0][1]})`;
    const wktEndOnRoad = `POINT(${lastPoint[lastPoint.length - 1][0]} ${lastPoint[lastPoint.length - 1][1]})`;
    return [wktStartOnRoad, wktEndOnRoad];
  }

  toWkt(geom: Geometry) {
    const geoJson = arcgisToGeoJSON(geom);
    const wkt = geoJsonToWkt(geoJson);
    return wkt;
  }
  patchCoordinates(loc: Localisation, start: number, end: number) {
    this.form.patchValue(
      {
        localisationRoutiere: {
          code: loc.codeRoute,
          simplifiedCode: loc.libelleRoute,
        } as LocalisationRoutiere,
        sensRoute: loc?loc.sensRoute.toUpperCase():SensRouteEnum.CROISSANT,
        startCumuleeRoute: start,
        endCumuleeRoute: end,
      },
      {
        emitEvent: false,
      }
    );
    this.cdr.detectChanges();
  }
}
