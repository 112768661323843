<div class="p-grid p-justify-center p-align-center vertical-container my-12">
    <div class="card-container mx-12 p-col-4">
        <div class="card p-0">
            <div class="x-center">
                <h2 class="m-0 p-16">{{ 'login.internal.title' | translate }}</h2>
            </div>
            <div class="x-center">
                <img class="img-spw" src="assets/images/wallonie-loader.png" />
            </div>
            <div class="p-16">
                <div class="my-8">
                    <i class="fas fa-sign-in icon"></i>
                    <a class="ml-4" href="#" (click)="signInAsInternal()">{{ 'login.internal.sign' | translate }}</a>
                </div>
            </div>
        </div>
    </div>

    <div class="card-container mx-12 p-col-4">
        <div class="card p-0">
            <div class="x-center">
                <h2 class="m-0 p-16">{{ 'login.external.title' | translate }}</h2>
            </div>
            <div>
                <img width="100%" src="assets/images/dashboard/dashboard.jpg" />
            </div>
            <div class="p-16">
                <div class="my-8">
                    <i class="fas fa-sign-in icon"></i>
                    <a class="ml-4" href="#" (click)="signInAsExternal()">{{ 'login.external.sign' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
</div>
