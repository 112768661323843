/**
 * Util enum containing all roles constants
 * !!! Generated code, any manual modification will be erased !!!
 */
export enum SecRoles {
  // Administrateur
  ROLE_ADMIN = 'ROLE_ADMIN',

  // Gestionnaire de campagne
  ROLE_COLLECTION_MANAGER = 'ROLE_COLLECTION_MANAGER',

  // Chargé de campagne
  ROLE_COLLECTION_SUPERVISOR = 'ROLE_COLLECTION_SUPERVISOR',
}
