import { Injectable } from '@angular/core';
import { NgxSecurityService } from 'ngx-security';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiService, ApiServiceContext } from './api.service';
import { Role, Permission, Policy } from '../models/security/security.model';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class SecurityService extends ApiService {
  private readonly permissionCache: Map<string, boolean> = new Map();
  private readonly url = this.environment.apiUrl;

  constructor(ctx: ApiServiceContext, private security: NgxSecurityService) {
    super(ctx);
  }

  // Permission check with cache
  public hasPermission(perm: string): Observable<boolean> {
    if (!this.permissionCache.has(perm)) {
      return this.http.get<boolean>(`${this.url}permission/has/${perm}`).pipe(
        tap((result) => this.permissionCache.set(perm, result)),
        catchError(this.handleError)
      );
    } else return of(this.permissionCache.get(perm));
  }

  // Reset security and cache
  public reset(): void {
    this.security.reset();
    this.permissionCache.clear();
  }

  // Role
  public getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(`${this.url}auth/roles`).pipe(catchError(this.handleError));
  }

  public getRole(name: string): Observable<Role> {
    return this.http.get<Role>(`${this.url}auth/roles/${name}`).pipe(catchError(this.handleError));
  }

  findAddableRoles(userLogin: string): Observable<Role[]> {
    return this.http.get(`${this.url}auth/roles/user/${userLogin}/new`).pipe(catchError(this.handleError));
  }

  public saveRole(role: Role): Observable<Role> {
    if (role.id) {
      return this.http.put<Role>(`${this.url}security/roles`, role).pipe(catchError(this.handleError));
    } else {
      return this.http.post<Role>(`${this.url}security/roles`, role).pipe(catchError(this.handleError));
    }
  }

  public deleteRole(roleId: number): Observable<any> {
    return this.http.delete(`${this.url}security/roles/${roleId}`).pipe(catchError(this.handleError));
  }

  // Permission
  public getPermissionsByTypeAndRole(permissionType: string, roleName: string): Observable<Permission[]> {
    return this.http
      .get<Policy[]>(`${this.url}security/permissions/${permissionType}/${roleName}`)
      .pipe(catchError(this.handleError));
  }

  public patchPermission(permissionType: string, roleName: string, permission: Permission): Observable<void> {
    return this.http
      .patch<void>(`${this.url}security/permission/${permissionType}/${roleName}`, permission)
      .pipe(catchError(this.handleError));
  }

  // Users
  public getUsersByRole(roleId: number): Observable<User[]> {
    return this.http.get<User[]>(`${this.url}security/roles/${roleId}/users`).pipe(catchError(this.handleError));
  }
}
