<div class="topbar clearfix">
  <div class="topbar-left-overlay" (click)="goToDashboard()"></div>
  <div class="topbar-left" fxLayout="row" fxLayoutAlign="start">
    <div class="logo"></div>
    <span class="title">{{ 'app.title' | translate }}</span>
    <div class="version">V {{ manifest.version }} ({{ manifest.buildDate | date: 'dd/MM/yyyy HH:mm' }})</div>
  </div>

  <div class="topbar-right">
    <a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)">
      <i></i>
    </a>

    <a id="rightpanel-menu-button" href="#" (click)="app.onRightPanelButtonClick($event)" *ngIf="app.layout.rightPanel">
      <i class="material-icons">help_outline</i>
    </a>

    <a id="topbar-menu-button" href="#" (click)="app.onTopbarMenuButtonClick($event)">
      <i class="material-icons">menu</i>
    </a>

    <ul class="topbar-items animated fadeInDown" [ngClass]="{ 'topbar-items-visible': app.topbarMenuActive }">
      <!-- Language - Custom NSI -->
      <li #language [ngClass]="{ 'active-top-menu': app.activeTopbarItem === language }" *ngIf="isMultiLang">
        <a href="#" (click)="app.onTopbarItemClick($event, language)">
          <i class="topbar-icon material-icons animated swing">flag</i>
          <span class="topbar-badge animated rubberBand">{{ i18n.currentLang | uppercase }}</span>
          <span class="topbar-item-name">Languages</span>
        </a>
        <ul class="topbar-language ultima-menu animated fadeInDown">
          <li *ngFor="let lang of i18n.langs" role="menuitem">
            <a href="#" class="topbar-message" (click)="$event.preventDefault(); onLangChange(lang)">
              <img src="assets/images/i18n/{{ lang }}.png" width="25" />
              <span>{{ lang | uppercase }}</span>
            </a>
          </li>
        </ul>
      </li>

      <li #profile class="profile-item" *ngIf="app.profileMode === 'top' || app.isHorizontal()"
        [hidden]="!app.layout.profileView" [ngClass]="{ 'active-top-menu': app.activeTopbarItem === profile }">
        <!--<a href="#" (click)="app.onTopbarItemClick($event,profile)">-->
        <!--<img class="profile-image" src="assets/template/images/{{app.layout.avatar}}"/>-->
        <!--<span class="topbar-item-name">DGO3 Utilisateur</span>-->
        <!--</a>-->

        <a href="#" (click)="app.onTopbarItemClick($event, profile)">
          <i class="topbar-icon material-icons">person</i>
          <span class="topbar-item-name">{{ (fullname$ | async) || 'User' }}</span>
        </a>

        <!-- Roles Dropdown -->
        <p-dropdown [options]="roles$ | async" [ngModel]="activeRole" (onChange)="onChangeRole($event)"
          [styleClass]="'roles'" [filter]="true" [emptyFilterMessage]="'common.no-result' | translate"
          *ngIf="app.config.security && app.config.securityRole && !singleRole"></p-dropdown>

        <ul class="ultima-menu animated fadeInDown">
          <!--<li role="menuitem">-->
          <!--<a href="#" (click)="app.onTopbarSubItemClick($event)">-->
          <!--<i class="material-icons">person</i>-->
          <!--<span>Profile</span>-->
          <!--</a>-->
          <!--</li>-->
          <!--<li role="menuitem">-->
          <!--<a href="#" (click)="app.onTopbarSubItemClick($event)">-->
          <!--<i class="material-icons">security</i>-->
          <!--<span>Privacy</span>-->
          <!--</a>-->
          <!--</li>-->
          <!--<li role="menuitem">-->
          <!--<a href="#" (click)="app.onTopbarSubItemClick($event)">-->
          <!--<i class="material-icons">settings_applications</i>-->
          <!--<span>Settings</span>-->
          <!--</a>-->
          <!--</li>-->

          <li role="menuitem" *ngIf="app.config.securityLogout">
            <a href="#" (click)="app.onTopbarSubItemClick($event, 'logout')">
              <i class="material-icons">power_settings_new</i>
              <span>{{ 'login.logout' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="app.config.securityLogout">
        <a class="logout-inline" href="#" (click)="app.onTopbarSubItemClick($event, 'logout')">
          <i class="material-icons">power_settings_new</i>
        </a>
      </li>
      <li>
        <span class="connected-user">{{ (fullname$ | async) || 'User' }}</span>
      </li>

      <li #settings [ngClass]="{ 'active-top-menu': app.activeTopbarItem === settings }"
        *ngIf="app.layout.settingsView">
        <a href="#" (click)="app.onTopbarItemClick($event, settings)">
          <i class="topbar-icon material-icons">settings</i>
          <span class="topbar-item-name">Settings</span>
        </a>
        <ul class="ultima-menu animated fadeInDown">
          <li role="menuitem">
            <a href="#" (click)="app.onTopbarSubItemClick($event)">
              <i class="material-icons">palette</i>
              <span>Change Theme</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#" (click)="app.onTopbarSubItemClick($event)">
              <i class="material-icons">favorite_border</i>
              <span>Favorites</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#" (click)="app.onTopbarSubItemClick($event)">
              <i class="material-icons">lock</i>
              <span>Lock Screen</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#" (click)="app.onTopbarSubItemClick($event)">
              <i class="material-icons">wallpaper</i>
              <span>Wallpaper</span>
            </a>
          </li>
        </ul>
      </li>
      <li #messages [ngClass]="{ 'active-top-menu': app.activeTopbarItem === messages }" *ngIf="app.layout.msgView">
        <a href="#" (click)="app.onTopbarItemClick($event, messages)">
          <i class="topbar-icon material-icons animated swing">message</i>
          <span class="topbar-badge animated rubberBand">5</span>
          <span class="topbar-item-name">Messages</span>
        </a>
        <ul class="ultima-menu animated fadeInDown">
          <li role="menuitem">
            <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
              <img src="assets/template/images/avatar1.png" width="35" />
              <span>Give me a call</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
              <img src="assets/template/images/avatar2.png" width="35" />
              <span>Sales reports attached</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
              <img src="assets/template/images/avatar3.png" width="35" />
              <span>About your invoice</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
              <img src="assets/template/images/avatar2.png" width="35" />
              <span>Meeting today at 10pm</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
              <img src="assets/template/images/avatar4.png" width="35" />
              <span>Out of office</span>
            </a>
          </li>
        </ul>
      </li>
      <li #notifications [ngClass]="{ 'active-top-menu': app.activeTopbarItem === notifications }"
        *ngIf="app.layout.notiView">
        <a href="#" (click)="app.onTopbarItemClick($event, notifications)">
          <i class="topbar-icon material-icons">timer</i>
          <span class="topbar-badge animated rubberBand">4</span>
          <span class="topbar-item-name">Notifications</span>
        </a>
        <ul class="ultima-menu animated fadeInDown">
          <li role="menuitem">
            <a href="#" (click)="app.onTopbarSubItemClick($event)">
              <i class="material-icons">bug_report</i>
              <span>Pending tasks</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#" (click)="app.onTopbarSubItemClick($event)">
              <i class="material-icons">event</i>
              <span>Meeting today at 3pm</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#" (click)="app.onTopbarSubItemClick($event)">
              <i class="material-icons">file_download</i>
              <span>Download documents</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#" (click)="app.onTopbarSubItemClick($event)">
              <i class="material-icons">flight</i>
              <span>Book flight</span>
            </a>
          </li>
        </ul>
      </li>

      <li #search class="search-item" [ngClass]="{ 'active-top-menu': app.activeTopbarItem === search }"
        (click)="app.onTopbarItemClick($event, search)" *ngIf="app.layout.globalSearch">
        <span class="md-inputfield">
          <input type="text" pInputText />
          <label>Search</label>
          <i class="topbar-icon material-icons">search</i>
        </span>
      </li>
    </ul>

    <div class="stitle">{{ 'app.sub-title' | translate }}</div>
  </div>
</div>
