import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Coordinate } from 'ol/coordinate';
import { Geolocation, Map, View } from 'ol';
import { fromLonLat, get } from 'ol/proj';
import { defaults } from 'ol/interaction';
import { Tile } from 'ol/layer';
import { OSM } from 'ol/source';
import { register } from 'ol/proj/proj4';
import proj4 from 'proj4';

@Injectable({
  providedIn: 'root',
})
export class MapState {
  public static readonly projection = 'EPSG:31370';

  private currentPosition: BehaviorSubject<Coordinate> = new BehaviorSubject<Coordinate>(null);
  public currentPosition$: Observable<Coordinate> = this.currentPosition.asObservable();

  constructor() {
    proj4.defs(
      'EPSG:31370',
      '+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.869,52.2978,-103.724,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs'
    );
    register(proj4);
    this.activatePositionTracking();
  }

  public getMap() {
    return new Map({
      target: 'map',
      interactions: defaults(),
      layers: [
        new Tile({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat([4.4, 50.5]),
        zoom: 7,
      }),
    });
  }

  private activatePositionTracking() {
    const geolocation = new Geolocation({
      tracking: true,
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: get(MapState.projection),
    });

    geolocation.on('change:position', (pos) => {
      if (pos) {
        this.currentPosition.next(geolocation.getPosition());
      }
    });
  }
}
