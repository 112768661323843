<div fxLayout="row" [fxLayoutAlign]="otherLeft ? 'space-between end' : 'end'" class="mt-12">
    <button
        pButton
        type="button"
        [label]="otherLabel || ('common.other' | translate)"
        icon="{{ otherIcon ? 'ui-icon-' + otherIcon : '' }}"
        [hidden]="otherHidden"
        [disabled]="otherDisabled"
        class="{{ otherClass || 'ui-button-secondary' }} {{ !isSave || 'mr-12' }}"
        (click)="onOther($event)"
        *ngIf="isOther"
    ></button>
    <div *ngIf="otherHidden"></div>

    <div>
        <ng-content></ng-content>

        <button
            pButton
            type="button"
            [label]="cancelLabel || ('common.cancel' | translate)"
            icon="ui-icon-{{ cancelIcon || 'cancel' }}"
            [hidden]="cancelHidden"
            [disabled]="cancelDisabled"
            class="{{ cancelClass || 'ui-button-secondary' }} {{ !isSave || 'mr-12' }}"
            (click)="onCancel($event)"
            *ngIf="isCancel"
        ></button>

        <button
            pButton
            type="button"
            [label]="saveLabel || ('common.validate' | translate)"
            icon="ui-icon-{{ saveIcon || 'check' }}"
            [hidden]="saveHidden"
            [disabled]="saveDisabled"
            class="{{ saveClass || '' }}"
            (click)="onSave($event)"
            *ngIf="isSave"
        ></button>
    </div>
</div>
