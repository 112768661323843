import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { AbstractService } from './abstract.service';
import { ObjectModel, Segment, TypeModel, TypeSearchModel } from '../models';
import { ObjectModelUtils } from '../shared';
import { UiNotifI18nService } from './ui-notif.service';
import { AmieEnvironment } from '../environment';
import { Page } from '@nsi-showcase/components';

@Injectable({ providedIn: 'root' })
export class ObjectService extends AbstractService<ObjectModel> implements Resolve<ObjectModel> {
  resourceName = 'pgObjectRest';

  constructor(protected http: HttpClient, protected notif: UiNotifI18nService, protected env: AmieEnvironment) {
    super(http, notif, env);
  }

  public getAll(): Observable<ObjectModel[]> {
    return super.getAll().pipe(
      tap((obects) => {
        obects.forEach((o) => {
          ObjectModelUtils.setDateFromDb(o);
        });
      })
    );
  }

  public findByCollectionIdAndObjectTypeId(collectionId: number, typeId: number): Observable<ObjectModel[]> {
    return this.http.get<ObjectModel[]>(`${this.path}/collection/${collectionId}/type/${typeId}`);
  }

  // public search(typeSearch: ObjectSearchModel, params: HttpParams): Observable<Page<ObjectModel>> {
  //   return this.queryPagePost(`${this.path}/search`, typeSearch, params).pipe(catchError(this.handleError));
  // }

  public getOne(id: number): Observable<ObjectModel> {
    return super.getOne(id).pipe(
      tap((o) => {
        ObjectModelUtils.setDateFromDb(o);
      })
    );
  }

  public getFirstByCollectionId(collectionId: number): Observable<ObjectModel> {
    return this.http.get<ObjectModel>(`${this.path}/collection/${collectionId}/first`);
  }

  public changeStatus(ids: number[], status: string): Observable<ObjectModel[]> {
    return this.http.patch<ObjectModel[]>(`${this.path}/update/status/${status}`, ids);
  }

  public changeStatusOfAllExcept(ids: number[], status: string): Observable<ObjectModel[]> {
    return this.http.patch<ObjectModel[]>(`${this.path}/update/except/status/${status}`, ids);
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ObjectModel> | Promise<ObjectModel> | ObjectModel {
    let id = Number(route.paramMap.get('id'));

    return this.getOne(id).pipe(
      mergeMap((object) => {
        if (object) {
          return of(object);
        } else {
          this.notif.error('error-get-object', 'common.error');
        }
      })
    );
  }
}
