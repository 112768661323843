import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  AuthenticationState,
  LayoutMenuService,
  SecurityService,
  AuthenticationService,
} from '@nsi-showcase/components';
import { SecPermissions } from '@spw-amie/components';
import { environment } from '../environments/environment';
import { Store } from '@ngxs/store';
import { filter, flatMap, take, tap, map, startWith } from 'rxjs/operators';
import { MenuItem } from 'primeng/public_api';
import { combineLatest, Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-root',
  template: `
    <nsi-root></nsi-root>
  `,
})
export class AppComponent {
  public authenticated$: Observable<boolean>;
  constructor(
    public menuService: LayoutMenuService,
    public store: Store,
    private security: SecurityService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    let model = [];

    this.store
      .select(AuthenticationState.authenticated)
      .pipe(
        filter((authenticated) => authenticated),
        flatMap((_) => this.addTypeMenuItem(model)),
        flatMap((_) => this.addCollectionMenuItem(model)),
        flatMap((_) => this.addUserMenuItem(model)),
        tap((_) => this.menuService.setItems(model))
      )
      .subscribe();

    this.authenticated$ = combineLatest([
      // observe both navigation events and state, project the last one received
      this.router.events,
      this.store.select(AuthenticationState.authenticated),
    ]).pipe(
      // whenever events or state arrive, we ignore as long as navigation isn't ended
      filter(([event, authenticated]) => event && event instanceof NavigationEnd),
      // we only return state
      map(([event, authenticated]) => authenticated),
      // ensure that initial state is returned when application is initializing (required for touch action)
      startWith(this.store.selectSnapshot(AuthenticationState.authenticated))
    );

    this.authenticated$
      .pipe(
        filter((a) => !a),
        tap(() => this.authenticationService.internalLogin())
      )
      .subscribe();
  }

  private static executeIfTrue(check: boolean, func: () => void) {
    if (check) {
      func();
    }
  }

  private addTypeMenuItem(model: MenuItem[]) {
    return this.security.hasPermission(SecPermissions.FORM_SEARCH).pipe(
      take(1),
      tap((authorized) =>
        AppComponent.executeIfTrue(authorized, () =>
          model.push({
            label: 'collection.types',
            icon: 'list',
            routerLink: ['/type'],
          })
        )
      )
    );
  }

  private addCollectionMenuItem(model: MenuItem[]) {
    return this.security.hasPermission(SecPermissions.COLLECTION_SEARCH).pipe(
      take(1),
      tap((authorized) =>
        AppComponent.executeIfTrue(authorized, () =>
          model.push({
            label: 'collection.collections',
            icon: 'shopping',
            routerLink: ['/collection'],
          })
        )
      )
    );
  }

  private addUserMenuItem(model: MenuItem[]) {
    return this.security.hasPermission(SecPermissions.ADMIN_USER).pipe(
      take(1),
      tap((authorized) =>
        AppComponent.executeIfTrue(authorized, () =>
          model.push({
            label: 'collection.users',
            icon: 'contacts',
            routerLink: ['/user-administration'],
          })
        )
      )
    );
  }
}
