import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NgxSecurityGuardOptions, NgxSecurityService } from 'ngx-security';
import { merge, Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { ErrorAccessElementComponent } from '../../../shared';

// Example : this is a Guard specific for TWICE to manage read/write elements => work with ErrorAccessElementComponent
@Injectable({ providedIn: 'root' })
export class SecurityAnyPermissionsChildGuard implements CanActivateChild {
  constructor(private router: Router, private security: NgxSecurityService) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const guardOptions = !!route && route.data ? (route.data['security'] as NgxSecurityGuardOptions) : {};

    let permissions$: Observable<boolean>[] = [];
    let readPermission: string;
    if (Array.isArray(guardOptions.permissions)) {
      permissions$.push(...guardOptions.permissions.map((n) => this.security.hasPermission(n)));
      readPermission = guardOptions.permissions.find((perm) => perm.endsWith('_READ'));
    }

    return merge(...permissions$).pipe(
      first((r) => r === true, false),
      map((hasPerm) => {
        if (!hasPerm) {
          // const url = `${guardOptions.redirectTo}/${route.params.numeroDossier}`;
          // const tree: UrlTree = this.router.parseUrl(url);
          // return tree;
          route.routeConfig.component = ErrorAccessElementComponent;
          route.routeConfig.canDeactivate = []; // Remove DungeonKeeperGuard
          route.data = {
            ...route.data,
            ...{
              routeElementData: {
                url: state.url,
                id: route.params.id,
                path: route.routeConfig.path,
              },
            },
          };
        } else {
          if (readPermission) {
            this.security
              .hasPermission(readPermission)
              .pipe(tap((r) => (route.data = { ...route.data, ...{ readOnly: r } })))
              .subscribe();
          } else route.data = { ...route.data, ...{ readOnly: false } };
        }
        return true;
      })
    );
  }
}
