import { Leaves } from './typing';

export class CoreObjectUtils {
  static removeAuditData(object: any) {
    if (!object) {
      return;
    }
    object.createBy = null;
    object.createAt = null;
    object.updateBy = null;
    object.updateAt = null;
  }
}

export type Field<T> = Leaves<T, 3> | keyof T;
export function flatten<T>(field: Field<T>): string {
  return (Array.isArray(field) ? field.join('.') : field) as string;
}
