import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FieldFormModel, FieldRefsModel } from '../../models';
import { SelectItem } from 'primeng/api';
import { ValidateFn } from 'codelyzer/walkerFactory/walkerFn';

export class FormUtils {
  static addControlFieldRefs(form: FormGroup, fieldRefs: FieldRefsModel[]) {
    fieldRefs
      .sort((a, b) => {
        if (a.fieldorder && b.fieldorder) {
          return a.fieldorder - b.fieldorder;
        } else {
          return a.id - b.id;
        }
      })
      .forEach((fieldRef) => {
        if (fieldRef.isMandatory) {
          form.addControl(`${fieldRef.objectTypeCode}_${fieldRef.id}`, new FormControl('', Validators.required));
        } else {
          form.addControl(`${fieldRef.objectTypeCode}_${fieldRef.id}`, new FormControl(''));
        }
      });
  }

  static sortByFieldRefOrder() {
    return (a, b) => {
      if (a.fieldRef.fieldorder && b.fieldRef.fieldorder) {
        return a.fieldRef.fieldorder - b.fieldRef.fieldorder;
      } else {
        return a.fieldRef.id - b.fieldRef.id;
      }
    };
  }

  static getValidatorsByFieldRef(fieldRef: FieldRefsModel): ValidatorFn[] {
    const validators: ValidatorFn[] = [];

    if (fieldRef.isMandatory) {
      validators.push(Validators.required);
    }

    if (
      fieldRef.minVal != null &&
      fieldRef.minVal.length > 0 &&
      fieldRef.maxVal != null &&
      fieldRef.maxVal.length > 0
    ) {
      validators.push(...[Validators.min(Number(fieldRef.minVal)), Validators.max(Number(fieldRef.maxVal))]);
    } else if (fieldRef.minVal != null && fieldRef.minVal.length > 0) {
      validators.push(Validators.min(Number(fieldRef.minVal)));
    } else if (fieldRef.maxVal != null && fieldRef.maxVal.length > 0) {
      validators.push(Validators.max(Number(fieldRef.maxVal)));
    }
    return validators;
  }

  static fillFieldRefOptions(form: FieldFormModel) {
    if (form.fieldRef.values && form.fieldRef.values !== '') {
      form.fieldRef.options = (<string>form.fieldRef.values)
        .split('|´|')
        .map((v) => <SelectItem>{ label: v, value: v });
    }
    return form;
  }
}
