import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormCanDeactivateComponent } from './form-can-deactivate.component';
import { UIService } from '../../../services/ui.service';

@Injectable({ providedIn: 'root' })
export class DungeonKeeperGuard implements CanDeactivate<FormCanDeactivateComponent> {
  constructor(private i18n: TranslateService, private ui: UIService) {}

  canDeactivate(component: FormCanDeactivateComponent): Promise<boolean> | boolean {
    if (!component.canDeactivate()) {
      this.ui.progressComplete();
      return this.ui.showDungeonKeeperConfirm(this.i18n.instant('common.canDeactivate')).then((confirm) => {
        return confirm;
      });
    }
    return true;
  }
}
