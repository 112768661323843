import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LovsService } from '../../services/lovs.service';

@Injectable()
@Pipe({ name: 'translateLov' })
export class TranslateLov implements PipeTransform {
  result: string;
  private lastDomCode = null;

  constructor(private readonly lovsService: LovsService, private readonly i18n: TranslateService) {}

  transform(domCode: string | any): Observable<string> {
    if (!domCode || typeof domCode !== 'string') {
      return of('');
    }
    if (domCode && this.lastDomCode === domCode && this.result) {
      return of(this.result);
    }

    this.lastDomCode = domCode;

    return this.lovsService.getLovLabel(domCode, this.i18n.currentLang).pipe(
      tap((lov) => {
        if (lov) {
          this.result = lov;
        }
        return lov;
      })
    );
  }
}
