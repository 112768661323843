import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { InternalLogin, ExternalLogin } from '../../states';

@Component({
  selector: 'app-login-multiple',
  templateUrl: './login-multiple.component.html',
  styleUrls: ['./login-multiple.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginMultipleComponent implements OnInit {
  constructor(private store: Store, private title: Title, private i18n: TranslateService) {}

  ngOnInit() {
    // Navigator Title
    this.title.setTitle(`${this.i18n.instant('app.title')} - ${this.i18n.instant('login.title')}`);
  }

  signInAsInternal(): void {
    this.store.dispatch(new InternalLogin());
  }

  signInAsExternal(): void {
    this.store.dispatch(new ExternalLogin());
  }
}
