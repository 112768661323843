import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { TypeModel, FieldRefsModel, FieldTypeModel } from '../../../models';
import { Action, UIService } from '@nsi-showcase/components';
import { NewTypeFormComponent } from '../new-type-form';
import { FieldTypeService } from '../../../services';

@Component({
  selector: 'app-fields-list',
  templateUrl: './fields-list.component.html',
  styleUrls: ['./fields-list.component.scss'],
})
export class FieldsListComponent implements OnInit {
  typeForm: FormGroup;
  defaultDate: Date = new Date();
  lightEdit: boolean;
  @Input() public baseType: TypeModel = <TypeModel>{ fieldRefs: [] };
  dialogVisible = false;
  public TYPES = {
    INT: 'INT',
    FLOAT: 'FLOAT',
    TXT: 'TXT',
    PIC: 'PIC',
    TIMESTAMP: 'TIMESTAMP',
    DATE: 'DATE',
  };
  actionsAdd: Action[] = [
    {
      icon: 'add',
      tooltipPosition: 'bottom',
      title: 'common.add',
      command: () => this.addField(),
    },
  ];

  @ViewChild('newFieldForm', { static: false })
  newFieldForm: NewTypeFormComponent;
  @Input() allowAdd = false;
  @Input() allowEdit = false;
  @Input() allowDelete = false;
  constructor(public fieldTypeService: FieldTypeService, private i18n: TranslateService, private ui: UIService) {}

  public fieldTypes$: Observable<any[]>;
  public fieldTypes: SelectItem[];

  public fieldToUpdate: FieldRefsModel;

  ngOnInit() {
    this.fieldTypes = [];
    this.fieldTypes$ = this.fieldTypeService.getAll().pipe(
      map((res) =>
        res.map((type) => {
          this.fieldTypes.push({
            label: this.i18n.instant('type.' + (<FieldTypeModel>type).code),
            value: (<FieldTypeModel>type).code,
          });
        })
      )
    );
  }

  private addField() {
    this.fieldToUpdate = null;
    this.dialogVisible = true;
  }

  public newFieldAdded(field) {
    if (this.baseType != null) {
      if (field.fieldorder == null) {
        field.fieldorder = this.baseType.fieldRefs.length;
      }
      if (this.baseType.code != null) {
        field.objectTypeCode = this.baseType.code;
      }
    }

    if (this.fieldToUpdate) {
      const index = this.baseType.fieldRefs.indexOf(this.fieldToUpdate);
      field = { ...this.fieldToUpdate, ...field };
      this.baseType.fieldRefs[index] = field;
      this.fieldToUpdate = null;
    } else {
      this.baseType.fieldRefs.push(field);
    }
    this.dialogVisible = false;
    this.setFieldOrders();
  }

  public onHideDialog() {
    this.newFieldForm.reset();
    this.fieldToUpdate = null;
  }

  public confirmRemoveField(field: FieldRefsModel) {
    this.ui.showDeleteConfirm(this.ui.i18n.instant('common.delete-confirm')).then((confirm) => {
      if (confirm) {
        this.removeField(field);
      }
    });
  }

  public removeField(field: FieldRefsModel) {
    const index = this.baseType.fieldRefs.indexOf(field);
    if (index > -1) {
      this.baseType.fieldRefs.splice(index, 1);
    }
    this.baseType.fieldRefs = this.baseType.fieldRefs.sort((a, b) => a.fieldorder - b.fieldorder);
    this.setFieldOrders();
  }

  public editField(field: FieldRefsModel) {
    this.fieldToUpdate = field;
    this.newFieldForm.updateField(field);
    this.dialogVisible = true;
  }

  public drop(event: CdkDragDrop<FieldRefsModel[]>) {
    moveItemInArray(this.baseType.fieldRefs, event.previousIndex, event.currentIndex);
    this.setFieldOrders();
  }

  private setFieldOrders() {
    let i = 0;
    const fields = [];
    for (const value of this.baseType.fieldRefs) {
      value.fieldorder = i;
      i++;
      fields.push(value);
    }
    this.baseType.fieldRefs = JSON.parse(JSON.stringify(fields));
  }
}
