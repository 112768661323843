<ul>
    <li>
        <a routerLink="/dashboard" [pTooltip]="'menu.dashboard' | translate" [tooltipPosition]="'right'">
            <i class="material-icons">home</i>
        </a>
    </li>
    <li class="slash">&nbsp;/&nbsp;</li>
    <ng-template ngFor let-item let-last="last" [ngForOf]="items">
        <li>
            <a [routerLink]="item.routerLink" *ngIf="item.routerLink">{{ item.label | translate }}</a>
            <ng-container *ngIf="!item.routerLink">{{ item.label | translate }}</ng-container>
        </li>
        <li class="slash" *ngIf="!last">&nbsp;/&nbsp;</li>
    </ng-template>
</ul>

<!--<div class="layout-breadcrumb-options">-->
<!--<a routerLink="/" [pTooltip]="'toolbar.new' | translate" [tooltipPosition]="'bottom'">-->
<!--<i class="material-icons">add_circle_outline</i>-->
<!--</a>-->
<!--<a routerLink="/" [pTooltip]="'toolbar.edit' | translate" [tooltipPosition]="'bottom'">-->
<!--<i class="material-icons">create</i>-->
<!--</a>-->
<!--<a routerLink="/" [pTooltip]="'toolbar.delete' | translate" [tooltipPosition]="'bottom'">-->
<!--<i class="material-icons">clear</i>-->
<!--</a>-->
<!--<a routerLink="/" [pTooltip]="'toolbar.save' | translate" [tooltipPosition]="'bottom'">-->
<!--<i class="material-icons">save</i>-->
<!--</a>-->
<!--<a routerLink="/" [pTooltip]="'toolbar.other' | translate" [tooltipPosition]="'bottom'">-->
<!--<i class="material-icons">more_vert</i>-->
<!--</a>-->
<!--&lt;!&ndash;<a routerLink="/" title="Logout">&ndash;&gt;-->
<!--&lt;!&ndash;<i class="material-icons">power_settings_new</i>&ndash;&gt;-->
<!--&lt;!&ndash;</a>&ndash;&gt;-->
<!--</div>-->
