import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService, ApiServiceContext } from './api.service';
import { TokenCredentials } from '../models/authentication';
import { TokenVM } from '../models/authentication';
import { AuthenticationDetails } from '../models/authentication';
import { Role } from '../models/security';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends ApiService {
  private readonly url = this.environment.apiUrl + 'auth';

  constructor(ctx: ApiServiceContext) {
    super(ctx);
  }

  public internalLogin() {
    window.location.href = `${this.environment.backend}oauth2/authorization/spw-ad?redirect_uri=${window.location.origin}${this.environment.context}`;
  }

  public externalLogin() {
    window.location.href = `${this.environment.backend}oauth2/authorization/spw-fas?redirect_uri=${window.location.origin}${this.environment.context}`;
  }

  public postToken(credentials: TokenCredentials): Observable<TokenVM> {
    return this.http.post<TokenVM>(`${this.url}/token`, credentials).pipe(catchError(this.handleError));
  }

  public getTouch(): Observable<TokenVM> {
    return this.http.get<TokenVM>(`${this.url}/touch`, {});
  }

  public refreshToken(): Observable<TokenVM> {
    return this.http.post<TokenVM>(`${this.url}/refresh-token`, {}).pipe(catchError(this.handleError));
  }

  public getMe(): Observable<AuthenticationDetails> {
    return this.http.get<AuthenticationDetails>(`${this.url}/me`).pipe(catchError(this.handleError));
  }

  public deleteToken(): Observable<void> {
    return this.http.delete<void>(`${this.url}/token`).pipe(catchError(this.handleError));
  }

  // User profile
  public getDefaultRole(userName?: string): Observable<Role> {
    if (userName) {
      return this.http.get<Role>(`${this.url}/users/${userName}/default-role`).pipe(catchError(this.handleError));
    } else {
      return this.http.get<Role>(`${this.url}/users/-me-/default-role`).pipe(catchError(this.handleError));
    }
  }

  public getRoles(userName?: string): Observable<Role[]> {
    if (userName) {
      return this.http.get<Role[]>(`${this.url}/users/${userName}/roles`).pipe(catchError(this.handleError));
    } else {
      return this.http.get<Role[]>(`${this.url}/users/-me-/roles`).pipe(catchError(this.handleError));
    }
  }

  public switchRole(roleName: string): Observable<TokenVM> {
    return this.http.post<TokenVM>(`${this.url}/role/${roleName}`, {}).pipe(catchError(this.handleError));
  }
}
