import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SortEvent } from 'primeng/api';
import { get } from 'lodash';
import { TranslateLov } from '../shared/pipes/translate-lov.pipe';
import { LovsService } from './lovs.service';
import { TableSortAction } from '../models/primeng/table-sort-action.enum';

@Injectable({ providedIn: 'root' })
export class TableService {
  private translateLov: TranslateLov;

  constructor(private i18n: TranslateService, private lovsService: LovsService) {
    this.translateLov = new TranslateLov(lovsService, i18n);
  }

  customSort(event: SortEvent, fields?: string[], actions?: string[], actionDatas?: string[]) {
    event.data.sort((data1, data2) => {
      let value1: any;
      let value2: any;
      let result: any;
      let customAction: string = null;
      let customPrefix: string = null;
      let customField: string = null;
      let customObjectField: string = null;
      let index = fields.findIndex((f) => f === event.field);

      if (index !== -1) {
        if (actions) {
          customAction = actions[index];

          if (customAction === TableSortAction.TRANSLATE && actionDatas) {
            customPrefix = actionDatas[index];
            value1 = this.i18n.instant(`${customPrefix}.${data1[event.field]}`);
            value2 = this.i18n.instant(`${customPrefix}.${data2[event.field]}`);
          } else if (customAction === TableSortAction.TRANSLATE_LOV) {
            value1 = this.translateLov.transform(data1[event.field]);
            value2 = this.translateLov.transform(data2[event.field]);
          } else if (customAction === TableSortAction.OTHER_FIELD && actionDatas) {
            customField = actionDatas[index];
            value1 = data1[customField];
            value2 = data2[customField];
          } else if (customAction === TableSortAction.OBJECT_FIELD && actionDatas) {
            customObjectField = actionDatas[index];
            value1 = get(data1, customObjectField);
            value2 = get(data2, customObjectField);
          } else {
            value1 = data1[event.field];
            value2 = data2[event.field];
          }
        } else {
          value1 = data1[event.field];
          value2 = data2[event.field];
        }
      } else {
        value1 = data1[event.field];
        value2 = data2[event.field];
      }

      result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }
}
