import { Subject } from 'rxjs';

export class DomUtils {
  static loadScript(src: string) {
    const script$ = new Subject();
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.onload = (e) => {
      script$.next({ script: src, event: e, success: true });
      script$.complete();
    };
    script.onerror = (e) => {
      script$.next({ script: src, event: e, success: false });
      script$.complete();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
    return script$;
  }
}
