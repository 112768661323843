import { HttpParams } from '@angular/common/http';
import { TableLazyLoadEvent } from '../primeng/events/table-lazy-load-event.interface';
import { PageableSearchRequest } from './pageable-search-request.interface';

export type Order = 'asc' | 'desc';

export interface Sort {
  attribute: string;
  order?: Order;
}

export class PageRequest {
  page?: number;
  size?: number;
  sort?: Sort[];

  static toHttpParams(request: PageRequest, params?: HttpParams): HttpParams {
    params = params || new HttpParams();
    if (request) {
      if (Number.isInteger(request.page)) {
        params = params.set('page', request.page.toString());
      }
      if (Number.isInteger(request.size)) {
        params = params.set('size', request.size.toString());
      }
      if (request.sort) {
        const sort = request.sort || [];
        params = sort.reduce((p, s) => p.append('sort', `${s.attribute},${s.order || 'asc'}`), params);
      }
    }
    return params;
  }

  static toPageRequest(event: TableLazyLoadEvent<any> | any): PageRequest {
    return {
      page: event.first / event.rows,
      size: event.rows,
      sort: event.sortField
        ? [
            {
              attribute: event.sortField,
              order: event.sortOrder > 0 ? 'asc' : 'desc',
            },
          ]
        : null,
    };
  }

  static mergeSearchRequest<Criteria>(
    action: PageableSearchRequest<Criteria>,
    state: PageableSearchRequest<Criteria>
  ): PageableSearchRequest<Criteria> {
    const request = action || { ...state };
    if (!request.page) {
      request.page = {};
    }
    if (!Number.isFinite(request.page.page)) {
      request.page.page = 0;
    }
    if (!request.params && state) {
      request.params = state.params;
    }
    return request;
  }
}
