import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { get, isObject, sortBy } from 'lodash';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { i18nLang } from '../core/operators/i18n-lang.observable';
import { CoreObjectUtils } from '../core/utils/object.utils';
import { ObjectUtils } from '@nsi/core-utils';

@Injectable({ providedIn: 'root' })
export class FormService {
  constructor(private i18n: TranslateService) {}

  private static isDisabled(item: any): boolean {
    switch (typeof item.statut) {
      case 'boolean':
        return !item.statut;
      default:
        return false;
    }
  }

  public getSelectItems(
    items: any[],
    value: any,
    label: string,
    translatePrefix?: string,
    order: boolean = false,
    orderField: string = null,
    disabling: boolean = false,
    emptyLabel?: string | boolean,
    removeAuditdata?: boolean
  ): Observable<SelectItem[]> {
    return i18nLang(this.i18n).pipe(
      map(() => {
        let selectItems: SelectItem[] = [];

        items.forEach((item) => {
          if (removeAuditdata) {
            CoreObjectUtils.removeAuditData(item);
          }
          selectItems.push({
            value: isObject(value) ? item : get(item, value),
            label: get(item, translatePrefix ? this.i18n.instant(`${translatePrefix}.${label}`) : label),
            disabled: disabling ? FormService.isDisabled(item) : false,
          });
        });

        if (selectItems.length > 0) {
          if (order && orderField) {
            orderField = translatePrefix ? this.i18n.instant(`${translatePrefix}.${orderField}`) : orderField;
            if (isObject(selectItems[0].value))
              selectItems.sort((a, b) => (a.value[orderField] > b.value[orderField] ? 1 : -1));
            else selectItems.sort((a, b) => (a.value > b.value ? 1 : -1));
          } else if (order) {
            selectItems.sort((a, b) => (a.label > b.label ? 1 : -1));
          }

          if (disabling) {
            let disabledItems = selectItems.filter((item) => item.disabled);
            selectItems = selectItems.filter((item) => !item.disabled);
            if (disabledItems) disabledItems.forEach((disabledItem) => selectItems.push(disabledItem));
          }

          if (!ObjectUtils.isNullOrUndefined(emptyLabel) && typeof emptyLabel === 'string') {
            selectItems.unshift({ value: null, label: emptyLabel });
          } else if (typeof emptyLabel !== 'boolean' || emptyLabel === true) {
            selectItems.unshift({
              value: null,
              label: this.i18n.instant('common.select'),
            });
          }
        }

        return selectItems;
      })
    );
  }

  public getEnumSelectItems(EnumItems: any, emptyLabel?: string, translatePrefix?: string): Observable<SelectItem[]> {
    return i18nLang(this.i18n).pipe(
      map(() => {
        let selectItems: SelectItem[] = [];

        Object.keys(EnumItems).forEach((enumItem) =>
          selectItems.push({
            value: enumItem,
            label: translatePrefix ? this.i18n.instant(`${translatePrefix}.${EnumItems[enumItem]}`) : enumItem,
          })
        );

        selectItems = sortBy(selectItems, ['label']);

        if (!ObjectUtils.isNullOrUndefined(emptyLabel)) selectItems.unshift({ value: null, label: emptyLabel });
        else
          selectItems.unshift({
            value: null,
            label: this.i18n.instant('common.select'),
          });

        return selectItems;
      })
    );
  }
}
