import * as moment from 'moment';

export class FormUtils {
  static rnMask = '99.99.99-999.99';

  // DATE
  static today: Date = new Date();
  static hundredAndTenYearsAgo: Date = moment().subtract(110, 'years').toDate();
  static birthDateRange = new Date().getFullYear() - 110 + ':' + new Date().getFullYear();
  static searchDateRange = new Date().getFullYear() - 110 + ':' + (new Date().getFullYear() + 10);
}

export function replaceSpecialChar(s: string) {
  s = s.replace(/[^0-9a-zA-Z]/gi, '');
  s = s.replace(/[\s]{2,}/g, ' ');
  return s.trim();
}
