import { NotiService, UIService } from '@nsi-showcase/components';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { AmieEnvironment } from '../environment';

type Severities = 'success' | 'info' | 'warning' | 'error';

@Injectable({ providedIn: 'root' })
export class UiNotifI18nService {
  constructor(
    protected ui: UIService,
    protected notif: NotiService,
    protected env: AmieEnvironment,
    protected i18n: TranslateService
  ) {}

  info(detail: string, summary?: string) {
    this.notify(summary, detail, 'info');
  }

  success(detail: string, summary?: string) {
    this.notify(summary, detail, 'success');
  }

  warn(detail: string, summary?: string) {
    this.notify(summary, detail, 'warning');
  }

  error(detail: string, summary?: string) {
    this.notify(summary, detail, 'error');
  }

  notify(summary: string, detail: string, severity: Severities) {
    const i18nDetail = this.i18n.instant(detail);
    const i18nSummary = summary ? this.i18n.instant(summary) : summary;
    switch (severity) {
      case 'error':
        this.notif.error(i18nDetail, i18nSummary);
        break;
      case 'success':
        this.notif.success(i18nDetail, i18nSummary);
        break;
      case 'warning':
        this.notif.warn(i18nDetail, i18nSummary);
        break;
      case 'info':
      default:
        this.notif.info(i18nDetail, i18nSummary);
    }
  }
}
