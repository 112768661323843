<form #formElement [formGroup]="form" class="ui-fluid form-group">
  <span class="md-inputfield">
    <label class="required">{{ 'object-edit-road.form.localisation-routiere' | translate }}</label>
    <br />
    <app-segment-autocomplete formControlName="localisationRoutiere" [disabled]="disabled"></app-segment-autocomplete>
    <val-errors controlName="localisationRoutiere"></val-errors>
  </span>
  <span class="md-inputfield">
    <label class="required">{{ 'object-edit-road.form.localisation-sens-route' | translate }}</label>
    <br />
    <p-dropdown [options]="sensRouteItems" formControlName="sensRoute" appendTo="body" [filter]="true"
      [emptyFilterMessage]="'common.no-result' | translate" [disabled]="disabled ||
      !form.value.localisationRoutiere"></p-dropdown>
    <val-errors controlName="sensRoute"></val-errors>
  </span>
  <span class="md-inputfield">
    <label class="required">{{ 'object-edit-road.form.localisation-cumulee' | translate }}</label>
    <br />
    <p-spinner formControlName="cumuleeRoute" (change)="onFormChange()" [disabled]="loading || disabled ||
      !form.value.localisationRoutiere || !form.value.sensRoute"></p-spinner>
    <val-errors controlName="cumuleeRoute"></val-errors>
  </span>
  <div #loader class="loader hide-loader">
    <i class="fas fa-2x fa-spin fa-spinner"></i>
  </div>
</form>
