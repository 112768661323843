import { SpwGeoviewer } from '../spw-geoviewer.component';
import { switchMap, map, filter } from 'rxjs/operators';
import { of, Subject, BehaviorSubject, Observable } from 'rxjs';
import { MapServiceTypes, MapServiceInfo } from '../api/models/map-service.model';

export const ESRI_REQUIRE = {
  geometryEngine: 'esri/geometry/geometryEngine',
  Draw: 'esri/toolbars/draw',
  Edit: 'esri/toolbars/edit',
  on: 'dojo/on',
  SpatialReference: 'esri/SpatialReference',
  Graphic: 'esri/graphic',
  jsonUtils: 'esri/geometry/jsonUtils',
  Polygon: 'esri/geometry/Polygon',
  Point: 'esri/geometry/Point',
  Polyline: 'esri/geometry/Polyline',
  Circle: 'esri/geometry/Circle',
};

export class AbstractSpwGeoviewerHelper {
  get instance() {
    return this.viewer.instance;
  }

  get spwMap() {
    return this.instance?.get('spwMap');
  }

  get require() {
    return (...args) => {
      return new Observable((subscriber) => {
        this.viewer.require(...args).subscribe((results) => {
          subscriber.next(results);
        });
      });
    };
  }
  services = new Map<string, BehaviorSubject<any>>();

  constructor(public viewer: SpwGeoviewer) {}

  on<T = any>(target, event) {
    const event$ = new Subject<T>();
    this.require('dojo/on').pipe(
      map(([on]) => {
        on(target, event, (result) => {
          event$.next(result);
        });
      })
    );
    return event$;
  }
  getGeometry(_geom, type, params?) {
    return this.require(`esri/geometry/${type}`).pipe(
      map(([Geom]) => {
        const geom = new Geom(_geom, params);
        return geom;
      })
    );
  }

  getGraphic(_geom, options) {
    return new Observable((subscriber) => {
      this.require('esri/graphic').subscribe(([Graphic]) => {
        const graphic = new Graphic({
          geometry: _geom,
          symbol: options.symbol,
          attributes: options.attributes,
        });
        subscriber.next(graphic);
      });
    });
  }

  getGraphics(options: any[]) {
    return new Observable<any[]>((subscriber) => {
      this.require('esri/graphic').subscribe(([Graphic]) => {
        const graphics = options.map(
          (option) =>
            new Graphic({
              geometry: option.geometry,
              symbol: option.symbol,
              attributes: option.attributes,
            })
        );
        subscriber.next(graphics);
      });
    });
  }
  getOrCreateMapService(
    mapServiceInfo: MapServiceInfo = {
      serviceId: 'default',
      label: 'default',
      type: MapServiceTypes.DRAW_LAYER,
      inTOC: false,
      identifiable: false,
    }
  ) {
    let service = this.getMapService(mapServiceInfo.serviceId);
    if (!service) {
      service = this.createMapService(mapServiceInfo);
    }
    return service;
  }

  getMapService(id) {
    return this.spwMap.getMapServices({ serviceId: id })[0];
  }

  getMapServiceByAdminCode(adminCode) {
    return this.spwMap.getMapServices({ adminCode: adminCode })[0];
  }

  createMapService(mapServiceInfo) {
    const _mapService = this.spwMap.addMapService(mapServiceInfo);
    if (mapServiceInfo.order) {
      this.spwMap.esriMap.reorderLayer(_mapService.serviceId, mapServiceInfo.order);
    }

    console.log('[createMapService]', _mapService);
    return _mapService;
  }
}
