import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { i18nLang } from '../../core/operators/i18n-lang.observable';

@Injectable()
@Pipe({ name: 'translateLabel' })
export class TranslateLabelPipe implements PipeTransform {
  private result: string;
  private object: any;

  constructor(private readonly i18n: TranslateService) {}

  transform(object: any, field: string = 'label'): Observable<string> {
    return i18nLang(this.i18n).pipe(
      map(() => {
        const lang = this.i18n.currentLang[0].toUpperCase() + this.i18n.currentLang.substring(1);
        if (!object || typeof object !== 'object' || !object.hasOwnProperty(field + lang)) {
          return object;
        }

        if (this.object !== object) {
          this.object = object;
          this.result = object[field + lang];
        }

        return this.result;
      })
    );
  }
}
