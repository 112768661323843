import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  constructor(private router: Router, private route: ActivatedRoute) {}

  navigate(url: any): void {
    this.router.navigateByUrl('/loading').then(() => this.router.navigate(url));
  }

  reload(): void {
    this.router.navigate([this.router.url], { relativeTo: this.route });
  }
}
