import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialog } from 'primeng/confirmdialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  providers: [ConfirmationService],
})
export class ConfirmDialogComponent implements OnInit {
  private _isError = false;
  private _isWarning = false;

  @HostBinding('class.ui-dialog-error') get isError() {
    return this._isError;
  }
  @HostBinding('class.ui-dialog-warning') get isWarning() {
    return this._isWarning;
  }

  @ViewChild(ConfirmDialog, { static: true })
  public confirmDialog: ConfirmDialog;
  public type: string;
  public rejectLabel: string;
  public acceptLabel: string;

  private closeResolver: (_: boolean) => any;

  constructor(private i18n: TranslateService, private confirmationService: ConfirmationService) {}

  ngOnInit(): void {}

  showConfirmMsg(
    type: string,
    msg: string,
    title?: string,
    icon?: string,
    dungeonKeeper: boolean = false
  ): Promise<boolean> {
    if (dungeonKeeper) {
      this.rejectLabel = this.i18n.instant('common.no-dungen-keeper');
      this.acceptLabel = this.i18n.instant('common.yes-dungen-keeper');
      // this.confirmDialog.style = { width: '510px' };
    } else {
      this.rejectLabel = this.i18n.instant('common.no');
      this.acceptLabel = this.i18n.instant('common.yes');
      this.confirmDialog.style = { width: '30%' };
    }

    return new Promise<boolean>((resolve, reject) => {
      this.type = type;
      this.closeResolver = resolve;
      this._isError = false;
      this._isWarning = false;

      if (type === 'error') {
        icon = 'ui-icon-error';
        this._isError = true;
      } else if (type === 'warning') {
        icon = 'ui-icon-warning';
        this._isWarning = true;
      }

      this.confirmationService.confirm({
        message: msg,
        header: title || this.i18n.instant(`common.${type}`),
        icon: icon || (type === 'delete' ? 'ui-icon-delete-forever' : 'ui-icon-help'),
        accept: () => {
          this.closeResolver(true);
        },
        reject: () => {
          this.closeResolver(false);
        },
      });
    });
  }
}
