// Angular Modules
import { NgModule } from '@angular/core';
import { CoreModule } from '../core';
import { ShowcaseSharedModule } from '../shared';
import { LayoutsModule } from '../layouts/layouts.module';
import { AppComponent } from './app.component';

// App Modules

const MODULES: any[] = [CoreModule, ShowcaseSharedModule, LayoutsModule];

const DECLARATIONS: any[] = [AppComponent];

@NgModule({
  imports: MODULES,
  declarations: DECLARATIONS,
  exports: [AppComponent],
})
export class ShowcaseAppModule {}
