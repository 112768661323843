import { AbstractSpwGeoviewerHelper, ESRI_REQUIRE } from './geoviewer-abstract.helper';
import { map, switchMap, tap, filter, first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DrawSpwGeoviewerHelper extends AbstractSpwGeoviewerHelper {
  ctrlDown = false;
  ctrlKey = 17;
  cmdKey = 91;
  zKey = 90;
  ctrlZDelay = 100; // ms
  _drawToolbar;
  _editToolbar;
  _drawHandler;
  _drawCancelDownHandler;
  clearService(mapServiceInfo) {
    const service = this.getOrCreateMapService(mapServiceInfo);
    console.log('clearService', mapServiceInfo, service);
    service.layer.clear();
    return service;
  }

  //region Show Polygon
  showPolygon(mapServiceInfo, geometry, _symbol) {
    this._render(mapServiceInfo, geometry, _symbol);
  }
  //endregion

  //region Show Polyline
  showPolyline(mapServiceInfo, geometry, _symbol) {
    this._render(mapServiceInfo, geometry, _symbol);
  }
  //endregion

  //region Show Point
  showPoint(mapServiceInfo, geometry, _symbol): Observable<any> {
    return this._render(mapServiceInfo, geometry, _symbol);
  }
  //endregion

  //region Show Point
  getPointGraphic(geometry, _symbol): Observable<any> {
    return this._renderGraphic(geometry, _symbol);
  }
  //endregion

  _renderGraphic(geometry, symbol) {
    return this.getGraphic(geometry, { type: 'Point', symbol: symbol }).pipe(
      filter((g) => !!g),
      tap((g) => {
        console.log('_render', g);
      })
    );
  }

  _render(mapServiceInfo, geometry, symbol) {
    const graphic$ = this.getGraphic(geometry, {
      type: 'Point',
      symbol: symbol,
    }).pipe(map((graphic) => this.addGraphicToService(mapServiceInfo, graphic)));

    return graphic$.pipe(
      filter((g) => !!g),
      tap((g) => {
        console.log('_render', g);
      })
    );
  }

  addGraphicToService(mapServiceInfo, graphic) {
    const service = this.getOrCreateMapService(mapServiceInfo);
    service.get('layer').add(graphic);
    return graphic;
  }
  refreshService(mapServiceInfo) {
    const service = this.getOrCreateMapService(mapServiceInfo);
    service.get('layer').refresh();
    return service;
  }

  initToolbars() {
    const effectiveInit = () => {
      this.require(ESRI_REQUIRE.Draw, ESRI_REQUIRE.Edit).subscribe(([Draw, Edit]) => {
        this._drawToolbar = new Draw(this.spwMap.get('esriMap'));
        this._editToolbar = new Edit(this.spwMap.get('esriMap'));
      });
    };
    if (this.spwMap.loaded) {
      effectiveInit();
    } else {
      this.spwMap.on(this.spwMap.events.MapLoaded, effectiveInit);
    }
  }

  //region Select Polygon / Draw Polygon / Draw Enclave
  activateDrawEnclave(mapServiceInfo) {
    const isEnclave = true;
    this.activateDrawPolygon(mapServiceInfo, null, isEnclave);
  }

  activateDrawPolygon(mapServiceInfo, symbol, isEnclave) {
    this.require(ESRI_REQUIRE.on).subscribe(([on]) => {
      isEnclave = isEnclave || false;
      const service = this.getOrCreateMapService(mapServiceInfo);
      this._drawHandler = on(this._drawToolbar, 'draw-end', (evt) => {
        this.onDrawPolygonEnd(evt, service, symbol, isEnclave);
      });

      this.activatePolygon();
    });
  }

  activateSelectPolygon() {
    const select$ = this.on(this._drawToolbar, 'draw-end');

    this.activatePolygon();
    return select$;
  }

  activatePolygon() {
    this.require(ESRI_REQUIRE.on, ESRI_REQUIRE.Draw).subscribe(([on, Draw]) => {
      const keyup$ = this.on(document, 'keyup').pipe(
        tap((e) => {
          if (e.keyCode === this.ctrlKey || e.keyCode === this.cmdKey) this.ctrlDown = false;
        })
      );

      this._drawCancelDownHandler = on.pausable(document, 'keydown', (e) => {
        if (e.keyCode === this.ctrlKey || e.keyCode === this.cmdKey) this.ctrlDown = true;

        if (this.ctrlDown && e.keyCode === this.zKey) {
          this.removeLastDrawPoint();
          this.pauseHandlerActivation(this._drawCancelDownHandler, this.ctrlZDelay);
        }
      });

      this._drawToolbar.activate(Draw['POLYGON']);
    });
  }

  onDrawPolygonEnd(evt, service, symbol, isEnclave) {
    this.simplifyGeometry(evt.geometry).subscribe((geometries: any[]) => {
      if (isEnclave) {
        geometries.forEach((geometry) => {
          this.addEnclaveToGraphicLayer(service, geometry);
        });
      } else {
        geometries.forEach((geometry) => {
          this.getGraphic(geometry, {
            type: 'Polygon',
            symbol: symbol,
          }).subscribe((graphic) => {
            this.addGraphicToService(service, graphic);
          });
        });
      }
    });
  }

  simplifyGeometry(geom) {
    return this.require(ESRI_REQUIRE.SpatialReference, ESRI_REQUIRE.geometryEngine, ESRI_REQUIRE.Polygon).pipe(
      map(([SpatialReference, geometryEngine, Polygon]) => {
        const simples = [];

        if (geom.type === 'polygon' && geom.isSelfIntersecting()) {
          const simple = geometryEngine.simplify(geom);
          const geoms = simple.rings.map((ring) => {
            const polygon = new Polygon(new SpatialReference({ wkid: '31370' }));
            polygon.addRing(ring);
            return polygon;
          });
          simples.push(...geoms);
        } else {
          simples.push(geom);
        }
        return simples;
      })
    );
  }

  addEnclaveToGraphicLayer(service, enclave) {
    this.require(ESRI_REQUIRE.geometryEngine, ESRI_REQUIRE.Graphic).subscribe(([geometryEngine, Graphic]) => {
      const graphics = service
        .get('layer')
        .graphics.filter((graphic) => geometryEngine.intersects(graphic.geometry, enclave));

      graphics.forEach((graphic) => {
        service.get('layer').remove(graphic);

        const geom = geometryEngine.difference(graphic.geometry, enclave);
        graphic = new Graphic(geom, graphic.symbol);

        service.get('layer').add(graphic);
      });
    });
  }

  pauseHandlerActivation(handler, time) {
    handler.pause();
    setTimeout(() => {
      handler.resume();
    }, time);
  }

  removeLastDrawPoint() {
    const dt = this._drawToolbar;
    const points = dt._points;
    if (points.length > 1) {
      const geom = dt._graphic.geometry;
      geom.removePoint(0, points.length - 1);
      points.splice(points.length - 1, 1);
      dt._graphic.setGeometry(geom);

      const start = points[points.length - 1];
      const tempGeom = dt._tGraphic.geometry;
      tempGeom.setPoint(0, 0, start.offset(0, 0));
      tempGeom.setPoint(0, 1, start.offset(0, 0));
      dt._tGraphic.setGeometry(tempGeom);
    } else {
      dt._points = [];
      dt._onMouseMoveHandler_connect.remove();
      dt._clear();
    }
  }

  deactivateDrawEnclave() {
    this.deactivateDrawPolygon();
  }

  deactivateDrawPolygon() {
    if (this._drawHandler && this._drawHandler.advice) {
      this._drawHandler.remove();
    }

    this.deactivatePolygon();
  }

  deactivateSelectPolygon() {
    this.deactivatePolygon();
  }

  deactivatePolygon() {
    if (this._drawCancelDownHandler) {
      this._drawCancelDownHandler.remove();
    }

    if (this._drawToolbar) {
      this._drawToolbar.deactivate();
    }
  }
  //endregion
}
