import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { ConfigService } from './config.service';
import { AppConfig } from '../models/app-config.model';

type Severities = 'success' | 'info' | 'warning' | 'error';

@Injectable({ providedIn: 'root' })
export class NotiService {
  constructor(private i18n: TranslateService, private toastr: ToastrService, private config: ConfigService) {}

  info(detail: string, summary?: string) {
    this.notify('info', summary || this.i18n.instant('common.info'), detail);
  }

  success(detail: string, summary?: string) {
    this.notify('success', summary || this.i18n.instant('common.success'), detail);
  }

  warn(detail: string, summary?: string) {
    this.notify('warning', summary || this.i18n.instant('common.warning'), detail);
  }

  error(detail: string, summary?: string) {
    this.notify('error', summary || this.i18n.instant('common.error'), detail);
  }

  deleteSuccess() {
    return this.success(this.i18n.instant('common.delete-ok'));
  }

  saveSuccess() {
    return this.success(this.i18n.instant('common.save-ok'));
  }

  saveError() {
    return this.error(this.i18n.instant('common.save-ko'));
  }

  saveWarning() {
    return this.warn(this.i18n.instant('common.save-warn'));
  }

  noChange() {
    return this.info(this.i18n.instant('common.no-change'));
  }

  saveTask(isComplete: boolean = false) {
    if (isComplete) this.taskCompleteSuccess();
    else this.saveSuccess();
    // this.taskSaveSuccess();
  }

  taskSaveSuccess() {
    return this.success(this.i18n.instant('common.save-task-ok'));
  }

  taskCompleteSuccess() {
    return this.success(this.i18n.instant('common.complete-task-ok'));
  }

  private notify(severity: Severities, summary: string, detail: string, config?: Partial<IndividualConfig>) {
    if (!config) {
      config = {};
    }

    const appConfig = <AppConfig>this.config.app;
    config.progressBar = appConfig.toastrProgressBar || false;
    config.closeButton = appConfig.toastrCloseButton;
    config.timeOut = appConfig.toastrTimeOut || 5000;
    config.extendedTimeOut = appConfig.toastrExtendedTimeOut || 1000;
    config.onActivateTick = true;

    if (severity === 'error') {
      // config.disableTimeOut = true;

      config.timeOut = appConfig.toastrTimeOut || 10000;
      summary = `${summary} (${moment().format('DD/MM/YYYY HH:mm:ss')})`;
    }

    this.toastr.show(detail, summary, config, `toast-${severity}`);
  }
}
